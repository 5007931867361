import React from 'react';
import {Table} from 'antd';
import {DatatableCouponDetailStyle} from './DatatableCouponDetailStyle';
import {CouponDetailColumn} from './DataTableCouponDetailColumns';

const DatatableCouponDetail = ({columns, data, ...otherProps}) => {
  return (
    <DatatableCouponDetailStyle>
      <div className="table-title">รายการคูปอง</div>
      <Table
        {...otherProps}
        columns={CouponDetailColumn()}
        dataSource={data}
        pagination={false}
        showSorterTooltip={false}
        locale={{emptyText: <div>{'ไม่พบข้อมูล'}</div>}}
      />
    </DatatableCouponDetailStyle>
  );
};

export default DatatableCouponDetail;
