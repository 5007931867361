import { Select, Tag } from "antd";

export const SelectTagsCustom = (props) => {
  const { disabledTags, tokenSeparators, options, value, ...othersProps } = props.item;

  const tagRender = (props) => {
    const { label, onClose } = props;
    // const isDisabled = disabledTags.includes(label);
    const isDisabled = disabledTags?.includes(label?.toUpperCase());

    return (
      <Tag
        closable={!isDisabled}
        onClose={isDisabled ? undefined : onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <>
      <Select
        mode="tags"
        tokenSeparators={Array.isArray(tokenSeparators) ? tokenSeparators : ['\n']}
        tagRender={tagRender}
        open={false}
        value={value.filter(Boolean) || []}
        options={options || []}
        style={{ width: '100%' }}
        {...othersProps}
      />
    </>
  )
}