export const registerInstallmentSteps = [
  {
    title: 'สินค้าใหม่',
  },
  {
    title: 'ส่วนตัว',
  },
  {
    title: 'อาชีพ',
  },
  {
    title: 'ผู้ติดต่อ',
  },
  {
    title: 'พิจารณา',
  },
]

export const registerTurnSteps = [
  {
    title: 'สินค้าเทิร์น',
  },
  {
    title: 'สินค้าใหม่',
  },
  {
    title: 'ส่วนตัว',
  },
  {
    title: 'อาชีพ',
  },
  {
    title: 'ผู้ติดต่อ',
  },
  {
    title: 'พิจารณา',
  },
]

export const registerSecondHandSteps = [
  {
    title: 'สินค้าใหม่',
  },
  {
    title: 'ส่วนตัว',
  },
  {
    title: 'พิจารณา',
  },
]

export const registerUserSteps = [
  {
    title: 'สินค้า',
  },
  {
    title: 'ส่วนตัว',
  },
  {
    title: 'อาชีพ',
  },
  {
    title: 'ผู้ติดต่อ',
  },
]