import React, {createRef} from 'react';
import {Button, Space, Tag, Tooltip, Row, Col} from 'antd';
import {color} from '../../../resources/color';
import {renderSearchColumn} from '../../../constants/global/renderSearchColumn';

export const UnlockIcloudColumn = ({options, openModal, openReceiptNewTabWithGet, user}) => {
  const searchInput = createRef();

  const tagColor = (data) => {
    let tag = '';
    switch (!!data) {
      case data === 'NO_STATUS' || data === 'LOCKED':
        tag = color.clear;
        break;
      case data === 'APPROVED' || data === 'UNLOCKED':
        tag = color.success;
        break;
      default:
        break;
    }
    return tag;
  };
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '40px',
      align: 'center',
      render: (text, record, i) => {
        return <div style={{display: 'flex'}}>{i + 1}</div>;
      },
    },
    {
      title: 'ชื่อ - นามสกุล',
      dataIndex: 'user_name',
      width: '200px',
      align: 'left',
      ...renderSearchColumn('user_name', searchInput),
      render: (text, record) => {
        return <div style={{display: 'flex'}}>{record.user_name}</div>;
      },
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contract_no',
      width: '120px',
      align: 'left',
      ...renderSearchColumn('contract_no', searchInput),
      render: (text, record) => {
        return <div style={{display: 'flex'}}>{record.contract_no}</div>;
      },
    },
    {
      title: 'SN',
      dataIndex: 'serialnumber',
      width: '120px',
      align: 'center',
    },
    {
      title: 'Type Lock',
      dataIndex: 'type_lock',
      width: '150px',
      key: 'type_lock',
      align: 'center',
      render: (text, record) => {
        return <div style={{display: 'flex'}}>{record?.type_lock === 'MDMICLOUD' ? 'MDM + ICLOUD' : record?.type_lock}</div>;
      },
    },
    {
      title: 'วันที่ปิดสัญญา',
      dataIndex: 'closed_contract_date',
      width: '120px',
      align: 'left',
      render: (text, record) => {
        return <div style={{display: 'flex'}}>{record?.closed_contract_date ?? ''}</div>;
      },
    },
    {
      title: 'เกรดหลังทำสัญญา',
      dataIndex: 'grade',
      width: '120px',
      align: 'center',
      filters: [
        {value: '-', text: 'ไม่มีเกรดลูกค้า'},
        {value: 'A', text: 'A (0-5'},
        {value: 'B', text: 'B (6-15)'},
        {value: 'C', text: 'C (16-59)'},
        {value: 'D', text: 'D (60+)'},
        {value: '?', text: '? (ยังไม่ระบุ)'},
        {value: 'V', text: 'V (VIP)'},
        {value: 'X', text: 'X (Blocked)'},
      ],
      onFilter: (value, record) => {
        if (record.grade === '') {
          record.grade = '-';
        }
        return record.grade.indexOf(value) === 0;
      },
      render: (text, record) => {
        return <div style={{display: 'center'}}>{record.grade}</div>;
      },
    },
    {
      title: 'Social ID',
      dataIndex: 'social_id',
      width: '150px',
      key: 'social_id',
      align: 'center',
      render: (text, record) => {
        const social_id = record?.chatcone?.social_id;
        return social_id ? <Tag color={color.success}>มี</Tag> : <Tag color={color.clear}>ไม่มี</Tag>;
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'approveStatus',
      width: '120px',
      align: 'center',
      filters: options.optionsApprove.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.approve_status.code === value;
      },
      render: (text, record) => {
        return <Tag color={tagColor(record.approve_status.code)}>{record.approve_status.text}</Tag>;
      },
    },
    {
      title: 'ผู้กดปลดล็อก MDM',
      dataIndex: 'admin_mdm_unlock',
      width: '120px',
      align: 'left',
      ...renderSearchColumn('admin_mdm_unlock', searchInput),
      render: (text, record) => {
        return <div style={{display: 'flex'}}>{record?.admin_mdm_unlock ?? ''}</div>;
      },
    },
    {
      title: 'วันที่ปลดล็อก MDM',
      dataIndex: 'mdm_unlock_date',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return <div style={{display: 'flex'}}>{record?.mdm_unlock_date ?? ''}</div>;
      },
    },
    {
      title: 'ผู้กดอนุมัติรหัส',
      dataIndex: 'admin_approve',
      width: '120px',
      align: 'left',
      ...renderSearchColumn('admin_approve', searchInput),
      render: (text, record) => {
        return <div style={{display: 'flex'}}>{record?.admin_approve ?? ''}</div>;
      },
    },
    {
      title: 'วันที่กดอนุมัติรหัส',
      dataIndex: 'approve_date',
      width: '100px',
      align: 'left',
      render: (text, record) => {
        return <div style={{display: 'flex'}}>{record?.approve_date ?? ''}</div>;
      },
    },
    {
      title: 'จัดการ',
      dataIndex: 'action',
      width: '100px',
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        let btnName;
        let bgcolor;
        switch (record?.approve_status?.code) {
          case 'NO_STATUS':
          case 'LOCKED':
            btnName = 'Approve';
            bgcolor = color.on_process;
            break;
          case 'APPROVED':
            btnName = 'ดูรหัส iCloud';
            bgcolor = color.theme;
            break;
          default:
            break;
        }

        localStorage.setItem('contractNo', record.contract_no);

        return user && user.role === 'Admin' ? (
          <Row>
            <Col span={24} align="left">
              <Space size={[8, 8]}>
                {['NO_STATUS', 'APPROVED', 'LOCKED', 'UNLOCKED'].includes(record.approve_status.code) && (
                  <Tooltip placement="topLeft" title={'ดูรายการรับชำระ'}>
                    <Button
                      style={{backgroundColor: color.load, color: color.white}}
                      className="ant-btn-customize"
                      onClick={() => openReceiptNewTabWithGet('/payment', {contractNo: record.contract_no})}>
                      ดูรายการรับชำระ
                    </Button>
                  </Tooltip>
                )}
                {/* Type Lock MDM */}
                {['MDM', 'MDMICLOUD'].includes(record.type_lock) && ['LOCKED'].includes(record.approve_status.code) ? (
                  <Tooltip placement="topLeft" title={'Unlock'}>
                    <Button
                      style={{backgroundColor: bgcolor, color: color.white, width: '120px'}}
                      className="ant-btn-customize"
                      onClick={() => openModal(record, 'UNLOCK_MDM')}>
                      {'Unlock'}
                    </Button>
                  </Tooltip>
                ) : null}
                {/* Type Lock ICLOUD, MDMICLOUD */}
                {['ICLOUD', 'MDMICLOUD'].includes(record.type_lock) && ['NO_STATUS'].includes(record.approve_status.code) && (
                  <Tooltip placement="topLeft" title={btnName}>
                    <Button
                      style={{backgroundColor: bgcolor, color: color.white, width: '120px'}}
                      className="ant-btn-customize"
                      onClick={() => openModal(record, 'APPROVE_PASSWORD')}>
                      {btnName}
                    </Button>
                  </Tooltip>
                )}
                {['APPROVED'].includes(record.approve_status.code) && (
                  <Tooltip placement="topLeft" title={btnName}>
                    <Button
                      style={{backgroundColor: bgcolor, color: color.white, width: '120px'}}
                      className="ant-btn-customize"
                      onClick={() => openModal(record, 'VIEW_PASSWORD')}>
                      {btnName}
                    </Button>
                  </Tooltip>
                )}
                {record?.chatcone?.social_id && (
                  <Tooltip placement="topLeft" title={'ส่งแจ้งเตือน'}>
                    <Button
                      style={{backgroundColor: color.clear, color: color.white, width: '120px'}}
                      className="ant-btn-customize"
                      onClick={() => openModal(record, 'CHATCONE_HISTORY')}>
                      {'ส่งแจ้งเตือน'}
                    </Button>
                  </Tooltip>
                )}
              </Space>
            </Col>
          </Row>
        ) : null;
      },
    },
  ];
};
