import React, {useEffect, useMemo, useState} from 'react';
import {Form, Input, Divider, Row, Col, InputNumber, Modal, Space, DatePicker} from 'antd';
import _ from 'lodash';
import {CONTRACT_TYPE} from '../constants';
import Example from '../../../../assets/images/example_detail.jpg';
import {convertNumberToComma, convertStringToNumber} from '../../../../functions/fn';
import {handleTrimValue} from '../../../../utils/antform';
import {disabledBeforeCurrentDate} from '../../../../utils/date';
import {Icon} from '../../../../resources/icon';
import {ButtonTheme, Spinners, Upload} from '../../../../components';
import {SelectBranch, SelectProductAll, SelectProductCondition} from '../../../../components/inputfromapi';
import {GCS_BUCKET_CONTRACT_IMAGE, GCS_PATH_CONTRACT_IMAGE} from '../../../../env';
import {SelectInsuranceStatus} from '../../../../components/masterdata';
import {useGetProductData} from '../hooks';

/*
coupon: [
  {
    coupon_id: 'PON-25-0006',
    coupon_code: 'REGISTERCOUPON',
    coupon_discount: 200,
  },
];
*/
const formName = 'FormTurn2Turn';
const {TURN2} = CONTRACT_TYPE;
const ININT_CAL = {
  contractType: TURN2, //ประเภทสัญญา
  condition: '', //เงื่อนไข
  priceTurn: 0, //ราคา
  productCode: '', //รหัสสินค้า
};
const isRequired = true;

const FormTurn2Turn = ({defaultData, data, setActiveTab, turnState, setTurnState}) => {
  const {admin_branch, contractType} = data;

  /* Form */
  const [form] = Form.useForm();
  /* Product */
  const [productTurnData, setProductTurnData] = useState(turnState?.state?.productTurnData || {});
  /* Maximum */
  const [maxPriceTurn, setMaxPriceTurn] = useState(turnState?.state?.maxPriceTurn || 0);
  /* Insurance */
  const [insuranceStatus, setInsuranceStatus] = useState(turnState?.formData?.insurance_status === 'YES' ? 'YES' : 'NO');
  /* Calculate */
  const [calculateData, setCalculateData] = useState(turnState?.state?.calculateData || {...ININT_CAL});
  /* File */
  const [fileMachineDetailImageList, setFileMachineDetailImageList] = useState(turnState?.state?.fileMachineDetailImageList || []);
  const [fileImeiImageList, setFileImeiImageList] = useState(turnState?.state?.fileImeiImageList || []);
  const [fileOtherImageList, setFileOtherImageList] = useState(turnState?.state?.fileOtherImageList || []);
  /* Modal */
  const [isModalVisible, setIsModalVisible] = useState(false);
  /* Loading API */
  const [machineDetailImageLoading, setMachineDetailImageLoading] = useState(false);
  const [imeiImageLoading, setImeiImageLoading] = useState(false);
  const [otherImageLoading, setOtherImageLoading] = useState(false);
  /* Hook */
  const [getProductDataFn, , productDataLoading] = useGetProductData();

  /* --------- useMemo calculateData ---------*/
  const calDtMemo = useMemo(() => {
    return calculateData;
  }, [calculateData]);

  /* --------- useEffect set default data ---------*/
  useEffect(() => {
    if ((!_.isEmpty(defaultData) && contractType === defaultData?.type) || !_.isEmpty(turnState)) {
      setDefaultData();
    }
  }, [defaultData]);

  /* --------- useEffect SetMaxPriceTurn ---------*/
  useEffect(() => {
    const maxPriceTurn = productTurnData?.productTurn?.price || 0;
    const newPriceTurn = defaultData?.summary?.maximum
      ? calDtMemo?.condition !== defaultData?.condition
        ? productTurnData?.productTurn?.price
        : defaultData?.summary?.maximum
      : productTurnData?.productTurn?.price;

    setMaxPriceTurn(maxPriceTurn);

    form.setFieldsValue({
      priceTurn: newPriceTurn,
    });
  }, [productTurnData]);

  /* --------- useEffect Get Product ---------*/
  useEffect(() => {
    const fetchProductData = async () => {
      if (contractType && !_.isEmpty(calDtMemo?.productCode) && !_.isEmpty(calDtMemo?.condition)) {
        const result = await getProductDataFn({
          type: contractType,
          productCode: calDtMemo?.productCode,
          condition: calDtMemo?.condition,
          isTurn: true,
        });

        setProductTurnData(result);
      }
    };

    fetchProductData();
  }, [contractType, calDtMemo?.productCode, calDtMemo?.condition]);

  /* --------- Fn ---------*/
  const setDefaultData = async () => {
    const {condition, serialNumber, productTurn, summary} = defaultData;
    const maxPriceTurn = summary?.maximum || 0;

    form.setFieldsValue({
      condition: condition,
      product_code: productTurn?.code,
      serial_number: serialNumber,
      priceTurn: maxPriceTurn,
      ...turnState?.formData,
    });

    setCalculateData((prev) => ({
      ...prev,
      priceTurn: maxPriceTurn,
      condition: turnState?.formData?.condition || condition,
      productCode: turnState?.formData?.product_code || productTurn?.code,
    }));

    setMaxPriceTurn(maxPriceTurn);
  };

  const handleProductChange = (value) => {
    form.setFields([{name: 'product_code', value: value, errors: []}]);
    setCalculateData((prev) => ({...prev, productCode: value}));
  };

  const handleConditionChange = (value) => {
    form.setFields([{name: 'condition', value: value, errors: []}]);
    setCalculateData((prev) => ({...prev, condition: value}));
  };

  const handlePriceChange = (value) => {
    form.setFields([{name: 'priceTurn', value: value}]);
    setCalculateData((prev) => ({...prev, priceTurn: value}));
  };

  const handleChangeInsuranceStatus = (e) => {
    form.setFieldsValue({insurance_status: e});
    if (e === 'NO') {
      form.setFieldsValue({insurance_date: null});
    }
    setInsuranceStatus(e);
  };

  const handleClearForm = () => {
    form.resetFields();
    setProductTurnData({});
    setMaxPriceTurn(0);
    setInsuranceStatus(null);
    setCalculateData({...ININT_CAL});
    setFileMachineDetailImageList([]);
    setFileImeiImageList([]);
    setFileOtherImageList([]);
    setTurnState({});
  };

  const onChangeTab = async (formData) => {
    setTurnState({
      formData: formData,
      state: {productTurnData, maxPriceTurn, calculateData, fileMachineDetailImageList, fileImeiImageList, fileOtherImageList},
    });
    setActiveTab('PRODUCT_NEW');
  };

  return (
    <>
      {productDataLoading && <Spinners />}
      <Form
        form={form}
        name={formName}
        layout={'horizontal'}
        labelAlign="right"
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
        style={{padding: '0px 20px 20px 20px'}}
        onFinish={onChangeTab}
        onSubmit={(e) => e.preventDefault()}
        autoComplete="off"
        scrollToFirstError>
        <Form.Item name={'product_code'} label="ค้นหาสินค้า" rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <SelectProductAll placeholder={'เลือกสินค้า'} handleChange={(e) => handleProductChange(e?.value)} filter={{type: contractType}} />
        </Form.Item>

        <Form.Item
          name={'serial_number'}
          label="ซีเรียลเครื่อง"
          rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}
          extra={`ห้ามใส่ ' - ' และช่องว่าง เมื่อสร้างสัญญาใหม่โดยใช้เลขซีเรียลเครื่องเดิม`}>
          <Input onChange={(e) => handleTrimValue(form, 'serial_number', e?.target?.value)} />
        </Form.Item>

        <Divider />

        <Form.Item
          name={'branch'}
          label="สาขา"
          rules={[
            {required: true, message: 'จำเป็นต้องกรอก'},
            {
              validator(_, value) {
                if (value === 'undefinde') {
                  return Promise.reject(new Error('ข้อมูลไม่ถูกต้อง'));
                }
                return Promise.resolve();
              },
            },
          ]}
          initialValue={admin_branch}>
          <SelectBranch
            placeholder={'เลือกสาขา'}
            handleChange={(e) => {
              form.setFields([{name: 'branch', value: e?.value, errors: []}]);
            }}
          />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'condition'} label="Condition" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <SelectProductCondition
                placeholder={'เลือก Condition'}
                handleChange={(e) => handleConditionChange(e?.value)}
                filter={{productCode: calDtMemo?.productCode}}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={'priceTurn'}
              label="ราคา"
              labelCol={{span: 8}}
              rules={[
                {required: true, message: 'จำเป็นต้องกรอก'},
                {
                  validator(_, value) {
                    if (value <= 0) {
                      return Promise.reject(new Error('ราคาที่กรอกต้องมากกว่า 0'));
                    }
                    if (value > maxPriceTurn) {
                      return Promise.reject(new Error('ราคาที่กรอกต้องไม่เกินราคาสูงสุดของ Condition'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              extra={`สูงสุด ${maxPriceTurn ? convertNumberToComma(maxPriceTurn) : ''}`}>
              <InputNumber
                style={{width: '100%'}}
                formatter={(value, info) => {
                  if (!info.userTyping) {
                    return convertNumberToComma(value);
                  } else {
                    return value;
                  }
                }}
                onBlur={(e) => handlePriceChange(convertStringToNumber(e?.target?.value))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Col span={12}>
          <Form.Item name={'imei'} label="IMEI" labelCol={{span: 8}} rules={[{required: isRequired, message: 'จำเป็นต้องกรอก'}]}>
            <Input placeholder={'กรุณากรอกหมายเลขเครื่อง IMEI'} onChange={(e) => handleTrimValue(form, 'imei', e?.target?.value)} />
          </Form.Item>
        </Col>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'insurance_status'} label="ประกัน" labelCol={{span: 8}} rules={[{required: isRequired, message: 'จำเป็นต้องกรอก'}]}>
              <SelectInsuranceStatus placeholder={'กรุณาเลือกสถานะประกันของเครื่อง'} onChange={(e) => handleChangeInsuranceStatus(e)} />
            </Form.Item>
          </Col>
          {insuranceStatus === 'YES' && (
            <Col span={12}>
              <Form.Item name={'insurance_date'} label="วันที่หมดประกัน" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
                <DatePicker style={{width: '100%'}} placeholder={'กรุณากรอกวันที่หมดประกัน'} disabledDate={disabledBeforeCurrentDate} />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Form.Item
          name={'machineDetailImage'}
          label="รูปรายละเอียด"
          labelCol={{span: 4}}
          rules={[{required: isRequired, message: 'จำเป็นต้องกรอก'}]}
          extra={
            <Space size={[36, 16]} wrap>
              <span>{`เข้าไปที่ การตั้งค่า > เกี่ยวกับ`}</span>
              <span className="text-link" onClick={() => setIsModalVisible(true)}>
                ดูรูปตัวอย่าง
              </span>
            </Space>
          }>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileMachineDetailImageList}
            setLoading={setMachineDetailImageLoading}
            handleChange={(files) => {
              form.setFields([{name: 'machineDetailImage', value: files[0]?.url, errors: []}]);
              setFileMachineDetailImageList(files);
            }}
            handleRemove={() => {
              form.setFields([{name: 'machineDetailImage', value: null, errors: []}]);
              setFileMachineDetailImageList([]);
            }}
          />
        </Form.Item>

        <Form.Item name={'imeiImage'} label="รูป IMEI" rules={[{required: isRequired, message: 'จำเป็นต้องกรอก'}]} labelCol={{span: 4}}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileImeiImageList}
            setLoading={setImeiImageLoading}
            handleChange={(files) => {
              form.setFields([{name: 'imeiImage', value: files[0]?.url, errors: []}]);
              setFileImeiImageList(files);
            }}
            handleRemove={() => {
              form.setFields([{name: 'imeiImage', value: null, errors: []}]);
              setFileImeiImageList([]);
            }}
          />
        </Form.Item>

        <Form.Item name={'otherImage'} label="รูปอื่นๆ" labelCol={{span: 4}}>
          <Upload
            accept=".png,.jpg,.jpeg"
            multiple
            maxCount={20}
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileOtherImageList}
            setLoading={setOtherImageLoading}
            handleChange={(files) => {
              form.setFields([{name: 'otherImage', value: files, errors: []}]);
              setFileOtherImageList(files);
            }}
            handleRemove={() => {
              form.setFields([{name: 'otherImage', value: null, errors: []}]);
              setFileOtherImageList([]);
            }}
          />
        </Form.Item>

        <Row justify={'center'}>
          <ButtonTheme
            useFor="CLEAR"
            style={{width: 150}}
            onClick={() =>
              Modal.confirm({
                title: 'ยืนยัน',
                icon: <Icon.warningAlert />,
                content: 'ต้องการล้างข้อมูลทั้งหมดใช่หรือไม่?',
                onOk() {
                  handleClearForm();
                },
                okText: 'ยืนยัน',
                cancelText: 'ยกเลิก',
              })
            }
          />
          <ButtonTheme
            useFor="CUSTOM"
            htmlType="submit"
            title={'ถัดไป'}
            style={{width: 150}}
            loading={machineDetailImageLoading || imeiImageLoading || otherImageLoading}
          />
        </Row>
      </Form>
      <Modal title="ตัวอย่างรูปรายละเอียดเครื่อง" open={isModalVisible} onCancel={() => setIsModalVisible(false)} width={380} footer={null}>
        <img src={Example} style={{width: '100%'}} alt="No" />
      </Modal>
    </>
  );
};

export default FormTurn2Turn;
