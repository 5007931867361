import {useEffect, useMemo, useState} from 'react';
import {Form, Input, Divider, Row, Col, InputNumber, Checkbox, Modal, Space, Select} from 'antd';
import _ from 'lodash';
import {FILTER_MASTER, CONTRACT_TYPE} from '../constants';
import Example from '../../../../assets/images/example_detail.jpg';
import {getImageObject} from '../../fn';
import {convertNumberToComma, convertStringToNumber} from '../../../../functions/fn';
import {handleTrimValue} from '../../../../utils/antform';
import {Icon} from '../../../../resources/icon';
import {ButtonTheme, Spinners, Upload} from '../../../../components';
import {DatatableCouponDetail, InputCoupon} from '../components';
import {
  SelectMDMProvider,
  SelectMDMSite,
  SelectProductAll,
  SelectTypeLock,
  SelectProductCondition,
  SelectBranch,
} from '../../../../components/inputfromapi';
import {GCS_BUCKET_CONTRACT_IMAGE, GCS_PATH_CONTRACT_IMAGE} from '../../../../env';
import {POST, RAMDOM_MAIL, GET_FGF_DISCOUNT} from '../../../../services';
import {getAdminMDMDefault} from '../../../../apiv2';
import {calculateInstallment} from '../../../../services/api/svapi/product';
import {useGetProductData} from '../hooks';
/*
coupon: [
  {
    coupon_id: 'PON-25-0006',
    coupon_code: 'REGISTERCOUPON',
    coupon_discount: 200,
    coupon_description: 'REGISTERCOUPON',
  },
];
*/

const formName = 'FormTurn1';
const {TURN1} = CONTRACT_TYPE;
const INIT_DISCOUNT_PERIOD = {
  label: '', //'ส่วนลดค่าเครื่อง:1000'
  code: '', //DISPERIOD1000
  discount: '', // 1000
};
const ININT_CAL = {
  contractType: TURN1, //ประเภทสัญญา
  productCode: '', //รหัสสินค้า
  condition: '', //เงื่อนไข
  price: 0, //ราคา
  priceTurn: 0, //ราคาเทิร์น
  down_amount: 0, //เงินดาวน์
  periods: 0, //งวด
  coupon: [], //คูปอง
  discountPeriod: {...INIT_DISCOUNT_PERIOD}, //ส่วนลดค่าเครื่อง
  discountTurn1: {...INIT_DISCOUNT_PERIOD}, //ส่วนลดเทิร์น1
};

export const FormTurn1 = ({defaultData, data, fn}) => {
  const {admin_branch, record, contractType} = data;
  const {checkCustomer, setErrorMessage} = fn;

  /* Form */
  const [form] = Form.useForm();
  /* Product */
  const [productData, setProductData] = useState({});
  /* Maximum */
  const [maxPriceTurn, setMaxPriceTurn] = useState(0);
  /* Coupon */
  const [couponList, setCouponList] = useState([]); // ['REGISTERCOUPON', 'REGISTERCOUPON1']
  const [couponDataList, setCouponDataList] = useState([]); // coupon only
  const [couponDetailList, setCouponDetailList] = useState([]); // รายละเอียดคูปอง coupon + discountPeriod
  /* Type Lock */
  const [typeLock, setTypeLock] = useState('');
  const [defaultMDMByKey, setDefaultMDMByKey] = useState('');
  /* Frind Get Friend */
  const [fgfData, setFGFData] = useState({});
  const [fgfTextExtra, setFGFTextExtra] = useState('');
  /* Calculate */
  const [calculateData, setCalculateData] = useState({...ININT_CAL});
  /* File */
  const [fileList, setFileList] = useState([]);
  const [fileMachineDetailImageList, setFileMachineDetailImageList] = useState([]);
  const [fileImeiImageList, setFileImeiImageList] = useState([]);
  const [fileOtherImageList, setFileOtherImageList] = useState([]);
  /* CheckMail */
  const [checkMail, setCheckMail] = useState(false);
  /* Modal */
  const [isModalVisible, setIsModalVisible] = useState(false);
  /* Loading API */
  const [imageLoading, setImageLoading] = useState(false);
  const [machineDetailImageLoading, setMachineDetailImageLoading] = useState(false);
  const [imeiImageLoading, setImeiImageLoading] = useState(false);
  const [otherImageLoading, setOtherImageLoading] = useState(false);
  const [calculateLoading, setCalculateLoading] = useState(false);
  const [getAdminMDMDefaultLoading, setMDMDefaultLoading] = useState(false);
  /* Hook */
  const [getProductDataFn, periodOptions, productDataLoading] = useGetProductData();

  /* --------- useMemo calculateData ---------*/
  const calDtMemo = useMemo(() => {
    return calculateData;
  }, [calculateData]);

  /* --------- useEffect get default MDM ---------*/
  useEffect(() => {
    if (record?.id_card) {
      getAdminMDMDefaultFn();
    }
  }, [record?.id_card]);

  /* --------- useEffect set default data ---------*/
  useEffect(() => {
    if (!_.isEmpty(defaultData) && contractType === defaultData?.type) {
      setDefaultData();
    } else {
      getFGFCodeFn(record?.id_card);
    }
  }, [defaultData]);

  /* --------- API Fn ---------*/
  const getAdminMDMDefaultFn = async () => {
    try {
      setMDMDefaultLoading(true);
      const res = await getAdminMDMDefault();

      if (res) {
        const MDMDefaultKey = _.keyBy(res, 'type_lock');
        setDefaultMDMByKey(MDMDefaultKey);
      }
    } catch (err) {
      Modal.error({
        title: 'ไม่สามารถดึงค่า default MDM ได้',
        content: err?.message,
      });
    } finally {
      setMDMDefaultLoading(false);
    }
  };

  const getFGFCodeFn = async (idCard) => {
    try {
      const res = await POST(GET_FGF_DISCOUNT, {id_card: idCard});
      const {friend_get_friend, success} = res;
      if (success) {
        form.setFieldValue('friend_get_friend', friend_get_friend?.code);
        setFGFData(friend_get_friend);
        setFGFTextExtra(`ส่วนลด ${convertNumberToComma(friend_get_friend?.commission_amount)} บาท` || '');
        return friend_get_friend;
      } else {
        setFGFTextExtra(res?.message || 'ไม่พบข้อมูล');
        return null;
      }
    } catch (err) {
      Modal.error({title: 'ไม่สามารถดึงค่า friend get friend ได้', content: err?.message});
    }
  };

  const calculateFn = async (params) => {
    try {
      const {down_amount, periods, price, priceTurn, discountPeriod, discountTurn1, coupon, fgfCommission} = params;

      if (contractType && (down_amount === 0 || down_amount) && periods && price && priceTurn) {
        setCalculateLoading(true);

        const inputParmas = {
          type: contractType,
          price: price || 0,
          priceTurn: priceTurn || 0,
          down: down_amount,
          discountPeriod: Number(discountPeriod?.discount) || 0,
          discountTurn1: Number(discountTurn1?.discount) || 0,
          period: periods,
          couponDiscount: (_.sumBy(coupon, 'coupon_discount') || 0) + (fgfCommission || fgfData?.commission_amount || 0),
        };

        const response = await calculateInstallment(inputParmas);
        const {success, result} = response;

        if (success) {
          const sumDiscount = result?.discount?.couponDiscount + result?.discount?.discountPeriod + result?.discount?.discountTurn1;

          const setFieldsValue = {
            discount: sumDiscount || 0,
            installments: result?.installments || 0,
            total: result?.total || 0,
            change_amount: result?.changeAmount,
          };
          form.setFieldsValue(setFieldsValue);
        }
      }
    } catch (err) {
      Modal.error({
        title: 'ไม่สามารถคํานวณราคาได้',
        content: err?.message,
      });
    } finally {
      setCalculateLoading(false);
    }
  };

  const getMailIcloudOld = async () => {
    const obj = {
      useOldMail: true,
      idCard: record?.id_card,
    };
    try {
      const {success, result} = await POST(RAMDOM_MAIL, obj);
      if (success) {
        setCheckMail(true);
        form.setFieldValue('checkMail', true);
        form.setFieldValue('mail', result.mail);
      }
    } catch (err) {
      const {message} = err;
      setCheckMail(false);
      setErrorMessage({form, error: true, name: 'checkMail', txtError: [message]});
    }
  };

  /* --------- Fn ---------*/
  const getCalData = ({periods, down_amount, priceTurn, prdDt = productData, coupon = couponDataList || []}) => {
    if (_.isEmpty(prdDt)) return {};

    const formValue = form.getFieldsValue();
    const newPeriods = periods ?? formValue?.periods;
    const newDownAmount = down_amount || formValue?.down_amount;
    const newPriceTurn = priceTurn || formValue?.priceTurn || prdDt?.productTurn?.price;
    const price = prdDt?.productSaleByPeriod[newPeriods]?.price ?? 0;
    const maxPriceTurn = prdDt?.productTurn?.price ?? 0;
    const saleGrade = prdDt?.productTurn?.sale_grade ?? '';
    const calDt = {price, periods: newPeriods, priceTurn: newPriceTurn, down_amount: newDownAmount};
    const calCouponDt = {
      discountPeriod: prdDt?.productSaleByPeriod[newPeriods]?.discountPeriod ?? {...INIT_DISCOUNT_PERIOD},
      discountTurn1: prdDt?.productSaleByPeriod[newPeriods]?.discountTurn1 ?? {...INIT_DISCOUNT_PERIOD},
      coupon: coupon,
    };
    return {maxPriceTurn, saleGrade, calDt, calCouponDt};
  };

  const setDefaultData = async () => {
    const fgfData = await getFGFCodeFn(record?.id_card);

    const {condition, coupon = [], summary, discountPeriod, discountTurn1, totalDiscount, product} = defaultData;
    const prdResData = await getProductDataFn({
      type: contractType,
      productCode: product?.code,
      condition: condition,
      isTurn: true,
    });

    const maxPriceTurn = prdResData?.productTurn?.price || 0;
    const periods = summary?.period;
    const price = prdResData?.productSaleByPeriod[periods]?.price || 0;
    const priceTurn = summary?.maximum || 0;
    const downAmount = summary?.downPayment || 0;
    const saleGrade = prdResData?.productTurn?.sale_grade || '';
    const calDt = {price: price, periods, priceTurn, down_amount: downAmount};
    const calCouponDt = {
      discountPeriod: discountPeriod ?? {...INIT_DISCOUNT_PERIOD},
      discountTurn1: discountTurn1 ?? {...INIT_DISCOUNT_PERIOD},
      coupon: coupon,
      fgfCommission: fgfData?.commission_amount || 0,
    };

    form.setFieldsValue({
      product_code: product?.code,
      down_amount: downAmount || 0,
      periods: periods,
      installments: summary?.installmet || 0,
      discount: totalDiscount || 0,
      total: summary?.total || 0,
      condition: condition,
      price: price,
      priceTurn: priceTurn,
      sale_grade: saleGrade,
    });

    setCalculateData((prev) => ({
      ...prev,
      condition: condition,
      down_amount: summary?.downPayment || 0,
      periods: periods,
      coupon: [...coupon],
      discountPeriod: discountPeriod ?? {...INIT_DISCOUNT_PERIOD},
      discountTurn1: discountTurn1 ?? {...INIT_DISCOUNT_PERIOD},
      productCode: product?.code,
      priceTurn: priceTurn,
    }));

    handleSetData({maxPriceTurn, saleGrade, calDt, calCouponDt, prdDt: prdResData});
  };

  const handleProductChange = async (value) => {
    form.setFieldsValue({product_code: value});
    setCalculateData((prev) => ({...prev, productCode: value}));
    handleClearProduct();
  };

  const handleConditionChange = async (value) => {
    const productCode = form.getFieldValue('product_code');
    const prdResData = await getProductDataFn({
      type: contractType,
      productCode: productCode,
      condition: value,
      isTurn: true,
    });

    const periods = prdResData?.periodOptions?.[0]?.value; //เปลี่ยน condition ให้ set งวดน้อยสุด
    const priceTurn = prdResData?.productTurn?.price; //เปลี่ยน condition ให้ set ราคารับซื้อเลย
    const calDt = getCalData({periods, priceTurn, prdDt: prdResData});

    form.setFieldsValue({periods, condition: value});
    setCalculateData((prev) => ({...prev, condition: value}));
    handleSetData({...calDt, prdDt: prdResData});
  };

  const handlePeriodsChange = async (value) => {
    const calDt = getCalData({periods: value});
    setCalculateData((prev) => ({...prev, periods: value}));
    handleSetData({...calDt});
  };

  const handlePriceTurnChange = async (value) => {
    form.setFieldValue('priceTurn', value);

    const calDt = getCalData({priceTurn: value});
    handleSetData({...calDt});
  };

  const handleDownAmountChange = (value) => {
    form.setFieldValue('down_amount', value);

    const calDt = getCalData({down_amount: value});
    handleSetData({...calDt});
  };

  const handleCouponChange = (value) => {
    const calDt = getCalData({coupon: value});
    handleSetData({...calDt});
  };

  const handleSetCouponList = ({discountPeriod, discountTurn1, coupon = []}) => {
    const couponCode = _.map(coupon, 'coupon_code');
    const couponCodeAll = _.compact([discountPeriod?.code, discountTurn1?.code, ...couponCode]);
    const couponDetail = [
      ...(discountPeriod?.code
        ? [
            {
              coupon_code: discountPeriod.code,
              coupon_description: discountPeriod.label,
              coupon_discount: discountPeriod.discount,
            },
          ]
        : []),
      ...(discountTurn1?.code
        ? [
            {
              coupon_code: discountTurn1.code,
              coupon_description: discountTurn1.label,
              coupon_discount: discountTurn1.discount,
            },
          ]
        : []),
      ...(coupon ?? []),
    ];

    setCouponList(couponCodeAll);
    setCouponDataList([...coupon]);
    setCouponDetailList(couponDetail);

    setCalculateData((prev) => ({...prev, discountPeriod: discountPeriod, discountTurn1: discountTurn1, coupon: coupon}));
  };

  const handleChangeCheckMail = (e) => {
    // clear Error of field 'checkMail' before fire api
    setErrorMessage({form, error: false, name: 'checkMail', txtError: [], value: e.target.checked});

    if (e?.target?.checked) {
      setCheckMail(true);
      getMailIcloudOld();
    } else {
      form.setFieldValue('mail', '');
      setCheckMail(false);
    }
  };
  const handleChangeTypeLock = (value) => {
    setTypeLock(value);
    form.setFields([
      {name: 'type_lock', value: value, errors: []},
      {name: 'mdm_provider_code', value: defaultMDMByKey[value]?.mdm_provider_code || '', errors: []},
      {name: 'mdm_site_code', value: defaultMDMByKey[value]?.mdm_site_code || '', errors: []},
    ]);
  };

  const handleSetData = async ({maxPriceTurn, saleGrade, calDt, calCouponDt, prdDt = productData}) => {
    try {
      if (!_.isEmpty(prdDt)) {
        form.setFieldsValue({
          periods: calDt?.periods,
          price: calDt?.price,
          priceTurn: calDt?.priceTurn,
          sale_grade: saleGrade,
        });

        setProductData(prdDt);
        setMaxPriceTurn(maxPriceTurn);
        setCalculateData((prev) => ({...prev, ...calDt, ...calCouponDt}));

        handleSetCouponList(calCouponDt);
        await calculateFn({...calDt, ...calCouponDt});
      }
    } catch (error) {}
  };

  const handleClearForm = () => {
    form.resetFields();
    setProductData({});
    setMaxPriceTurn(0);
    setCouponList([]);
    setCouponDataList([]);
    setCouponDetailList([]);
    setTypeLock('');
    setCalculateData({...ININT_CAL});
    setFileList([]);
    setFileMachineDetailImageList([]);
    setFileImeiImageList([]);
    setFileOtherImageList([]);
    setCheckMail(false);
  };

  const handleClearProduct = () => {
    form.resetFields(['periods', 'condition', 'price', 'priceTurn', 'sale_grade', 'discount', 'installments', 'total']);
    setMaxPriceTurn(0);
    setCouponList([]);
    setCouponDataList([]);
    setCouponDetailList([]);
    setCalculateData((prev) => ({...prev, discountPeriod: {...INIT_DISCOUNT_PERIOD}, discountTurn1: {...INIT_DISCOUNT_PERIOD}, coupon: []}));
  };

  const onFinish = async (values) => {
    try {
      const {
        product_code,
        serial_number,
        branch,
        condition,
        priceTurn,
        down_amount,
        friend_get_friend,
        periods,
        type_lock,
        mdm_provider_code,
        mdm_site_code,
        checkMail,
        mail,
        image,
        machineDetailImage,
        imeiImage,
        otherImage,
        remark,
        isOldContract,
      } = values;

      const productCode = product_code;
      const productName = productData?.productSaleByPeriod[periods]?.productName || '';

      const couponCode = _.map(couponDataList, (el) => {
        return {code: el?.coupon_code};
      });

      const payload = {
        user_id: record?._id,
        type: contractType,
        branch: branch,
        productCode: [{code: productCode, name: productName, serialNumber: serial_number}],
        serial_number: [
          {
            product_code: productCode,
            product_name: productName,
            serial_number: serial_number,
            price: priceTurn, //ราคารับซื้อ
          },
        ],
        serial_number2: serial_number,
        contract: {
          type: contractType,
          down_amount: down_amount,
          periods: periods,
          type_lock: type_lock === 'NONE' ? '' : type_lock,
          mdm_provider_code: mdm_provider_code,
          mdm_site_code: mdm_site_code,
          checkMail: checkMail,
          package: '',
          price_package: 0,
          promotion_code: 'none',
          couponCode: couponCode,
          ...(checkMail ? {mail} : {}),
          ...(image ? getImageObject('image', image) : {}),
          ...(machineDetailImage ? getImageObject('machineDetailImage', machineDetailImage) : {}),
          ...(imeiImage ? getImageObject('imeiImage', imeiImage) : {}),
          ...(otherImage ? {otherImage: [{url: otherImage}]} : []),
          discount_period: calDtMemo?.discountPeriod?.code ? calDtMemo?.discountPeriod : {},
          discount_turn1: calDtMemo?.discountTurn1?.code ? calDtMemo?.discountTurn1 : {},
        },
        coupon_id: couponDataList[0]?.coupon_id ?? '',
        remark: remark,
        isOldContract: isOldContract,
        ...(friend_get_friend ? {friend_get_friend: fgfData} : {}),
        condition: condition,
      };

      await checkCustomer(payload);
    } catch (error) {}
  };

  return (
    <>
      {(getAdminMDMDefaultLoading || productDataLoading || calculateLoading) && <Spinners />}
      <Form
        form={form}
        name={formName}
        layout={'horizontal'}
        labelAlign="right"
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
        style={{padding: '0px 20px 20px 20px'}}
        onFinish={onFinish}
        autoComplete="off"
        scrollToFirstError>
        <Form.Item name={'product_code'} label="ค้นหาสินค้า" rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <SelectProductAll placeholder={'เลือกสินค้า'} handleChange={(e) => handleProductChange(e?.value)} filter={{type: contractType}} />
        </Form.Item>

        <Form.Item
          name={'serial_number'}
          label="ซีเรียลเครื่อง"
          rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}
          extra={`ห้ามใส่ ' - ' และช่องว่าง เมื่อสร้างสัญญาใหม่โดยใช้เลขซีเรียลเครื่องเดิม`}>
          <Input onChange={(e) => handleTrimValue(form, 'serial_number', e?.target?.value)} />
        </Form.Item>

        <Divider />

        <Form.Item
          name={'branch'}
          label="สาขา"
          rules={[
            {required: true, message: 'จำเป็นต้องกรอก'},
            {
              validator(_, value) {
                if (value === 'undefinde') {
                  return Promise.reject(new Error('ข้อมูลไม่ถูกต้อง'));
                }
                return Promise.resolve();
              },
            },
          ]}
          initialValue={admin_branch}>
          <SelectBranch
            placeholder={'เลือกสาขา'}
            handleChange={(e) => {
              form.setFields([{name: 'branch', value: e?.value, errors: []}]);
            }}
          />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'condition'} label="Condition" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <SelectProductCondition
                placeholder={'เลือก Condition'}
                handleChange={(e) => handleConditionChange(e?.value)}
                filter={{productCode: calDtMemo?.productCode}}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={'priceTurn'}
              label="ราคา"
              labelCol={{span: 8}}
              rules={[
                {required: true, message: 'จำเป็นต้องกรอก'},
                {
                  validator(_, value) {
                    if (value <= 0) {
                      return Promise.reject(new Error('ราคาที่กรอกต้องมากกว่า 0'));
                    }
                    if (value > maxPriceTurn) {
                      return Promise.reject(new Error('ราคาที่กรอกต้องไม่เกินราคาสูงสุดของ Condition'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              extra={`สูงสุด ${maxPriceTurn ? convertNumberToComma(maxPriceTurn) : ''}`}>
              <InputNumber
                style={{width: '100%'}}
                formatter={(value, info) => {
                  if (!info.userTyping) {
                    return convertNumberToComma(value);
                  } else {
                    return value;
                  }
                }}
                onBlur={(e) => handlePriceTurnChange(convertStringToNumber(e?.target?.value))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'sale_grade'} label="Sale Grade" labelCol={{span: 8}}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'price'} label="ราคาขาย" labelCol={{span: 8}}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name={'down_amount'}
              label="เงินดาวน์"
              rules={[
                {required: true, message: 'จำเป็นต้องกรอก'},
                {
                  validator(_, value) {
                    if (value < 0) {
                      return Promise.reject(new Error('ราคาที่กรอกต้องมากกว่า 0'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              labelCol={{span: 8}}>
              <InputNumber
                style={{width: '100%'}}
                formatter={(value, info) => {
                  if (!info.userTyping) {
                    return convertNumberToComma(value);
                  } else {
                    return value;
                  }
                }}
                onBlur={(e) => handleDownAmountChange(convertStringToNumber(e?.target?.value))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'friend_get_friend'} label="รหัสเพื่อนชวนเพื่อน" labelCol={{span: 8}} extra={fgfTextExtra}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'coupon_code'} label={'Code คูปอง'} labelCol={{span: 8}} extra={'เมื่อกรอกโค้ดเสร็จ กรุณากด "Enter'}>
              <InputCoupon
                form={form}
                fieldName={'coupon_code'}
                setErrorMessage={setErrorMessage}
                disabledCoupon={_.compact([calDtMemo?.discountPeriod?.code, calDtMemo?.discountTurn1?.code])}
                couponList={couponList}
                setCouponList={setCouponList}
                couponDataList={couponDataList}
                setCouponDataList={setCouponDataList}
                productCodeList={calDtMemo?.productCode ? [calDtMemo?.productCode] : []}
                disabled={_.isEmpty(calDtMemo?.productCode)}
                onCouponChange={(e) => handleCouponChange(e)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'discount'} label="ส่วนลด" labelCol={{span: 8}} initialValue={0}>
              <InputNumber style={{width: '100%'}} disabled formatter={convertNumberToComma} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={2}></Col>
          <Col span={22}>
            <DatatableCouponDetail data={couponDetailList} />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'periods'} label="จำนวนงวด" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <Select style={{width: '100%'}} placeholder={'เลือกจำนวนงวด'} options={periodOptions} onChange={(e) => handlePeriodsChange(e)} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'installments'} label="ยอดผ่อนต่อเดือน" labelCol={{span: 8}} initialValue={0}>
              <InputNumber style={{width: '100%'}} disabled formatter={convertNumberToComma} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'total'} label="ยอดชำระรวม" labelCol={{span: 8}} initialValue={0}>
              <InputNumber style={{width: '100%'}} disabled formatter={convertNumberToComma} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'type_lock'} label="การล็อคเครื่อง" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <SelectTypeLock
                placeholder={'เลือกรูปแบบการล็อกเครื่อง'}
                handleChange={(e) => {
                  form.setFields([{name: 'type_lock', value: e?.value, errors: []}]);
                  handleChangeTypeLock(e?.value);
                }}
                filter={FILTER_MASTER}
              />
            </Form.Item>
          </Col>
        </Row>

        {typeLock && ['MDMICLOUD', 'MDM'].includes(typeLock) && (
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={'mdm_provider_code'}
                label="ผู้ให้บริการ MDM"
                labelCol={{span: 8}}
                rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
                <SelectMDMProvider
                  placeholder={'เลือกผู้ให้บริการ MDM'}
                  handleChange={(e) => {
                    form.setFields([{name: 'mdm_provider_code', value: e?.value, errors: []}]);
                  }}
                  filter={FILTER_MASTER}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'mdm_site_code'} label="MDM Site" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
                <SelectMDMSite
                  placeholder={'เลือก MDM Site'}
                  handleChange={(e) => {
                    form.setFields([{name: 'mdm_site_code', value: e?.value, errors: []}]);
                  }}
                  filter={FILTER_MASTER}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={[16, 16]}>
          {typeLock && ['MDMICLOUD', 'ICLOUD'].includes(typeLock) && (
            <Col span={12}>
              <Form.Item
                name={'checkMail'}
                label="อีเมล์ ไอคลาวน์"
                labelCol={{span: 8}}
                initialValue={false}
                valuePropName="checked"
                onChange={handleChangeCheckMail}>
                <Checkbox style={{marginLeft: 5}}>ใช้บัญชีเดิม</Checkbox>
              </Form.Item>
            </Col>
          )}
          {typeLock && ['MDMICLOUD', 'ICLOUD'].includes(typeLock) && checkMail && (
            <Col span={12}>
              <Form.Item name={'mail'} label="อีเมล์" labelCol={{span: 8}}>
                <Input disabled />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Form.Item name={'image'} label="รูปผู้ทำสัญญา" labelCol={{span: 4}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileList}
            setLoading={setImageLoading}
            handleChange={(files) => {
              form.setFields([{name: 'image', value: files[0]?.url, errors: []}]);
            }}
            handleRemove={() => {
              form.setFields([{name: 'image', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item
          name={'machineDetailImage'}
          label="รูปรายละเอียด"
          labelCol={{span: 4}}
          rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}
          extra={
            <Space size={[36, 16]} wrap>
              <span>{`เข้าไปที่ การตั้งค่า > เกี่ยวกับ`}</span>
              <span className="text-link" onClick={() => setIsModalVisible(true)}>
                ดูรูปตัวอย่าง
              </span>
            </Space>
          }>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileMachineDetailImageList}
            setLoading={setMachineDetailImageLoading}
            handleChange={(files) => {
              form.setFields([{name: 'machineDetailImage', value: files[0]?.url, errors: []}]);
            }}
            handleRemove={() => {
              form.setFields([{name: 'machineDetailImage', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item name={'imeiImage'} label="รูป IMEI" labelCol={{span: 4}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileImeiImageList}
            setLoading={setImeiImageLoading}
            handleChange={(files) => {
              form.setFields([{name: 'imeiImage', value: files[0]?.url, errors: []}]);
            }}
            handleRemove={() => {
              form.setFields([{name: 'imeiImage', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item name={'otherImage'} label="รูปอื่นๆ" labelCol={{span: 4}}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileOtherImageList}
            setLoading={setOtherImageLoading}
            handleChange={(files) => {
              form.setFields([{name: 'otherImage', value: files[0]?.url, errors: []}]);
            }}
            handleRemove={() => {
              form.setFields([{name: 'otherImage', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item name={'remark'} label="หมายเหตุ" labelCol={{span: 4}}>
          <Input />
        </Form.Item>

        <Form.Item name={'isOldContract'} label="สร้างสัญญาแบบเก่า" labelCol={{span: 4}} initialValue={false} valuePropName="checked">
          <Checkbox style={{marginLeft: 5}} />
        </Form.Item>

        <Form.Item name={'change_amount'} label="เงินทอน" labelCol={{span: 4}}>
          <InputNumber style={{width: '100%'}} formatter={convertNumberToComma} disabled />
        </Form.Item>

        <Row justify={'center'}>
          <ButtonTheme
            useFor="CLEAR"
            style={{width: 150}}
            onClick={() =>
              Modal.confirm({
                title: 'ยืนยัน',
                icon: <Icon.warningAlert />,
                content: 'ต้องการล้างข้อมูลทั้งหมดใช่หรือไม่?',
                onOk() {
                  handleClearForm();
                },
                okText: 'ยืนยัน',
                cancelText: 'ยกเลิก',
              })
            }
          />
          <ButtonTheme
            useFor="CUSTOM"
            htmlType="submit"
            title={'ยืนยัน'}
            style={{width: 150}}
            loading={imageLoading || machineDetailImageLoading || imeiImageLoading || otherImageLoading}
          />
        </Row>
      </Form>
      <Modal title="ตัวอย่างรูปรายละเอียดเครื่อง" open={isModalVisible} onCancel={() => setIsModalVisible(false)} width={380} footer={null}>
        <img src={Example} style={{width: '100%'}} alt="No" />
      </Modal>
    </>
  );
};
