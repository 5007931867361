import {useState} from 'react';
import axios from 'axios';
import {Modal} from 'antd';
import {API_URL} from '../../../../env.js';

export const useSaveUnlockMDM = () => {
  const [loading, setLoading] = useState(false);

  const saveUnlockMDM = async (input) => {
    try {
      if (input?.contract_no) {
        setLoading(true);

        const response = await axios.put(
          `${API_URL}/admin/unlock-icloud/${input?.contract_no}/mdm_unlock`,
          {},
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('token')}`,
              'content-type': 'application/json',
            },
          },
        );

        if (response?.data?.success) return true;
        throw new Error();
      }
    } catch (err) {
      Modal.error({
        title: 'บันทึกข้อมูลไม่สำเร็จ',
        content: err?.response?.data?.message || '',
      });
    } finally {
      setLoading(false);
    }
  };

  return [saveUnlockMDM, loading];
};
