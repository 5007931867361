import {useCallback, useState} from 'react';
import {Modal} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import _ from 'lodash';
import {getProductData} from '../../../../services/api/svapi/product';

export const useGetProductData = () => {
  const [loading, setLoading] = useState(false);
  const [periodOptions, setPeriodOptions] = useState([]);

  const getProductDataFn = useCallback(async (params) => {
    try {
      setLoading(true);
      const {result} = await getProductData(params);
      let dtResponse = setProductList(result);

      if (result?.warning) {
        return new Promise((resolve) => {
          Modal.confirm({
            title: 'ยืนยันเพื่อใช้ราคาล่าสุด',
            icon: <ExclamationCircleOutlined />,
            content: result?.warning,
            onOk() {
              resolve(dtResponse);
            },
            onCancel() {
              resolve(null);
            },
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
          });
        });
      }

      return dtResponse;
    } catch (err) {
      setPeriodOptions([]);
      Modal.error({
        title: 'ไม่สามารถดึงข้อมูลสินค้าได้',
        content: err?.message,
      });
      return null;
    } finally {
      setLoading(false);
    }
  }, []); // ✅ Memoized function ไม่มี dependencies

  const setProductList = (result) => {
    try {
      const dt = _.sortBy(
        _.map(result?.productSale, (dt) => ({
          label: dt?.period,
          value: dt?.period,
          source: dt,
        })),
        ['value'],
      );

      const productSaleByPeriod = _.keyBy(
        _.map(result?.productSale, (dt) => ({
          ...dt,
          productName: result?.productName,
        })),
        'period',
      );

      const sourceDt = {
        ...result,
        productSaleByPeriod,
        periodOptions: dt,
      };

      setPeriodOptions(dt);
      return sourceDt;
    } catch (err) {}
  };

  return [getProductDataFn, periodOptions, loading];
};
