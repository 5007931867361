export const branchValue = ['CENTER', 'TB', 'RS', 'KHONKAEN', 'CHONBURI', 'PHITSANULOK']
export const typeOld = [{ value: "INSTALLMENT", label: "ผ่อนดาวน์" }, { value: "REFINANCE", label: "ไอโฟนแลกเงิน" }]
export const typeNew = [{ value: "INSTALLMENT", label: "ผ่อนดาวน์" }, { value: "TURN1", label: "เทิร์น1" }, { value: "TURN2", label: "เทิร์น2" }, { value: "SECONDHAND", label: "ขายขาด" }]
export const typeNewCombine = [{ value: "INSTALLMENT", label: "ผ่อนดาวน์" }, { value: "REFINANCE", label: "ไอโฟนแลกเงิน" }, { value: "TURN1", label: "เทิร์น1" }, { value: "TURN2", label: "เทิร์น2" }, { value: "SECONDHAND", label: "ขายขาด" }]
export const optionSignOnline = [{ value: 'WAITING_SIGNATURE', label: 'รอลูกค้าเซ็น' }, { value: 'SUCCESS', label: 'เซ็นแล้ว' }]
export const statusApprove = [{ value: "APPROVED", label: "อนุมัติ" }, { value: "REJECTED", label: "ไม่อนุมัติ" }]
export const optionsRoundPayment = [{ label: 'ต่อสัปดาห์', value: 'WEEK' }, { label: 'ต่อเดือน', value: 'MONTH' }]
export const optionsPeriod = [{ label: '6', value: '6' }, { label: '10', value: '10' }, { label: '12 *สำหรับนักศึกษาเท่านั้น', value: '12' }]
export const optionsCountPerson = [{ value: '1', label: '1 คน' }, { value: '2', label: '2 คน' }, { value: '3', label: '3 คน' }, { value: '4', label: '4 คนขึ้นไป' }, { value: '0', label: 'ไม่มี' }]
export const optionsPrefix = [{ value: 'นาย', label: 'นาย' }, { value: 'นาง', label: 'นาง' }, { value: 'นางสาว', label: 'นางสาว' }, { value: 'อื่นๆ', label: 'อื่นๆ' }]
export const optionsIncomeDoc = [{ value: '3MONTH', label: 'Statement 3 เดือนล่าสุด' }, { value: 'CURRENT', label: 'สลิปเงินเดือนล่าสุด' }, { value: 'NO', label: 'ไม่มี' }]
export const optionsOccupation = [{ value: 'บุคคลทั่วไป', label: 'บุคคลทั่วไป' }, { value: 'นักเรียน/นักศึกษา', label: 'นักเรียน/นักศึกษา' }]
export const optionsPhoneSystem = [{ value: 'เติมเงิน', label: 'เติมเงิน' }, { value: 'รายเดือน', label: 'รายเดือน' }, { value: 'รายปี', label: 'รายปี' }]
export const optionsExperience = [{ value: 'น้อยกว่า 3 เดือน', label: 'น้อยกว่า 3 เดือน' }, { value: '3-6 เดือน', label: '3-6 เดือน' }, { value: '6 เดือน - 2 ปี', label: '6 เดือน - 2 ปี' }, { value: '2 ปีขึ้นไป', label: '2 ปีขึ้นไป' }]
export const optionRelationship = [{ value: 'พ่อแม่', label: 'พ่อแม่' }, { value: 'พี่น้อง', label: 'พี่น้อง' }, { value: 'สามี/ภรรยา/แฟน', label: 'สามี/ภรรยา/แฟน' },
{ value: 'ลูก', label: 'ลูก' }, { value: 'ญาติ', label: 'ญาติ' }, { value: 'เพื่อน/หัวหน้างาน', label: 'เพื่อน/หัวหน้างาน' }]
export const optionDegree = [{ value: "ม.ปลาย", label: "ม.ปลาย" }, { value: "ปวช", label: "ปวช" }, { value: "ปวส", label: "ปวส" }, { value: "ป.ตรี", label: "ป.ตรี" }, { value: "สูงกว่าป.ตรี", label: "สูงกว่าป.ตรี" }]
export const dateType = [{ value: "registerDate", label: "วันที่ลงทะเบียน" }, { value: "appointmentDate", label: "วันที่นัดหมาย" }]
export const optionWalkIn = [{ value: 'walk_in', label: "Walk in" }, { value: 'none', label: "ไม่ Walk in" }]
export const optionProductHand = [{ value: 'NEW', label: "สินค้ามือหนึ่ง" }, { value: 'SECOND', label: "สินค้ามือสอง" }]
export const optionProductGroup = [{ value: 'Smartphone', label: "iPhone" }, { value: 'Tablet', label: "iPad" }]
export const optionBusinessDoc = [{ value: 'REGISTERED_BUSINESS', label: "กิจการจดทะเบียน" }, { value: 'UNREGISTERED_BUSINESS', label: "กิจการไม่จดทะเบียน" }]
export const optionSaleChannel = [{ value: 'ONLINE', label: "ช่องทางออนไลน์" }, { value: 'OFFLINE', label: "ช่องทางหน้าร้าน" }]

export const optionSourceIncomeStudent = [
    { value: 'พ่อแม่ หรือผู้ปกครอง', label: "พ่อแม่ หรือผู้ปกครอง" },
    { value: 'งานประจำ', label: "งานประจำ" },
    { value: 'งานพาร์ทไทม์', label: "งานพาร์ทไทม์" },
    { value: 'เงินกู้ยืมจาก กยศ', label: 'เงินกู้ยืมจาก กยศ' }
]

export const optionProductHandFilter = [{ value: 'NEW', label: "สินค้ามือหนึ่ง" }, { value: 'SECOND', label: "สินค้ามือสอง" }, { value: 'SPECIAL', label: 'สินค้าพิเศษ' }]

export const optionsTypeLockInstallment = [
    { value: 'MDM', label: 'MDM' },
    { value: 'MDMICLOUD', label: 'MDM+iCloud' },
];
export const optionsTypeLockRefinance = [
    { value: 'ICLOUD', label: 'iCloud' },
    { value: 'MDMICLOUD', label: 'MDM+iCloud' },
];