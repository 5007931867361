export const dataRefinance = [
    {
      rowNo: 1,
      contractNo: 'A-0000007',
      fullName: 'ปวีณา ชมสวน',
      status: 'WAIT', 
      statusName: 'รอตรวจสอบ', 
      productName:'iPhone 13 Pro Max 128GB',
      totalRefinance:10000,
      accountNo:'1452636982',
      accountName:'ปวีณา ชมสวน',
      bank:'กสิกรไทย',
      branch:'เมกา บางนา',
      saleName:'นาย A',
      bookbankImg:'',
      contractImg:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
    },
    {
        rowNo: 2,
        contractNo: 'A-0000008',
        fullName: 'ปวีณา ชมสวน2',
        status: 'WAIT', 
        statusName: 'รอตรวจสอบ', 
        productName:'iPhone 13 Pro Max 512GB',
        totalRefinance:9000,
        accountNo:'1452636982',
        accountName:'ปวีณา ชมสวน',
        bank:'กสิกรไทย',
        branch:'เมกา บางนา',
        saleName:'นาย A',
        bookbankImg:'',
      contractImg:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
      },
  ];
  