import React from 'react';
import {Row, Col, Radio} from 'antd';
import {masterPriceSelection} from '../../constants/masterData';

const RadioPriceSelection = ({responsive, ...otherProp}) => {
  const dataSc = masterPriceSelection;

  return (
    <Radio.Group {...otherProp} style={{width: '100%'}}>
      <Row span={24}>
        {dataSc?.map((item, key) => {
          return (
            <Col key={key} {...responsive}>
              <Radio value={item?.value}>{item?.label}</Radio>
            </Col>
          );
        })}
      </Row>
    </Radio.Group>
  );
};

export default RadioPriceSelection;
