import styled from 'styled-components';
import colors from '../../../styles/colors';

const TabsStyle = styled.div`
  .ant-tabs-nav {
    margin: 0 0 10px;

    .ant-tabs-nav-wrap {
      .ant-tabs-tab:not(.ant-tabs-tab-disabled) {
        &:hover {
          color: ${colors.primary} !important;
        }
      }

      .ant-tabs-tab {
        overflow: unset;
      }
      .ant-tabs-nav-list .ant-tabs-ink-bar {
        background-color: ${colors.primary} !important;
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${colors.primary}!important;
      }

      .title-tab {
        display: flex;
        align-items: center;
        svg {
          margin-right: 5px;
        }
      }
    }
  }
`;

export default TabsStyle;
