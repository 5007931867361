// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Modal, Card, Spin } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { ContainerButton } from '../../styles/styledComponentGlobal';
import { BlacklistCustomerSearchForm } from '../../constants/global/SearchForm';
import { BlacklistCustomerColumn } from '../../constants/global/columnTableForm';
import { ButtonTheme } from '../../components/buttons';
import { CreateBlacklistCustomer } from './../../components/modal/BlacklistCustomer/CreateBlacklistCustomer';
import {
  GET,
  GET_BLACKLIST_CUSTOMER
} from '../../services';

const BlacklistCustomer = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [defaultValue, setDefaultValue] = useState({});
  const [visibleCreate, setVisibleCreate] = useState(false)

  useEffect(() => {
    getBlacklistCustomer()
  }, []);

  const getBlacklistCustomer = async (data = {}) => {
    try {
      setLoading(true);
      let res = await GET(GET_BLACKLIST_CUSTOMER(data));
      const { success, result } = res;
      if (success) {
        setDataSource(result.blacklist);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'Blacklist ลูกค้า',
              icon: <SettingOutlined />,
              spanSearch: 24,
              formSearch: BlacklistCustomerSearchForm(),
              onSearch: (data) => getBlacklistCustomer(data),
            }}
            tableLayout={{
              columns: BlacklistCustomerColumn(),
              dataSource: dataSource,
              extraContent: (
                <ContainerButton right>
                  <ButtonTheme useFor="CREATE" title={'เพิ่ม blacklist'} onClick={() => setVisibleCreate(true)} />
                </ContainerButton>)
            }}
          />
        </Spin>
      </Card>
      <CreateBlacklistCustomer
        visible={visibleCreate}
        title={'เพิ่ม blacklist ลูกค้า'}
        onClose={() => setVisibleCreate(false)}
        data={{ reApi: getBlacklistCustomer, record: defaultValue }}
      />
    </Wrapper>
  );
};

export default BlacklistCustomer;
