import React, { useState, useEffect } from 'react';
import { CardTheme } from '../card/CardTheme';
import { useForm } from 'react-hook-form';
import RenderForm from '../../constants/global/RenderForm';
import { ButtonTheme } from '../buttons';
import { ContainerButton } from '../../styles/styledComponentGlobal';
import { TextLarge } from '../forms/text';
import { Tabs } from 'antd';
import moment from 'moment/moment';

const { TabPane } = Tabs;

export const SearchLayout = ({ props }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useForm();
  const {
    title,
    formSearch,
    onSearch,
    icon,
    onClearCustom,
    tabs,
    onChangeTab,
    spanSearch,
    extraContent,
    dataSearchDefault,
    disabledSearchButton,
    titleBtn,
    formJustify,
    formName
  } = props;
  const [setTabKey] = useState('1');

  useEffect(() => {
    if (dataSearchDefault) {
      if (formName === 'considerSearchForm') {
        if (!!dataSearchDefault.statusMonitor) {
          setValue('status', dataSearchDefault.statusMonitor || '');
        }

        if (dataSearchDefault.dateType) {
          setValue('dateType', dataSearchDefault.dateType || '');
        } else if (dataSearchDefault.dateType === undefined || dataSearchDefault.dateType === null || dataSearchDefault.dateType === '') {
          setValue('dateType', '');
        }

        if (dataSearchDefault.startDate) {
          setValue('startDate', (dataSearchDefault.startDate && moment(dataSearchDefault.startDate)) || '');
        } else if (dataSearchDefault.startDate === undefined || dataSearchDefault.startDate === null || dataSearchDefault.startDate === '') {
          setValue('startDate', '');
        }

        if (dataSearchDefault.endDate) {
          setValue('endDate', (dataSearchDefault.endDate && moment(dataSearchDefault.endDate)) || '');
        } else if (dataSearchDefault.endDate === undefined || dataSearchDefault.endDate === null || dataSearchDefault.endDate === '') {
          setValue('endDate', '');
        }
      }

      if (!!dataSearchDefault.statusTrackPayment) {
        setValue('status', dataSearchDefault.statusTrackPayment || '');
      }

      if (!!dataSearchDefault.actionBy) {
        setValue('actionBy', dataSearchDefault.actionBy);
        setValue('startDate', (dataSearchDefault.startDate && moment(dataSearchDefault.startDate)) || '');
        setValue('endDate', (dataSearchDefault.endDate && moment(dataSearchDefault.endDate)) || '');
      }

      if (!!dataSearchDefault.docNoManageDEP) {
        setValue('docNo', dataSearchDefault.docNoManageDEP || '');
      }

      if (!!dataSearchDefault.itemNameStockSerial) {
        setValue('branch', dataSearchDefault.branch);
        setValue('itemName', dataSearchDefault.itemNameStockSerial);
        setValue('type', dataSearchDefault.type);
        setValue('serial_status', dataSearchDefault.serial_status);
      }

      if (dataSearchDefault.branchStockSerial === null) {
        setValue('branch', null);
      }

      if (!!dataSearchDefault.IDtrackersAgent) {
        setValue('IDtrackers', dataSearchDefault.IDtrackersAgent);
      }

      if (!!dataSearchDefault.startDateTrackingAgent) {
        setValue('startDate', moment(new Date(dataSearchDefault.startDate), 'YYYY-MM-DD'));
      }

      if (!!dataSearchDefault.endDateTrackingAgent) {
        setValue('endDate', moment(new Date(dataSearchDefault.endDate), 'YYYY-MM-DD'));
      }

      if (!!dataSearchDefault.userIdCard) {
        setValue('user_id_card', dataSearchDefault.userIdCard);
        setValue('name', dataSearchDefault.userName);
        setValue('tel', dataSearchDefault.userTel);
      }
      if (!!dataSearchDefault.typeCustomer) {
        setValue('type', dataSearchDefault.type);
      }

      if (!!dataSearchDefault.itemTypeReportMonitor) {
        setValue('type', dataSearchDefault.type);
        setValue('serial_status', dataSearchDefault.serial_status);
      }

      if (dataSearchDefault.IDtrackers) {
        setValue('IDtrackers', dataSearchDefault.IDtrackers);
        setValue('end_date', moment(dataSearchDefault.end_date, 'DD/MM/YYYY'));
        setValue('start_date', moment(dataSearchDefault.start_date, 'DD/MM/YYYY'));
      }

      if (dataSearchDefault.IsDownPayment) {
        if (dataSearchDefault.end_date) {
          setValue('end_date', moment(dataSearchDefault.end_date, 'YYYY-MM-DD'));
        }
        if (dataSearchDefault.start_date) {
          setValue('start_date', moment(dataSearchDefault.start_date, 'YYYY-MM-DD'));
        }
      }

      if (dataSearchDefault.typeStockBranch) {
        setValue('type', dataSearchDefault.type)
      }

      if (dataSearchDefault.terminate_contract_status_params) {
        setValue('terminate_contract_status', dataSearchDefault.terminate_contract_status_params)
      }

      if (formName === 'secondhand_report') {
        setValue('type', dataSearchDefault?.type ?? '');
        setValue('doc_no', dataSearchDefault?.doc_no ?? '');
      }
      
      
    }
  });
  const onSubmit = (data) => {
    onSearch(data);
  };

  const onClear = (formSearchTab) => {
    let form = formSearchTab ? formSearchTab : formSearch;
    form.forEach((val) => setValue(val.name, null));
  };
  return (
    <>
      <CardTheme
        title={
          title && (
            <div style={{ display: 'flex', alignItems: 'center', color: '#0052BA' }}>
              {icon}
              &nbsp;
              <TextLarge text={title} color={'#0052BA'} />
            </div>
          )
        }
        content={
          <>
            {tabs ? (
              <Tabs
                defaultActiveKey="1"
                onChange={(value) => {
                  onChangeTab(value, setValue);
                  setTabKey(value);
                }}>
                {tabs.map((val, i) => {
                  return (
                    <TabPane tab={<span>{val.label}</span>} key={i + 1}>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <RenderForm
                          control={control}
                          setValue={setValue}
                          getValues={getValues}
                          errors={errors}
                          formList={val.formSearch}
                          spanSearch={spanSearch}
                          renderButton={
                            <ContainerButton right>
                              <ButtonTheme useFor="SEARCH" htmlType="submit" />
                              <ButtonTheme
                                useFor="CLEAR"
                                onClick={onClearCustom ? () => onClearCustom(setValue, val.formSearch) : () => onClear(val.formSearch)}
                              />
                            </ContainerButton>
                          }
                        />
                      </form>
                    </TabPane>
                  );
                })}
              </Tabs>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <RenderForm
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors}
                  formList={formSearch}
                  spanSearch={spanSearch}
                  justify={formJustify}
                  renderButton={
                    <ContainerButton right>
                      {extraContent}

                      {!disabledSearchButton && (
                        <>
                          <ButtonTheme useFor="SEARCH" htmlType="submit" title={titleBtn} />
                          <ButtonTheme useFor="CLEAR" onClick={onClearCustom ? () => onClearCustom(setValue, formSearch) : () => onClear()} />
                        </>
                      )}
                    </ContainerButton>
                  }
                />
              </form>
            )}
          </>
        }
      />
    </>
  );
};
