import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
  DownloadOutlined,
  LoginOutlined,
  ClearOutlined,
  LockOutlined,
  TeamOutlined,
  PlusCircleOutlined,
  RollbackOutlined,
  SaveOutlined,
  FileSearchOutlined,
  ReloadOutlined,
  InfoCircleOutlined,
  UserAddOutlined,
  ShakeOutlined,
  FileAddOutlined,
  PrinterOutlined,
  CheckCircleOutlined,
  CopyrightOutlined,
  QrcodeOutlined,
  ExclamationCircleOutlined,
  InteractionOutlined,
  BookOutlined,
  UserOutlined,
  TagOutlined,
  GiftOutlined,
  CodeSandboxOutlined,
  SettingOutlined,
  HomeOutlined,
  DeleteTwoTone,
  CheckCircleTwoTone,
  CarryOutOutlined,
  PartitionOutlined,
  CloudUploadOutlined,
  SendOutlined,
  InboxOutlined,
  SyncOutlined,
  RightOutlined,
  RetweetOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  FileDoneOutlined,
  MobileOutlined,
  BellOutlined,
  CloseOutlined,
  CheckOutlined,
  CopyOutlined
} from '@ant-design/icons';

export const Icon = {
  mobile: () => <MobileOutlined />,
  addtask: () => <FileDoneOutlined />,
  tranfer: () => <RetweetOutlined />,
  next: () => <RightOutlined />,
  box: () => <InboxOutlined />,
  send: () => <SendOutlined />,
  sale: () => <PartitionOutlined />,
  complete: () => <CarryOutOutlined />,
  customerList: () => <TeamOutlined />,
  report: () => <FileSearchOutlined />,
  manageCredit: () => <CopyrightOutlined />,
  createQRCode: () => <QrcodeOutlined />,
  random: () => <SyncOutlined />,
  /**most for button */
  search: () => <SearchOutlined />,
  edit: () => <EditOutlined />,
  remove: () => <DeleteOutlined />,
  delete: () => <DeleteTwoTone twoToneColor="#eb2f96" />,
  clear: () => <ClearOutlined />,
  upload: () => <UploadOutlined />,
  download: () => <DownloadOutlined />,
  login: () => <LoginOutlined />,
  forgetPassword: () => <LockOutlined />,
  create: () => <PlusCircleOutlined />,
  back: () => <RollbackOutlined />,
  save: () => <SaveOutlined />,
  confirm: () => <CheckCircleOutlined />,
  reAct: () => <ReloadOutlined />,
  recheck: () => <InfoCircleOutlined />,
  register: () => <UserAddOutlined />,
  manageAppReport: () => <ShakeOutlined />,
  manageUserReport: () => <TeamOutlined />,
  manageReport: () => <FileAddOutlined />,
  print: () => <PrinterOutlined />,
  warningAlert: () => <ExclamationCircleOutlined />,
  systemFlow: () => <InteractionOutlined />,
  banner: () => <BookOutlined />,
  user: () => <UserOutlined />,
  coupon: () => <TagOutlined />,
  gift: () => <GiftOutlined />,
  product: () => <CodeSandboxOutlined />,
  setting: () => <SettingOutlined />,
  check: () => <CheckCircleTwoTone twoToneColor="#87D068" />,
  close: () => <CloseCircleOutlined />,
  closeOutline: () => <CloseOutlined />,
  clock: () => <ClockCircleOutlined />,
  checkOutline: () => <CheckOutlined />,
  bell: () => <BellOutlined />,
  Copy: () => <CopyOutlined />
};

export const IconMenu = {
  sale: <PartitionOutlined />,
  complete: <CarryOutOutlined />,
  customerList: <TeamOutlined />,
  report: <FileSearchOutlined />,
  manageCredit: <CopyrightOutlined />,
  upload: <CloudUploadOutlined />,
  banner: <BookOutlined />,
  home: <HomeOutlined />,
  user: <UserOutlined />,
  coupon: <TagOutlined />,
  gift: <GiftOutlined />,
  product: <CodeSandboxOutlined />,
};
