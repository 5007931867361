import {useEffect, useState, useMemo} from 'react';
import {Form, Input, Row, Col, Modal, DatePicker, InputNumber} from 'antd';
import _ from 'lodash';
import dayjs from 'dayjs';
import {CONTRACT_TYPE} from '../constants';
import {checkSameNameWithPrefix, convertNumberToComma, convertStringToNumber} from '../../../../functions/fn';
import {getUrlObject} from '../../fn';
import {handleTrimValue} from '../../../../utils/antform';
import {disabledBeforeCurrentDate} from '../../../../utils/date';
import {Icon} from '../../../../resources/icon';
import {ButtonTheme, Permission, Spinners, Upload} from '../../../../components';
import {RadioPriceSelection, SelectInsuranceStatus} from '../../../../components/masterdata';
import {SelectBank, SelectBranch, SelectProductAll, SelectProductCondition} from '../../../../components/inputfromapi';
import {GCS_BUCKET_CONTRACT_IMAGE, GCS_PATH_CONTRACT_IMAGE} from '../../../../env';
import {GET_DATA_OCR, POST} from '../../../../services';
import {useGetProductData} from '../hooks';

const formName = 'FormSecondHand';
const {SECONDHAND} = CONTRACT_TYPE;
const ININT_CAL = {
  contractType: SECONDHAND, //ประเภทสัญญา
  condition: '', //เงื่อนไข
  price: 0, //ราคา
  productCode: '', //รหัสสินค้า
};

export const FormSecondHand = ({defaultData, data, fn}) => {
  const {admin_branch, record, contractType} = data;
  const {checkCustomer} = fn;

  /* Form */
  const [form] = Form.useForm();
  /* Fullname */
  const [fullname, setFullName] = useState(null);
  const [enabledChangeName, setEnabledChangeName] = useState(false);
  /* Product */
  const [productData, setProductData] = useState({});
  /* Maximum */
  const [maxPrice, setMaxPrice] = useState(0);
  /* Insurance */
  const [insuranceStatus, setInsuranceStatus] = useState(null);
  /* Insurance */
  const [bankKeyData, setBankKeyData] = useState({});
  /* Calculate */
  const [calculateData, setCalculateData] = useState({...ININT_CAL});
  /* File */
  const [fileImageList, setFileImageList] = useState([]);
  const [bookBankImageList, setBookBankImageList] = useState([]);
  const [mobileImageList, setMobileImageList] = useState([]);
  const [fileOtherImageList, setFileOtherImageList] = useState([]);
  const [changeNameImageList, setChangeNameImageList] = useState([]);
  /* Disabled */
  const [disableBankCode, setDisableBankCode] = useState(false);
  const [disableBankName, setDisableBankName] = useState(false);
  const [disableBankNumber, setDisableBankNumber] = useState(false);
  /* SelectPrice */
  const [selectPrice, setSelectPrice] = useState('SYSTEM');
  /* Loading API */
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [uploadBookBankLoading, setUploadBookBankLoading] = useState(false);
  const [uploadMobileLoading, setUploadMobileLoading] = useState(false);
  const [uploadOtherImageLoading, setUploadOtherImageLoading] = useState(false);
  const [uploadChangeNameLoading, setUploadChangeNameLoading] = useState(false);
  const [getOCRLoading, setGetOCRLoading] = useState(false);
  /* Hook */
  const [getProductDataFn, , productDataLoading] = useGetProductData();

  /* --------- useMemo calculateData ---------*/
  const calDtMemo = useMemo(() => {
    return calculateData;
  }, [calculateData]);

  /* --------- useEffect set default data ---------*/
  useEffect(() => {
    if (!_.isEmpty(defaultData) && contractType === defaultData?.type) {
      setDefaultData();
    }
  }, [defaultData]);

  /* --------- useEffect SetMaxPrice ---------*/
  useEffect(() => {
    const maxPrice = selectPrice === 'SYSTEM' ? productData?.productTurn?.price : defaultData?.summary_register?.maximum || 0;
    form.setFieldValue('maximum', maxPrice);
    setMaxPrice(maxPrice);
  }, [calDtMemo?.productCode, calDtMemo?.condition, productData, selectPrice]);

  /* --------- useEffect Get Product ---------*/

  useEffect(() => {
    const fetchProductData = async () => {
      if (contractType && !_.isEmpty(calDtMemo?.productCode) && !_.isEmpty(calDtMemo?.condition)) {
        const result = await getProductDataFn({
          type: contractType,
          productCode: calDtMemo?.productCode,
          condition: calDtMemo?.condition,
        });

        setProductData(result);
      }
    };

    fetchProductData();
  }, [contractType, calDtMemo?.productCode, calDtMemo?.condition]);

  /* --------- useEffect setFullName ---------*/
  useEffect(() => {
    if (record?.fullname) {
      setFullName(record?.fullname);
    }
  }, [record?.fullname]);

  /* --------- useEffect getDataOCR ---------*/
  useEffect(() => {
    if (bookBankImageList && bookBankImageList[0]?.url) {
      setDisableBankCode(true);
      setDisableBankName(true);
      setDisableBankNumber(true);
      getDataOCR(bookBankImageList[0]?.url);
    }
  }, [bookBankImageList]);

  /* --------- useEffect validateFields Price ---------*/
  useEffect(() => {
    if (form.getFieldValue('price')) {
      form.validateFields(['price']);
    }
  }, [selectPrice]);

  /* --------- API Fn ---------*/
  const getDataOCR = async (url) => {
    try {
      setGetOCRLoading(true);
      const res = await POST(GET_DATA_OCR, {link: url});
      const {message, data} = res;
      const setFields = [];

      if (message === 'get ocr success') {
        if (JSON.stringify(data?.ocr) !== '{}') {
          /* ธนาคาร */
          if (!data?.ocr?.bankCode) {
            setFields.push({
              name: 'bankCode',
              errors: ['ไม่สามารถอ่าน OCR รูปหน้า Book Bank ได้'],
            });
          } else {
            setFields.push({
              name: 'bankCode',
              value: data?.ocr?.bankCode,
              errors: [],
            });
            setFields.push({
              name: 'bankName',
              value: bankKeyData[data?.ocr?.bankCode]?.label,
              errors: [],
            });
          }

          /* ชื่อบัญชี */
          if (!data?.ocr?.accountName) {
            setFields.push({
              name: 'bookBankName',
              errors: ['ไม่สามารถอ่าน OCR รูปหน้า Book Bank ได้'],
            });
          } else {
            if (!fullname) {
              setFields.push({
                name: 'bookBankName',
                errors: ['ไม่พบข้อมูลชื่อ-นามสกุล'],
              });
            } else {
              const checkName = checkSameNameWithPrefix(fullname, data?.ocr.accountName);
              if (checkName === false) {
                setEnabledChangeName(true);
              } else if (checkName === true) {
                setEnabledChangeName(false);
              }
              setFields.push({
                name: 'bookBankName',
                value: data?.ocr?.accountName,
                errors: [],
              });
            }
          }

          /* เลขบัญชี */
          if (!data?.ocr?.accountNo) {
            setFields.push({
              name: 'bookBankNo',
              errors: ['ไม่สามารถอ่าน OCR รูปหน้า Book Bank ได้'],
            });
          } else {
            setFields.push({
              name: 'bookBankNo',
              value: data?.ocr?.accountNo,
              errors: [],
            });
          }
        }

        form.setFields(setFields);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message || '',
        content: error || '',
      });
    } finally {
      setGetOCRLoading(false);
      setDisableBankCode(false);
      setDisableBankName(false);
      setDisableBankNumber(false);
    }
  };

  /* --------- Fn ---------*/
  const setDefaultData = async () => {
    const {condition, serialNumber, product, summary, summary_register} = defaultData;
    const price = summary?.maximum || 0;
    const maxPrice = selectPrice === 'SYSTEM' ? summary?.maximum : summary_register?.maximum || 0;

    form.setFieldsValue({
      branch: admin_branch,
      condition: condition,
      product_code: product?.code,
      serial_number: serialNumber,
      maximum: maxPrice,
      price: price,
    });

    setCalculateData((prev) => ({
      ...prev,
      condition: condition,
      productCode: product?.code,
      price: price,
    }));
  };

  const handleProductChange = (value) => {
    form.setFields([{name: 'product_code', value: value, errors: []}]);
    setCalculateData((prev) => ({...prev, productCode: value}));
  };

  const handleConditionChange = (value) => {
    form.setFields([{name: 'condition', value: value, errors: []}]);
    setCalculateData((prev) => ({...prev, condition: value}));
  };

  const handlePriceChange = (value) => {
    form.setFields([{name: 'price', value: value, errors: []}]);
    setCalculateData((prev) => ({...prev, price: value}));
  };

  const handleChangeInsuranceStatus = (e) => {
    form.setFieldsValue({insurance_status: e});
    if (e === 'NO') {
      form.setFieldsValue({insurance_date: null});
    }
    setInsuranceStatus(e);
  };

  const handleChangeBookBankName = (e) => {
    let checkName = checkSameNameWithPrefix(fullname, e);
    if (checkName === false) {
      setEnabledChangeName(true);
    } else if (checkName === true) {
      setEnabledChangeName(false);
    }
  };

  const handleSelectPriceChange = (e) => {
    setSelectPrice(e?.target?.value);
  };

  const handleClearForm = () => {
    form.resetFields();
    setProductData({});
    setMaxPrice(0);
    setInsuranceStatus(null);
    setCalculateData({...ININT_CAL});
    setBookBankImageList([]);
    setMobileImageList([]);
    setFileOtherImageList([]);
    setChangeNameImageList([]);
  };

  const onFinish = async (values) => {
    try {
      const {
        product_code,
        serial_number,
        branch,
        condition,
        price,
        imei,
        insurance_status,
        insurance_date,
        imageBookBank,
        bankCode,
        bankName,
        branchBankName,
        bookBankName,
        bookBankNo,
        mobileImage,
        otherImage,
        imageNameChangeCertificate,
        remark,
        image,
      } = values;

      const productCode = product_code;
      const productName = productData?.productName || '';

      const mobileImageList = _.map(mobileImage, (el) => {
        return getUrlObject('mobileImage', el?.url);
      });

      const otherImageList = _.map(otherImage, (el) => {
        return getUrlObject('otherImage', el?.url);
      });

      const payload = {
        user_id: record?._id,
        type: contractType,
        branch: branch,
        price: price,
        productCode: [{code: productCode, name: productName, serialNumber: serial_number}],
        serial_number: [
          {
            product_code: productCode,
            product_name: productName,
            serial_number: serial_number,
            price: price,
            insurance: {
              date: insurance_status === 'YES' ? dayjs(insurance_date).format('YYYY-MM-DD') : null,
              status: insurance_status,
            },
            imei: imei,
          },
        ],
        serial_number2: serial_number,
        condition: condition,
        contract: {
          type: contractType,
          package: '',
          price_package: 0,
          promotion_code: 'none',
          imageBookBank,
          bankCode,
          bankName,
          branchBankName,
          bookBankName,
          bookBankNo,
          mobileImage: mobileImageList,
          otherImage: otherImageList,
          imageNameChangeCertificate,
          image: {url: image},
        },
        remark: remark,
      };

      checkCustomer(payload);
    } catch (err) {}
  };

  return (
    <>
      {productDataLoading && <Spinners />}
      <Form
        form={form}
        name={formName}
        layout={'horizontal'}
        labelAlign="right"
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
        style={{padding: '0px 20px 20px 20px'}}
        onFinish={onFinish}
        autoComplete="off"
        scrollToFirstError>
        <Form.Item
          name={'branch'}
          label="สาขา"
          labelCol={{span: 4}}
          rules={[
            {required: true, message: 'จำเป็นต้องกรอก'},
            {
              validator(_, value) {
                if (value === 'undefinde') {
                  return Promise.reject(new Error('ข้อมูลไม่ถูกต้อง'));
                }
                return Promise.resolve();
              },
            },
          ]}
          initialValue={admin_branch}>
          <SelectBranch
            placeholder={'เลือกสาขา'}
            handleChange={(e) => {
              form.setFields([{name: 'branch', value: e?.value, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item name={'product_code'} label="ชื่อสินค้า" labelCol={{span: 4}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <SelectProductAll placeholder={'เลือกสินค้า'} handleChange={(e) => handleProductChange(e?.value)} filter={{type: contractType}} />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'serial_number'} label="S/N" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <Input placeholder={'กรุณากรอก S/N'} onChange={(e) => handleTrimValue(form, 'serial_number', e?.target?.value)} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'imei'} label="IMEI" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <Input placeholder={'กรุณากรอกหมายเลขเครื่อง IMEI'} onChange={(e) => handleTrimValue(form, 'imei', e?.target?.value)} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name={'condition'} label="สภาพเครื่อง" labelCol={{span: 4}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <SelectProductCondition
            placeholder={'เลือก สภาพเครื่อง'}
            handleChange={(e) => handleConditionChange(e?.value)}
            filter={{productCode: calDtMemo?.productCode}}
          />
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'insurance_status'} label="ประกัน" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <SelectInsuranceStatus placeholder={'กรุณาเลือกสถานะประกันของเครื่อง'} onChange={(e) => handleChangeInsuranceStatus(e)} />
            </Form.Item>
          </Col>
          {insuranceStatus === 'YES' && (
            <Col span={12}>
              <Form.Item name={'insurance_date'} label="วันที่หมดประกัน" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
                <DatePicker style={{width: '100%'}} placeholder={'กรุณากรอกวันที่หมดประกัน'} disabledDate={disabledBeforeCurrentDate} />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Permission role={{action: 'selectprice', menuCode: 'M1005'}}>
          <Form.Item
            name={'selectPrice'}
            label="วงเงินสูงสุด"
            labelCol={{span: 4}}
            rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}
            initialValue={'SYSTEM'}>
            <RadioPriceSelection responsive={{xs: 12, md: 12, lg: 6, xl: 4}} onChange={(e) => handleSelectPriceChange(e)} />
          </Form.Item>
        </Permission>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name={'maximum'}
              label="วงเงินสูงสุดที่จะได้รับ"
              labelCol={{span: 8}}
              rules={[{required: true, message: 'ค่าวงเงินสูงสุดที่จะได้รับเป็นค่าว่าง'}]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={'price'}
              label="วงเงินที่อนุมัติ"
              labelCol={{span: 8}}
              rules={[
                {required: true, message: 'จำเป็นต้องกรอก'},
                {
                  validator(_, value) {
                    if (value <= 0) {
                      return Promise.reject(new Error('ราคาที่กรอกต้องมากกว่า 0'));
                    }
                    if (value > maxPrice) {
                      return Promise.reject(new Error('ราคาที่กรอกต้องไม่เกินวงเงินสูงสุดที่จะได้รับ'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}>
              <InputNumber
                style={{width: '100%'}}
                placeholder={'กรุณากรอกวงเงินที่อนุมัติ'}
                formatter={(value, info) => {
                  if (!info.userTyping) {
                    return convertNumberToComma(value);
                  } else {
                    return value;
                  }
                }}
                onBlur={(e) => handlePriceChange(convertStringToNumber(e?.target?.value))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name={'imageBookBank'} label={'อัพโหลดรูปหน้า Book Bank'} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={bookBankImageList}
            setLoading={setUploadBookBankLoading}
            handleChange={(files) => {
              form.setFields([{name: 'imageBookBank', value: files[0]?.url, errors: []}]);
              setBookBankImageList(files);
            }}
            handleRemove={() => {
              form.setFields([{name: 'imageBookBank', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Row gutter={[16, 16]} justify={'left'}>
          <Col span={12}>
            <Form.Item name={'bankCode'} label="ธนาคาร" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <SelectBank
                placeholder={'กรุณาเลือกธนาคาร'}
                handleChange={(e) => {
                  form.setFields([
                    {name: 'bankCode', value: e?.value, errors: []},
                    {name: 'bankName', value: e?.label, errors: []},
                  ]);
                }}
                dataResponse={(e) => setBankKeyData(_.keyBy(e, 'value'))}
                disabled={disableBankCode}
              />
            </Form.Item>
            <Form.Item name={'bankName'} hidden>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'branchBankName'} label="สาขาธนาคาร" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <Input placeholder={'กรุณากรอกชื่อสาขาธนาคาร'} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify={'center'}>
          <Col span={12}>
            <Form.Item name={'bookBankName'} label="ชื่อบัญชี" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <Input placeholder={'กรุณากรอกชื่อบัญชี'} onChange={(e) => handleChangeBookBankName(e?.target?.value)} disabled={disableBankName} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'bookBankNo'} label="เลขที่บัญชี" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <Input placeholder={'กรุณากรอกเลขที่บัญชี'} disabled={disableBankNumber} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name={'image'} label="รูปผู้ทำสัญญา" labelCol={{span: 4}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileImageList}
            handleChange={(files) => {
              form.setFields([{name: 'image', value: files[0]?.url, errors: []}]);
              setFileImageList([{fileName: files[0]?.fileName, url: files[0]?.url}]);
            }}
            setLoading={setLoadingUpload}
            handleRemove={() => {
              form.setFields([{name: 'image', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item name={'mobileImage'} label="อัพโหลดรูปเครื่อง" rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <Upload
            accept=".png,.jpg,.jpeg"
            multiple
            maxCount={10}
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={mobileImageList}
            setLoading={setUploadMobileLoading}
            handleChange={(files) => {
              form.setFields([{name: 'mobileImage', value: files, errors: []}]);
              setMobileImageList(files);
            }}
            handleRemove={() => {
              form.setFields([{name: 'mobileImage', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item name={'otherImage'} label="อัพโหลดรูปเอกสาร" rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <Upload
            accept=".png,.jpg,.jpeg"
            multiple
            maxCount={10}
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileOtherImageList}
            setLoading={setUploadOtherImageLoading}
            handleChange={(files) => {
              form.setFields([{name: 'otherImage', value: files, errors: []}]);
              setFileOtherImageList(files);
            }}
            handleRemove={() => {
              form.setFields([{name: 'otherImage', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item
          name={'imageNameChangeCertificate'}
          label="อัพโหลดรูปใบเปลี่ยนชื่อ"
          rules={[{required: enabledChangeName, message: 'จำเป็นต้องกรอก'}]}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={changeNameImageList}
            setLoading={setUploadChangeNameLoading}
            handleChange={(files) => {
              form.setFields([{name: 'imageNameChangeCertificate', value: files[0]?.url, errors: []}]);
              setChangeNameImageList(files);
            }}
            handleRemove={() => {
              form.setFields([{name: 'imageNameChangeCertificate', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item name={'remark'} label="หมายเหตุ" labelCol={{span: 4}}>
          <Input.TextArea rows={4} maxLength={300} showCount />
        </Form.Item>

        <Row justify={'center'}>
          <ButtonTheme
            useFor="CLEAR"
            style={{width: 150}}
            onClick={() =>
              Modal.confirm({
                title: 'ยืนยัน',
                icon: <Icon.warningAlert />,
                content: 'ต้องการล้างข้อมูลทั้งหมดใช่หรือไม่?',
                onOk() {
                  handleClearForm();
                },
                okText: 'ยืนยัน',
                cancelText: 'ยกเลิก',
              })
            }
          />
          <ButtonTheme
            useFor="CUSTOM"
            htmlType="submit"
            title={'ยืนยัน'}
            style={{width: 150}}
            loading={uploadBookBankLoading || uploadMobileLoading || uploadOtherImageLoading || uploadChangeNameLoading || getOCRLoading}
          />
        </Row>
      </Form>
    </>
  );
};
