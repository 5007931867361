/* eslint-disable default-case */
import React, {useEffect, useRef, useState} from 'react';
import {PageLayout} from '../../../components/pageLayout/PageLayout';
import {useForm} from 'react-hook-form';
import Wrapper from '../../../views/wrapper/Wrapper';
import {Modal, Card, Space, Button, Table, Spin, Row} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {
  EDIT_RECORD_SECOND_HAND,
  GET,
  GET_BANK_OPTION,
  GET_DATA_OCR,
  GET_DETAIL_EDIT_SECOND_HAND,
  GET_OPTION_BRANCH,
  GET_SECOND_HAND_CONDITION_LIST,
  GET_SECOND_HAND_PRICE,
  GET_SECOND_HAND_PRODUCT_OPTION,
  POST,
} from '../../../services';
import axios from 'axios';
import {checkIdCardPattern, checkSameNameWithPrefix, cutStringImgSigned} from '../../../functions/fn';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import moment from 'moment';
import {Icon} from '../../../resources/icon';
import RenderForm from '../../../constants/global/RenderForm';
import {TextLarge} from '../../../components/forms/text';
import {EditRecordSecondHandForm, EditRecordSecondHandNewForm} from '../form';
import {allowUserEmail, optionCondition, allowUserEmailList} from '../constants';
import {auth} from '../../../utils/firebase';
import {useAuthState} from '../../../utils/hook';

const EditRecordSecondHand = () => {
  const {
    handleSubmit,
    formState: {errors},
    setValue,
    getValues,
    control,
    setError,
    clearErrors,
  } = useForm();

  const {user} = useAuthState(auth);

  const [loading, setLoading] = useState(false);
  const [optionBranch, setOptionBranch] = useState([]);
  const [optionProduct, setOptionProduct] = useState([]);
  const [bankOptions, setBankOptions] = useState([]);

  const [branchName, setBranchName] = useState(null);
  const [productName, setProductName] = useState(null);
  const [serialNumber, setSerialNumber] = useState(null);
  const [imeiNumber, setImeiNumber] = useState(null);
  const [conditionProduct, setConditionProduct] = useState(null);
  const [idCard, setIdCard] = useState(null);
  const [name, setName] = useState(null);
  const [tel, setTel] = useState(null);
  const [highestApproveAmount, setHighestApproveAmount] = useState(null);
  const [approvePaidAmount, setApprovePaidAmount] = useState(null);
  const [insuranceStatus, setInsuranceStatus] = useState(null);
  const [insuranceDate, setInsuranceDate] = useState(null);
  const insuranceDateRef = useRef(null);
  const [idCardImageList, setIdCardImageList] = useState([]);
  // const idCardImageList = useRef([]);
  const [changeNameImageList, setChangeNameImageList] = useState([]);
  const [mobileImageList, setMobileImageList] = useState([]);
  const [documentImageList, setDocumentImageList] = useState([]);
  const [signatureImage, setSignatureImage] = useState(null);
  const [bookBankImage, setBookBankImage] = useState([]);
  const [bookBankBranch, setBookBankBranch] = useState('');
  const [bookBankCode, setBookBankCode] = useState(null);
  const [bookBankName, setBookBankName] = useState('');
  const [bookBankNumber, setBookBankNumber] = useState('');
  const [remark, setRemark] = useState('');
  const [ReadOcr, setReadOcr] = useState(false);
  const [enabledChangeName, setEnabledChangeName] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [documentNo, setDocumentNo] = useState(null);
  const [validateIdCardValue, setValidateIdCardValue] = useState(false);
  const [isVivid, setIsVivid] = useState(false);
  const [status, setStatus] = useState(null);

  useEffect(async () => {
    const params = new URLSearchParams(window.location.search);
    const docNo = params.get('document_no');
    if (docNo) {
      await getDetailEditSecondHand(docNo);
      setDocumentNo(docNo);
    } else {
      Modal.error({
        // title: 'กรุณาเลือกแก้ไขรายการรับซื้อสินค้ามือสอง',
        content: 'กรุณาเลือกแก้ไขรายการรับซื้อสินค้ามือสองที่ต้องการจะแก้ไข จากหน้ารายงานรับซื้อสินค้ามือสอง',
      });
    }
    await getOptionBranch();
    await getOptionSecndHandProduct();
    await onFetchBankOption();
  }, []);

  useEffect(() => {
    //ReadOcr เข้าหน้าครั้งเเรกไม่ให้มันทำ ocr
    if (bookBankImage && bookBankImage[0]?.url ) {
      if (ReadOcr) {
        setDisabled(true);
        const spliceUrl = bookBankImage[0]?.url.split('?');
        getDataOCR(spliceUrl[0]);
      }
      else{
        setReadOcr(true);
      }
    }
  }, [bookBankImage]);

  useEffect(() => {}, [idCardImageList]);

  const getDetailEditSecondHand = async (docNo) => {
    setLoading(true);
    try {
      const res = await POST(GET_DETAIL_EDIT_SECOND_HAND(docNo));
      const {success, result} = res;
      if (success) {
        const dataValue = Object.keys(result);
        dataValue.forEach((val) => setValue(val, result[val]));
        setValue('book_bank_code', result?.bank_code);
        if (result?.insurance_status === 'YES') {
          setValue('insurance_date', moment(new Date(result?.insurance_date), 'YYYY-MM-DD'));
          setInsuranceDate(moment(new Date(result?.insurance_date), 'YYYY-MM-DD'));
          insuranceDateRef.current = moment(new Date(result?.insurance_date), 'YYYY-MM-DD');
        }

        const validateIdCard = checkIdCardPattern(result?.id_card);
        setValidateIdCardValue(validateIdCard);

        setBranchName(result?.branch);
        setProductName(result?.product);
        setSerialNumber(result?.serial_number);
        setImeiNumber(result?.imei);
        setConditionProduct(result?.condition);
        setIdCard(result?.id_card);
        setName(result?.name);
        setTel(result?.tel);
        setApprovePaidAmount(result?.approve_paid_amount.toString());
        setInsuranceStatus(result?.insurance_status);
        setStatus(result?.status);

        if (result?.condition && result?.product && result?.type !== 'SECONDHAND') {
          await fetchSecondHandPriceByCondition(result?.condition, result?.product.trim());
        }

        if(result?.type  && result?.type === 'SECONDHAND') {
          setHighestApproveAmount(result?.approve_paid_amount)
          setIsVivid(true);
        }

        setBookBankCode(result?.bank_code);
        setBookBankBranch(result?.book_bank_branch);
        setBookBankName(result?.book_bank_name);
        setBookBankNumber(result?.book_bank_number);
        setRemark(result?.remark);

        let checkName = checkSameNameWithPrefix(result?.name, result?.book_bank_name);
        if (checkName === false) {
          setEnabledChangeName(true);
        } else if (checkName === true) {
          setEnabledChangeName(false);
        }

        if (result?.imageFrontIdCard2) {
          const image = [
            {
              name: result?.imageFrontIdCard2?.fileName,
              mimeType: `image/${result?.imageFrontIdCard2?.mimetype}`,
              url: result?.imageFrontIdCard2.url,
              status: 'done',
              uid: 1,
              fileName: result?.fileName
            },
          ];
          setValue('id_card_image', image);
          setIdCardImageList(image);
        }

        if (result?.bookBankImage2) {
          const image = [
            {
              name: result?.bookBankImage2?.fileName,
              mimeType: `image/${result?.bookBankImage2?.mimetype}`,
              url: result?.bookBankImage2.url,
              status: 'done',
              uid: 1,
              fileName: result?.bookBankImage2?.fileName

            },
          ];
          setBookBankImage(image);
        }

        if (result?.change_name_image2) {
          if (result?.change_name_image2?.url !== '') {
            const image = [
              {
                name: result?.change_name_image2?.fileName,
                mimeType: `image/${result?.change_name_image2?.mimetype}`,
                url: result?.change_name_image2?.url,
                status: 'done',
                uid: 1,
                fileName: result?.change_name_image2?.fileName
              },
            ];
            setChangeNameImageList(image);
          }
        }

        if (result?.imageSignature) {
          setSignatureImage(result?.imageSignature);
        }

        if (result?.imageMobileArr) {
          const image = result?.imageMobileArr.map((el, i) => {
            return {
              name: el?.fileName,
              mimeType: `image/${el?.mimetype}`,
              url: el?.url,
              status: 'done',
              uid: i + 1,
              fileName: el?.fileName
            };
          });

          setMobileImageList(image);
        }

        if (result?.imageDocumentArr) {
          const image = result?.imageDocumentArr.map((el, i) => {
            return {
              name: el?.fileName,
              mimeType: `image/${el?.mimetype}`,
              url: el?.url,
              status: 'done',
              uid: i + 1,
              fileName: el?.fileName
            };
          });

          setDocumentImageList(image);
        }
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const getOptionBranch = async () => {
    try {
      const res = await GET(GET_OPTION_BRANCH);
      const {success, result} = res;
      if (success) {
        const option = result.filter((el) => {
          return el.value !== 'ON_HOLD';
        });

        setOptionBranch(option);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const getOptionSecndHandProduct = async () => {
    try {
      const res = await GET(GET_SECOND_HAND_PRODUCT_OPTION);
      const {success, result} = res;
      if (success) {
        setOptionProduct(result);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onFetchBankOption = async () => {
    try {
      const res = await GET(GET_BANK_OPTION);
      const {message, data} = res;
      if (message === 'get bank options success') {
        const result = data?.bank?.bank.map((el) => {
          return {
            label: el.bankName,
            value: el.bankCode,
          };
        });

        setBankOptions(result);
      }
    } catch (err) {
      const {error} = err;
      Modal.error({
        title: error,
      });
    }
  };

  const getBase64 = (file) => {
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleChange = async (e, ref) => {
    switch (ref) {
      case 'branch':
        setValue('branch', e);
        setBranchName(e);
        break;
      case 'product':
        setValue('product', e);
        setProductName(e);
        if (conditionProduct !== null) {
          await fetchSecondHandPriceByCondition(conditionProduct, e);
        }
        break;
      case 'serial_number':
        setValue('serial_number', e);
        setSerialNumber(e);
        break;
      case 'imei':
        setValue('imei', e);
        setImeiNumber(e);
        break;
      case 'condition':
        setValue('condition', e);
        setConditionProduct(e);
        await fetchSecondHandPriceByCondition(e, productName);
        break;
      case 'insurance_status':
        setValue('insurance_status', e);
        setInsuranceStatus(e);
        break;
      case 'insurance_date':
        let dateISO = moment(e).toISOString();
        insuranceDateRef.current = dateISO;
        setValue('insurance_date', e);
        setInsuranceDate(e);
        break;
      case 'highest_approve_amount':
        setHighestApproveAmount(e);
        break;
      case 'approve_paid_amount':
        setValue('approve_paid_amount', e);
        setApprovePaidAmount(e);
        break;
      case 'id_card_image':
        setValue(ref, e.fileList);
        saveImage(e, 'id_card_image');
        clearErrors(ref);
        break;
      case 'id_card':
        setValue('id_card', e);
        if (e.length === 13) {
          const validateIdCard = checkIdCardPattern(e);
          setValidateIdCardValue(validateIdCard);
        }
        setIdCard(e);
        break;
      case 'name':
        let checkName2 = checkSameNameWithPrefix(e, bookBankName);
        if (checkName2 === false) {
          setEnabledChangeName(true);
        } else if (checkName2 === true) {
          setEnabledChangeName(false);
        }
        setValue('name', e);
        setName(e);
        break;
      case 'tel':
        setValue('tel', e);
        setTel(e);
        break;
      case 'book_bank_image':
        setValue(ref, e.fileList);
        saveImage(e, 'book_bank_image');
        clearErrors(ref);
        break;
      case 'book_bank_image_new':
        setValue(ref, e.fileList);
        saveImage(e, 'book_bank_image_new', true);
        clearErrors(ref);
        break;
      case 'change_name_image':
        setValue(ref, e.fileList);
        saveImage(e, 'change_name_image');
        clearErrors(ref);
        break;
      case 'change_name_image_new':
        setValue(ref, e.fileList);
        saveImage(e, 'change_name_image_new', true);
        clearErrors(ref);
        break;
      case 'book_bank_code':
        setValue('book_bank_code', e);
        setBookBankCode(e);
        clearErrors('book_bank_image');
        clearErrors(ref);
        break;
      case 'book_bank_branch':
        setValue('book_bank_branch', e);
        setBookBankBranch(e);
        clearErrors('book_bank_image');
        clearErrors(ref);
        break;
      case 'book_bank_name':
        if (name !== null) {
          let checkName = checkSameNameWithPrefix(name, e);
          if (checkName === false) {
            setEnabledChangeName(true);
          } else if (checkName === true) {
            setEnabledChangeName(false);
          }
        }
        setValue('book_bank_name', e);
        setBookBankName(e);
        clearErrors('book_bank_image');
        clearErrors(ref);
        break;
      case 'book_bank_number':
        setValue('book_bank_number', e);
        setBookBankNumber(e);
        clearErrors('book_bank_image');
        clearErrors(ref);
        break;
      // case 'signature_image':
      //     setValue('signature_image', e)
      //     setSignatureImage(e)
      //     break;
      case 'remark':
        setValue('remark', e);
        setRemark(e);
        break;
      case 'mobile_image':
        setValue(ref, e.fileList);
        saveImage(e, 'mobile_image');
        clearErrors(ref);
        break;
      case 'mobile_image_new':
        setValue(ref, e.fileList);
        saveImage(e, 'mobile_image_new', true);
        clearErrors(ref);
        break;
      case 'document_image':
        setValue(ref, e.fileList);
        saveImage(e, 'document_image');
        clearErrors(ref);
        break;
      case 'document_image_new':
        setValue(ref, e.fileList);
        saveImage(e, 'document_image_new', true);
        clearErrors(ref);
        break;
    }
    clearErrors(ref);
  };

  const saveImage = (e, ref, isNew) => {
    if(isNew !== true) {
      e.fileList.map(async (el, i) => {
        if (el?.response?.message === 'get url upload success') {
          await axios.put(el?.response?.data?.url, e.fileList[i].originFileObj, {headers: {'Content-Type': e.fileList[i].originFileObj.type}});
        }
      });
    }

    switch (ref) {
      case 'id_card_image':
        const objFile = e.fileList.map((val) => {
          if (val?.response?.message === 'get url upload success') {
            return {
              mimeType: val.response && val.type,
              url: val.response && val?.response?.data?.url,
            };
          }
        });
        // idCardImageList.current = objFile
        setIdCardImageList(objFile);
        break;
      case 'book_bank_image':
        const objFile2 = e.fileList.map((val) => {
          if (val?.response?.message === 'get url upload success') {
            return {
              ...val,
              mimeType: val.response && val.type,
              url: val.response && val?.response?.data?.url,
            };
          }
        });

        setBookBankImage(objFile2);
        break;
      case 'book_bank_image_new':
        setBookBankImage(e);
        break;
      case 'change_name_image':
        const objFile3 = e.fileList.map((val) => {
          if (val?.response?.message === 'get url upload success') {
            return {
              mimeType: val.response && val.type,
              url: val.response && val?.response?.data?.url,
            };
          } else {
            return {
              mimeType: val?.mimeType || '',
              url: val?.url || '',
            };
          }
        });
        setChangeNameImageList(objFile3);
        break;
      case 'change_name_image_new':
        setChangeNameImageList(e);
        break;
      case 'mobile_image':
        const objFile4 = e.fileList.map((val) => {
          if (val?.response?.message === 'get url upload success') {
            return {
              mimeType: val.response && val.type,
              url: val.response && val?.response?.data?.url,
            };
          } else {
            return {
              mimeType: val?.mimeType || '',
              url: val?.url || '',
            };
          }
        });
        setMobileImageList(objFile4);
        break;
      case 'mobile_image_new':
        setMobileImageList(e);
        break;
      case 'document_image':
        const objFile5 = e.fileList.map((val) => {
          if (val?.response?.message === 'get url upload success') {
            return {
              mimeType: (val.response && val.type) || val?.mimeType,
              url: (val.response && val?.response?.data?.url) || val?.url,
            };
          } else {
            return {
              mimeType: val?.mimeType || '',
              url: val?.url || '',
            };
          }
        });
        setDocumentImageList(objFile5);
        break;
        case 'document_image_new':
          setDocumentImageList(e);
        break;
    }
    clearErrors(ref);
  };

  const getDataOCR = async (url) => {
    setLoading(true);
    try {
      const res = await POST(GET_DATA_OCR, {link: url});
      const {message, data} = res;

      if (message === 'get ocr success') {
        clearErrors('book_bank_image');
        if (JSON.stringify(data.ocr) !== '{}') {
          if (data?.ocr?.bankCode !== null) {
            setBookBankCode(data?.ocr.bankCode);
          }

          if (data?.ocr?.accountName !== null) {
            setValue('book_bank_name', data?.ocr.accountName);
            setBookBankName(data?.ocr.accountName);
            if (name !== null) {
              let checkName = checkSameNameWithPrefix(name, data?.ocr.accountName);
              if (checkName === false) {
                setEnabledChangeName(true);
              } else if (checkName === true) {
                setEnabledChangeName(false);
              }
            } else {
              Modal.error({
                title: 'กรุณากรอกชื่อ-นามสกุล' || '',
              });
            }
          }

          if (data?.ocr?.accountNo !== null) {
            setBookBankNumber(data?.ocr.accountNo);
          }
          if (data?.ocr.bankCode === null && data?.ocr.accountName && data?.ocr?.accountNo === null) {
            setError('book_bank_image', {type: 'required', message: 'ไม่สามารถอ่าน OCR รูปหน้า Book Bank ได้'});
          }
        } else {
          setError('book_bank_image', {type: 'required', message: 'ไม่สามารถอ่าน OCR รูปหน้า Book Bank ได้'});
        }
        setDisabled(false);
        clearErrors('book_bank_code');
        clearErrors('book_bank_name');
        clearErrors('book_bank_branch');
        clearErrors('book_bank_number');
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message || '',
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchSecondHandPriceByCondition = async (conditionValue, productValue) => {
    let obj = {
      condition: conditionValue,
      productName: productValue,
    };
    try {
      const res = await POST(GET_SECOND_HAND_PRICE, obj);
      const {success, result} = res;
      if (success) {
        // const data = result.con
        setValue('highest_approve_amount', result);
        setHighestApproveAmount(result);
      }
    } catch (err) {
      const {error} = err;
      Modal.error({
        title: error,
      });
    }
  };

  const onRefresh = async () => {
    await getDetailEditSecondHand(documentNo);
  };

  const scrollToError = (e) => {
    window.scrollTo({
      top: e, //100
      left: 100,
      behavior: 'smooth',
    });
  };

  const onConfirm = () => {
    if (branchName === null) {
      setError('branch', {type: 'required', message: 'โปรดเลือกสาขาที่รับซื้อ'});
    }

    if (productName === null) {
      setError('product', {type: 'required', message: 'โปรดเลือกสินค้า'});
    }

    if (serialNumber === null) {
      setError('serial_number', {type: 'required', message: 'กรุณากรอก S/N'});
    }

    if (imeiNumber === null) {
      setError('imei', {type: 'required', message: 'กรุณากรอก IMEI'});
    }

    if (conditionProduct === null) {
      setError('condition', {type: 'required', message: 'โปรดเลือกสภาพเครื่อง'});
    }

    if (insuranceStatus === null) {
      setError('insurance_status', {type: 'required', message: 'โปรดเลือกประกัน'});
    }

    if (insuranceStatus === 'YES') {
      if (insuranceDateRef.current === null) {
        setError('insurance_date', {type: 'required', message: 'โปรดเลือกวันที่สิ้นสุดประกัน'});
      }
    }

    if (approvePaidAmount === null) {
      setError('approve_paid_amount', {type: 'required', message: 'กรุณากรอกวงเงินที่อนุมัติ'});
    } else if (approvePaidAmount !== null) {
      if (parseInt(approvePaidAmount) > highestApproveAmount) {
        if (!allowUserEmailList.includes(user.email)) {
          setError('approve_paid_amount', {type: 'required', message: 'วงเงินที่อนุมัติต้องไม่เกินกว่าวงเงินสุงสุด'});
        }
      }

      if (!approvePaidAmount.match(/^[0-9]+$/)) {
        setError('approve_paid_amount', {type: 'required', message: 'วงเงินที่กรอกต้องมีเฉพาะตัวเลข'});
      }
    }

    if (JSON.stringify(idCardImageList) === '[]'  && isVivid === false) {
      setError('id_card_image', {type: 'required', message: 'โปรดอัพโหลดรูปคู่บัตรประชาชน'});
      scrollToError(500);
    }

    if (idCard === null) {
      setError('id_card', {type: 'required', message: 'โปรดกรอกเลขบัตรประชาชน'});
    } else if (idCard !== null) {
      if (idCard.length === 13) {
        if (validateIdCardValue === false) {
          setError('id_card', {type: 'required', message: `กรอกเลขบัตรประชาชนให้ถูกต้อง`});
        }
      } else if (idCard.length !== 13) {
        setError('id_card', {type: 'required', message: `ระบุไม่เกินหรือน้อยกว่า 13 ตัวอักษร`});
      }
    }

    if (name === null) {
      setError('name', {type: 'required', message: 'โปรดกรอกชื่อ-นามสกุล'});
    }

    if (tel === null) {
      setError('tel', {type: 'required', message: 'โปรดกรอกเบอร์โทรศัพท์'});
    } else if (tel !== null) {
      if (tel.length !== 10) {
        setError('tel', {type: 'required', message: `ระบุไม่เกินหรือน้อยกว่า 10 ตัวอักษร`});
      }
    }

    if (JSON.stringify(bookBankImage) === '[]') {
      setError('book_bank_image', {type: 'required', message: 'โปรดอัพโหลดรูปหน้าบัญชีธนาคาร'});
      scrollToError(700);
    }

    if (JSON.stringify(mobileImageList) === '[]') {
      setError('mobile_image', {type: 'required', message: 'โปรดอัพโหลดรูปเครื่อง'});
    }

    if (JSON.stringify(documentImageList) === '[]') {
      setError('document_image', {type: 'required', message: 'โปรดอัพโหลดรูปเอกสาร'});
    }

    if (bookBankCode === null) {
      setError('book_bank_code', {type: 'required', message: 'กรุณาเลือกธนาคาร'});
    }

    if (bookBankBranch === '') {
      setError('book_bank_branch', {type: 'required', message: 'กรุณากรอกชื่อสาขาธนาคาร'});
    }

    if (bookBankName === '') {
      setError('book_bank_name', {type: 'required', message: 'กรุณากรอกชื่อบัญชี'});
    }

    if (bookBankNumber === '') {
      setError('book_bank_number', {type: 'required', message: 'กรุณากรอกเลขที่บัญชี'});
    }

    if (enabledChangeName) {
      if (JSON.stringify(changeNameImageList) === '[]') {
        setError('change_name_image', {type: 'required', message: 'โปรดอัพโหลดรูปใบเปลี่ยนชื่อ'});
        scrollToError(1000);
      }
    }

    // if (signatureImage === null) {
    //     setError('signature_image', { type: 'required', message: 'โปรดลงลายมือชื่อ' });
    //     scrollToError(1100)
    // }

    if (enabledChangeName) {
      if (insuranceStatus === 'NO') {
        if (
          (JSON.stringify(idCardImageList) !== '[]'  || (JSON.stringify(idCardImageList) === '[]' && isVivid === true)) &&
          JSON.stringify(bookBankImage) !== '[]' &&
          JSON.stringify(changeNameImageList) !== '[]' &&
          bookBankCode !== null &&
          bookBankName !== '' &&
          bookBankBranch !== '' &&
          bookBankNumber !== '' &&
          branchName !== null &&
          productName !== null &&
          serialNumber !== null &&
          imeiNumber !== null &&
          conditionProduct !== null &&
          idCard !== null &&
          name !== null &&
          tel !== null &&
          approvePaidAmount !== null &&
          insuranceStatus !== null &&
          validateIdCardValue &&
          JSON.stringify(mobileImageList) !== '[]' &&
          JSON.stringify(documentImageList) !== '[]'
        ) {
          if (approvePaidAmount.match(/^[0-9]+$/)) {
            if (
              parseInt(approvePaidAmount) <= highestApproveAmount ||
              (parseInt(approvePaidAmount) > highestApproveAmount && allowUserEmailList.includes(user.email))
            ) {
              Modal.confirm({
                title: 'ยืนยัน',
                icon: <Icon.warningAlert />,
                content: 'ยืนยันการแก้ไขรายการรับซื้อสินค้ามือสอง',
                onOk() {
                  onSubmit();
                },
                okText: 'ยืนยัน',
                cancelText: 'ยกเลิก',
              });
            }
          }
        }
      } else if (insuranceStatus === 'YES') {
        if (
          (JSON.stringify(idCardImageList) !== '[]' || (JSON.stringify(idCardImageList) === '[]' && isVivid === true)) &&
          JSON.stringify(bookBankImage) !== '[]' &&
          JSON.stringify(changeNameImageList) !== '[]' &&
          bookBankCode !== null &&
          bookBankName !== '' &&
          bookBankBranch !== '' &&
          bookBankNumber !== '' &&
          branchName !== null &&
          productName !== null &&
          serialNumber !== null &&
          imeiNumber !== null &&
          conditionProduct !== null &&
          idCard !== null &&
          name !== null &&
          tel !== null &&
          approvePaidAmount !== null &&
          insuranceStatus !== null &&
          insuranceDateRef.current !== null &&
          validateIdCardValue &&
          JSON.stringify(mobileImageList) !== '[]' &&
          JSON.stringify(documentImageList) !== '[]'
        ) {
          if (approvePaidAmount.match(/^[0-9]+$/)) {
            if (
              parseInt(approvePaidAmount) <= highestApproveAmount ||
              (parseInt(approvePaidAmount) > highestApproveAmount && allowUserEmailList.includes(user.email))
            ) {
              Modal.confirm({
                title: 'ยืนยัน',
                icon: <Icon.warningAlert />,
                content: 'ยืนยันการแก้ไขรายการรับซื้อสินค้ามือสอง',
                onOk() {
                  onSubmit();
                },
                okText: 'ยืนยัน',
                cancelText: 'ยกเลิก',
              });
            }
          }
        }
      }
    } else {
      if (insuranceStatus === 'NO') {
        if (
          (JSON.stringify(idCardImageList) !== '[]'  || (JSON.stringify(idCardImageList) === '[]' && isVivid === true)) &&
          JSON.stringify(bookBankImage) !== '[]' &&
          bookBankCode !== null &&
          bookBankName !== '' &&
          bookBankBranch !== '' &&
          bookBankNumber !== '' &&
          branchName !== null &&
          productName !== null &&
          serialNumber !== null &&
          imeiNumber !== null &&
          conditionProduct !== null &&
          idCard !== null &&
          name !== null &&
          tel !== null &&
          approvePaidAmount !== null &&
          insuranceStatus !== null &&
          validateIdCardValue &&
          JSON.stringify(mobileImageList) !== '[]' &&
          JSON.stringify(documentImageList) !== '[]'
        ) {
          if (approvePaidAmount.match(/^[0-9]+$/)) {
            if (
              parseInt(approvePaidAmount) <= highestApproveAmount ||
              (parseInt(approvePaidAmount) > highestApproveAmount && allowUserEmailList.includes(user.email))
            ) {
              Modal.confirm({
                title: 'ยืนยัน',
                icon: <Icon.warningAlert />,
                content: 'ยืนยันการแก้ไขรายการรับซื้อสินค้ามือสอง',
                onOk() {
                  onSubmit();
                },
                okText: 'ยืนยัน',
                cancelText: 'ยกเลิก',
              });
            }
          }
        }
      } else if (insuranceStatus === 'YES') {
        if (
          (JSON.stringify(idCardImageList) !== '[]' || (JSON.stringify(idCardImageList) === '[]' && isVivid === true)) &&
          JSON.stringify(bookBankImage) !== '[]' &&
          bookBankCode !== null &&
          bookBankName !== '' &&
          bookBankBranch !== '' &&
          bookBankNumber !== '' &&
          branchName !== null &&
          productName !== null &&
          serialNumber !== null &&
          imeiNumber !== null &&
          conditionProduct !== null &&
          idCard !== null &&
          name !== null &&
          tel !== null &&
          approvePaidAmount !== null &&
          insuranceStatus !== null &&
          insuranceDateRef.current !== null &&
          validateIdCardValue &&
          JSON.stringify(mobileImageList) !== '[]' &&
          JSON.stringify(documentImageList) !== '[]'
        ) {
          if (approvePaidAmount.match(/^[0-9]+$/)) {
            if (
              parseInt(approvePaidAmount) <= highestApproveAmount ||
              (parseInt(approvePaidAmount) > highestApproveAmount && allowUserEmailList.includes(user.email))
            ) {
              Modal.confirm({
                title: 'ยืนยัน',
                icon: <Icon.warningAlert />,
                content: 'ยืนยันการแก้ไขรายการรับซื้อสินค้ามือสอง',
                onOk() {
                  onSubmit();
                },
                okText: 'ยืนยัน',
                cancelText: 'ยกเลิก',
              });
            } else {
            }
          }
        }
      }
    }
  };

  const onSubmit = async () => {
    clearErrors();
    const bankName = bankOptions.filter((el) => el.value === bookBankCode);

    const mobileImage = mobileImageList.map((el) => {
      return cutStringImgSigned(el?.url) || '';
    });

    const documentImage = documentImageList.map((el) => {
      return cutStringImgSigned(el?.url) || '';
    });

    let obj = {
      branch: branchName || '',
      product: productName || '',
      serial_number: serialNumber || '',
      imei: imeiNumber || '',
      condition: conditionProduct || '',
      insurance_status: insuranceStatus || '',
      insurance_date: insuranceDateRef.current || '',
      approve_paid_amount: parseInt(approvePaidAmount) || 0,
      imageFrontIdCard: isVivid === false ?cutStringImgSigned(idCardImageList[0]?.url): '',
      id_card: idCard,
      name: name || '',
      tel: tel || '',
      book_bank_image: cutStringImgSigned(bookBankImage[0]?.url) || '',
      bank_code: bookBankCode || '',
      bank_name: bankName[0]?.label || '',
      book_bank_branch: bookBankBranch || '',
      book_bank_number: bookBankNumber || '',
      book_bank_name: bookBankName || '',
      change_name_image: cutStringImgSigned(changeNameImageList[0]?.url) || '',
      remark: remark,
      // imageSignature: signatureImage || '',
      imageMobile: mobileImage || [],
      imageDocument: documentImage || [],
    };
    setLoading(true);
    
    try {
      const res = await POST(EDIT_RECORD_SECOND_HAND(documentNo), obj);
      const {success, message} = res;
      if (success) {
        Modal.success({
          title: message || '',
          afterClose: () => {
            onRefresh();
            window.open('/buy-second-hand-report', '_self');
          },
        });
      }
    } catch (err) {
      const {error, message} = err;
      Modal.error({
        title: error || message || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onPreview = async (file) => {
    if (file?.url) {
      const url = file.url.split('?')[0] || file.url;
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  const selectedForm = isVivid
  ? EditRecordSecondHandNewForm
  : EditRecordSecondHandForm;

  return (
    <Wrapper >
      {loading ? (
        <div style={{display: 'flex', justifyContent: 'center', marginTop: 300}}>
          <Spin tip="Loading..." spinning={loading}></Spin>
        </div>
      ) : (
        <Card style={{ maxWidth: '100%', width: '100%', boxSizing: 'border-box' }}>
          <div style={{display: 'flex', alignItems: 'center', color: '#0052BA', marginBottom: 10,
            flexWrap: 'wrap'}}>
            <EditOutlined />
            &nbsp;
            <TextLarge text={'แก้ไขรายการรับซื้อสินค้ามือสอง'} color={'#0052BA'} />
          </div>
          <PageLayout
            infoLayout={{
              formInfo: [],
              extra: (
                <form style={{ width: '100%' }}>
                  <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gap: '10px',
                    width: '100%',
                  }}
                >
                  <RenderForm
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    formList={selectedForm({
                      fn: {handleChange},
                      data: {
                        idCardImageList,
                        bookBankImage,
                        changeNameImageList,
                        enabledChangeName,
                        insuranceStatus,
                        signatureImage,
                        disabled,
                        mobileImageList,
                        documentImageList,
                        status
                      },
                      options: {optionBranch, optionProduct, bankOptions, optionCondition},
                      onPreview: onPreview
                    })}
                    renderButton={
                      <ContainerButton center>
                        <ButtonTheme useFor="SUBMIT" title="บันทึก" onClick={() => onConfirm()} />
                        <ButtonTheme
                          useFor="CANCEL"
                          onClick={() => {
                            window.location.replace('/buy-second-hand-report');
                          }}
                        />
                      </ContainerButton>
                    }
                  />
                  </div>
                </form>
              ),
            }}
          />
        </Card>
      )}
    </Wrapper>
  );
};

export default EditRecordSecondHand;
