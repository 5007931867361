import React from 'react';
import PropTypes from 'prop-types';
import {Select} from 'antd';
import {masterInsuranceStatus} from '../../constants/masterData';

const SelectInsuranceStatus = (props) => {
  const {placeholder, ...otherProp} = props;
  const dataSc = masterInsuranceStatus;

  return <Select {...otherProp} options={dataSc} placeholder={placeholder} showSearch={false} />;
};

SelectInsuranceStatus.propTypes = {
  placeholder: PropTypes.string.isRequired,
};

export default SelectInsuranceStatus;
