import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Select, Modal} from 'antd';
import {GET, GET_BRANCH_OPTION_SPECIFIC} from '../../../services';
const admin_branch = localStorage.getItem('admin_branch');

const SelectBranch = (props) => {
  const {placeholder, handleChange, dataResponse, multiple, ...otherProp} = props;
  const [dataSc, setDataSc] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (admin_branch) fetchData();
  }, [admin_branch]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await GET(GET_BRANCH_OPTION_SPECIFIC);
      const {success, result} = res;
      if (success) {
        let branchFilter = result.filter((el) => el.value !== 'ON_HOLD');

        if (admin_branch !== 'CENTER') {
          branchFilter = result.filter((el) => el.value === admin_branch);
        }

        setDataSc(branchFilter);

        if (dataResponse) {
          dataResponse(result);
        }
      }
    } catch (err) {
      Modal.error({
        title: 'ไม่สามารถดึงข้อมูลสาขาได้',
        onOk() {},
      });
      setDataSc([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Select
      {...otherProp}
      style={{width: '100%'}}
      options={dataSc}
      placeholder={placeholder}
      loading={loading}
      showSearch
      onChange={(value) => (multiple ? handleChange(value) : handleChange(_.find(dataSc, (data) => data?.value === value)))}
      multiple={multiple}
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    />
  );
};

SelectBranch.propTypes = {
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  filter: PropTypes.object,
  dataResponse: PropTypes.func,
};

export default SelectBranch;
