export const UnlockIcloudSearchForm = ({options}) => {
  return [
    {
      key: '2',
      span: 6,
      name: 'user_name',
      label: 'ชื่อ-นามสกุล',
      placeholder: 'กรุณากรอกชื่อ-นามสกุล',
      type: 'INPUT',
      value: '',
    },
    {
      key: '2',
      span: 6,
      name: 'approve_status',
      label: 'สถานะ',
      placeholder: 'เลือกสถานะ',
      type: 'SELECT',
      options: options.optionsApprove,
      allowClear: true
    },
    {
      key: '3',
      span: 6,
      name: 'admin_mdm_unlock',
      label: 'ผู้กดปลดล็อก MDM',
      placeholder: 'กรุณากรอกผู้กดปลดล็อก MDM',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '4',
      span: 6,
      name: 'admin_approve',
      label: 'ผู้กดอนุมัติรหัส',
      placeholder: 'กรุณากรอกผู้กดอนุมัติรหัส',
      type: 'INPUT',
      value: '',
      allowClear: true,
    },
    {
      key: '5',
      span: 6,
      name: 'type_lock',
      label: 'Type Lock',
      placeholder: 'Type Lock',
      type: 'SELECT',
      options: options.optionsTypeLock,
      allowClear: true,
    },
  ];
};