import axios from 'axios';
import {API_URL} from '../../env.js';
import _ from 'lodash';
import { Typography } from 'antd';
import { decryptString } from '../../functions/fn';

const {Text} = Typography;

const wrap = (func) =>
  function () {
    return func.apply(null, arguments).catch((e) => {
      if (e?.response?.data?.fgfStatus === 'FGF_NOT_MATCH_CONDITION') {
        return e?.response?.data;
      }
      return Promise.reject(new Error(e?.response?.data?.error || e?.message));
    });
  };

const getHeaders = () => ({
  headers: {
    authorization: `Bearer ${localStorage.getItem('token')}`,
    'content-type': 'application/json',
  },
});

const priceLookup = {};
const priceLookup2 = {}; 
const pricelookupOthers = {}
let productCode = []
let couponDetail = {}

const mapProductCodeToSerialNumber = (serialNumbers, productCodes) => {
  // return if only match sn in two array
  return productCodes.filter(product =>
    serialNumbers.some(serial => serial.serial_number === product.serialNumber)
  );
}

export const loadBarcodeName = async (barcode) => {
  // productCode = []
  var qs = new URLSearchParams({
    page: 1,
    size: 10,
    serial_number: barcode,
  });
  if (barcode.indexOf(' ') >= 0) {
    return (
      <Text style={{color: 'red'}}>
        Serial number ไม่ควรมีช่องว่าง
        <br />
        &emsp;&emsp;&emsp;&emsp;&ensp;กรุณาเช็ค Serial number ใหม่อีกครั้ง
      </Text>
    );
  }
  var res = await axios.get(`${API_URL}/admin/product/tradesys/product-serial-one?${qs}`, getHeaders());
  //const product = res?.data?.data?.products?.[0];
  const product = res?.data?.data?.products?.[0]; 
  priceLookup[product.serialnumber] = product.price;
  priceLookup2[product.serialnumber] = product.price2;
  pricelookupOthers[product.serialnumber] = {price2: product.price2, price3: product.price3, price4: product.price4} 

  productCode.push({code: product.code, name: product.name, serialNumber: product.serialnumber});

  return product.name;
};

export const sumPrice = (serialNumbers) => {
  var ans = 0;
  if (serialNumbers) for (var serial of serialNumbers) ans += priceLookup[serial];
  return ans;
};

export const sumPrice2 = (serialNumbers) => {
  var ans2 = 0;
  if (serialNumbers) {
    for (var serial of serialNumbers) {
      switch (!!priceLookup2[serial]) {
        case priceLookup2[serial] === 'สินค้านี้ไม่มีการระบุราคาที่2':
          priceLookup2[serial] = 'สินค้านี้ไม่มีการระบุราคาที่2';
          ans2 = priceLookup2[serial];
          break;
        default:
          ans2 += priceLookup2[serial];
          break;
      }
    }
  }
  return ans2;
};

export const sumPriceOther = (serialNumbers, keyPrice = 'price2') => { 
  var totalPrice = 0;
  if (serialNumbers) {
    for (var serial of serialNumbers) {
      totalPrice+= pricelookupOthers[serial][keyPrice]
    }
  }
  return totalPrice;
};

export const getCustomerList = wrap(async (formData) => {
  var { page, size, id_card, tel, name, type, contractType } = formData;
  var qs = new URLSearchParams({
    page: page,
    size: size,
    ...(id_card && { id_card }),
    ...(name && { name }),
    ...(tel && { tel }),
    ...(type && { type }),
    ...(contractType && { contractType }),
  });
  var res = await axios.get(`${API_URL}/admin/customer?${qs}`, getHeaders());

  // var ans = res?.data?.data?.customers;
  var ans = res?.data;
  return ans;

  // return ans.map((a) => {
  //   const job_cat = a?.education?.college ? 'student' : 'employed';
  //   return { customer2: { ...a, job_cat } };
  // });
});

export const deleteCustomers = wrap(async (customerArray) => {
  var options = {
    ...getHeaders(),
    data: {customer_id: customerArray.map((a) => a.customer2._id)},
  };
  let res = await axios.delete(`${API_URL}/admin/customer/delete`, options);
  return res?.data;
});

const reformatData = (formData) => {
  if (formData.customer) {
    formData.customer.fullname = formData.customer.general.fullname;
    formData.customer.prefix = formData.customer.general.prefix;
    formData.customer.gender = formData.customer.general.gender;
    formData.customer.dob = formData.customer.general.dob;
    formData.customer.facebook = formData.customer?.social_media?.facebook;
    formData.customer.line = formData.customer?.social_media?.line;
  } else if (formData.customer2) {
    formData.customer2.fullname = formData.customer2.general.fullname;
    formData.customer2.prefix = formData.customer2.general.prefix;
    formData.customer2.gender = formData.customer2.general.gender;
    formData.customer2.dob = formData.customer2.general.dob;
    formData.customer2.facebook = formData.customer2?.social_media?.facebook;
    formData.customer2.line = formData.customer2?.social_media?.line;
  }
};

export const updateCustomer = wrap(async (formData, originalData) => {
  // reformatData(formData);
  var _id = originalData.customer ? originalData.customer._id : originalData.customer2._id;
  var data = formData.customer ? formData.customer : formData.customer2;

  data.code = data.id_card;
  var res = await axios.put(`${API_URL}/admin/customer/${_id}/update`, data, getHeaders());

  var ans = res?.data;
  return ans;
  // var ans = res?.data?.data;
  // return { customer: ans, _id: originalData._id };
});

export const createCustomer = wrap(async (formData) => {
  reformatData(formData);
  var res = await axios.post(`${API_URL}/admin/customer/create`, {customers: [formData.customer]}, getHeaders());
  return res?.data?.data?.[0]?._id;
});

export const createContract = wrap(async (formData, user_id, fgfData = {}) => {
  var contract = _.cloneDeep(formData.contract);
  contract.contract.type = contract.type;

  if (contract.contract.type === 'INSTALLMENT') {
    contract.serial_number = contract.serial_number.map((a) => ({serial_number: a}));
  } else {
    var {serial_number2, price, product_code} = contract;
    contract.serial_number = [{serial_number: serial_number2, price, product_code}];
    contract.contract.periods = contract.contract.periods_fin;
  }

  let data = {};

  //handle change to uppercase for couponCode from joi auto admin form
  contract.contract.couponCode = contract?.contract?.couponCode?.toUpperCase();

  if (contract.contract.type === 'REFINPACKAGE') {
    const respon = await axios.get(
      `${API_URL}/admin/packages/get-detail-package-by-id?id=${contract.contract.package}&branch=${contract.branch}`,
      getHeaders(),
    );
    const resultPackage = respon?.data?.result?.detail;
    let items = [];
    resultPackage.forEach((el) => {
      const {item_code, item_name, cost, qty} = el;
      items.push({item_code, item_name, cost, qty});
    });
    const packages = {
      package_id: resultPackage[0].package_head_id,
      package_name: resultPackage[0].package_name,
      item: items,
    };

    data = {user_id, ...contract, package: packages};
  } else {
    data = {user_id, ...contract};
  }
  if (contract.contract.type === 'REFINANCE') {
    localStorage.removeItem('refinancePeriod');
  }

  /* Check branch */ 
  if (!data?.branch || data?.branch === 'undefined' || data?.branch === 'null') {
    const admin_branch = localStorage.getItem('admin_branch')
    if (!admin_branch || admin_branch === 'undefined' || admin_branch === 'null') {
      throw new Error('ข้อมูลสาขาไม่ถูกต้อง')
    }
    data['branch'] = admin_branch
  }

  // data.coupon_id = couponDetail.coupon_id

  if (couponDetail.coupon_id !== '') {
    data.coupon_id = couponDetail.coupon_id;
  }

  /*FGF: 2.ทำสัญญา */
  const resGetFGFData = await getFGFData();
  const { friend_get_friend, success } = resGetFGFData;
  if (success) {
    data['friend_get_friend'] = friend_get_friend;
  }

  if (!_.isEmpty(fgfData)) {
    data['fgfStatus'] = fgfData?.fgfStatus || ''
  }

  /* MDM_SETTING_DEFAULT */ 
  if (!['MDM', 'MDMICLOUD'].includes(contract.contract.type_lock)) {
    data['mdm_provider_code'] = null
    data['mdm_site_code'] = null
  } 

  // จัดข้อมูลจาก serial_number ไปใส่ productCode
  if (contract.contract.type === 'INSTALLMENT') {
    const mappedProducts = mapProductCodeToSerialNumber(contract.serial_number, productCode);
    data['productCode'] = mappedProducts
  } else {
    productCode[0]['serialNumber'] = contract.serial_number[0]['serial_number']
    data['productCode'] = productCode
  }

  var res = await axios.post(`${API_URL}/admin/contract/create`, data, getHeaders());
  couponDetail = {};
  return res?.data?.data;
});

export const createCustomerAndContract = async (formData) => {
  var customerId = await createCustomer(formData);
  return await createContract(formData, customerId);
};

export const uploadContractImg = async (file) => {
  var extension = null;

  file.name.replace(/\.[^.]{3,4}$/, (m) => (extension = m));

  if (!extension) throw new Error('extension not found');

  var qs = new URLSearchParams({extension});
  var res = await axios.get(`${API_URL}/admin/contract/get-url-upload?${qs}`, getHeaders());
  var gcsUrl = res?.data?.data?.url;

  await axios.put(gcsUrl, file, {headers: {'Content-Type': file.type}});
  return gcsUrl;
};

export const uploadIdCardPhoto = async (base64) => {
  var res = await axios.get(`${API_URL}/admin/customer/get-url-upload?extension=.png`, getHeaders());
  var url = res?.data?.data?.url;

  //convert
  var bstr = atob(base64);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) u8arr[n] = bstr.charCodeAt(n);
  var file = new File([u8arr], 'abc.png', {type: 'image/png'});

  await axios.put(url, file, {headers: {'Content-Type': 'image/png'}});

  return url.split(/[#?]/)[0];
};

export const getProductList = async () => {
  var res = await axios.get(`${API_URL}/admin/product/all`, getHeaders());
  var data = res?.data?.data;
  if (Array.isArray(data)) data = _.uniqBy(data, 'code');
  return data;
};

export const getOption = async (type) => {
  var res = await axios.get(`${API_URL}/admin/packages/options?key=${type}`, getHeaders());
  var data = res?.data?.result;
  return data;
};

export const getOptionBranchSpecific = async (type) => {
  var res = await axios.get(`${API_URL}/admin/authen/branchDashboard/options`, getHeaders());
  var data = res?.data;
  return data;
};

export const getOptionRefinanceInterestRate = async (type, price) => {
  var res = await axios.get(`${API_URL}/admin/packages/interest-rate?key=${type}&price=${price}`, getHeaders());
  var data = res?.data?.result;
  return data;
};

export const getOptionPackageByProduct = async (code, period) => {
  var res = await axios.get(`${API_URL}/admin/packages/get-package-by-product-period?code=${code}&period=${period}`, getHeaders());
  var data = res?.data?.result;
  return data;
};

export const getNotificationList = wrap(async (formData) => {
  var {id_card, tel, name, status, channel, system_only} = formData;
  var qs = new URLSearchParams({
    page: 1,
    size: 100,
    ...(status && {status}),
    ...(channel && {channel}),
    ...(id_card && {id_card}),
    ...(name && {name}),
    ...(tel && {tel}),
    ...(system_only && {system_only}),
  });
  var res = await axios.get(`${API_URL}/admin/notification?${qs}`, getHeaders());
  var ans = res?.data?.data?.notifications;
  return ans;
});

export const resendNotification = wrap(async (idList) => {
  await axios.post(`${API_URL}/admin/notification/resend`, {notification_id_list: idList}, getHeaders());
});

export const getTrackPaymentList = wrap(async (formData) => {
  var {page, size, id_card, tel, name, status, stage, has_token_only, total_item} = formData;
  if (!status) status = 'PENDING';
  var qs = new URLSearchParams({
    page: page,
    size: size,
    ...(status && {status}),
    ...(stage && {stage}),
    ...(id_card && {id_card}),
    ...(name && {name}),
    ...(tel && {tel}),
    ...(has_token_only && {has_token_only}),
    ...(total_item && {total_item}),
  });
  var res = await axios.get(`${API_URL}/admin/track?${qs}`, getHeaders());
  // var ans = res?.data?.data?.tracks;
  var ans = res?.data;
  return ans;
});

export const getTrackDataExport = wrap(async (formData) => {
  var {id_card, tel, name, status, stage, has_token_only} = formData;
  if (!status) status = 'PENDING';
  var qs = new URLSearchParams({
    ...(status && {status}),
    ...(stage && {stage}),
    ...(id_card && {id_card}),
    ...(name && {name}),
    ...(tel && {tel}),
    ...(has_token_only && {has_token_only}),
  });

  var res = await axios.get(`${API_URL}/admin/track/export_excel?${qs}`, getHeaders());
  var ans = res?.data;
  return ans;
});

export const getProductSerialList = wrap(async (formData) => {
  var {serial_number, exclude_sale, product_name, type} = formData;
  var qs = new URLSearchParams({
    page: 1,
    size: 100,
    ...(product_name && {product_name}),
    ...(serial_number && {serial_number}),
    ...(exclude_sale && {exclude_sale}),
    ...(type && {type}),
  });
  var res = await axios.get(`${API_URL}/admin/product/tradesys/product-serial?${qs}`, getHeaders());
  var ans = res?.data?.data?.products;
  return ans;
});

export const getProductWithFilterList = wrap(async (formData) => {
  var {code, name, plu} = formData;
  var qs = new URLSearchParams({
    page: 1,
    size: 100,
    ...(code && {code}),
    ...(name && {name}),
    ...(plu && {plu}),
  });
  var res = await axios.get(`${API_URL}/admin/product/?${qs}`, getHeaders());
  var ans = res?.data?.data?.products;
  return ans;
});

export const updateProductImg = wrap(async (file) => {
  var extension = null;
  file.name.replace(/\.[^.]{3,4}$/, (m) => (extension = m));
  if (!extension) throw new Error('extension not found');
  var qs = new URLSearchParams({extension});
  var res = await axios.get(`${API_URL}/admin/product/get-url-upload?${qs}`, getHeaders());
  var gcsUrl = res?.data?.data?.url;
  await axios.put(gcsUrl, file, {headers: {'Content-Type': file.type}});
  return gcsUrl;
});

export const updateProduct = wrap(async (formData, originalData) => {
  let {code, image} = formData;
  let data = {};
  if (image && image.url) data.image = {url: image.url[0]};
  var res = await axios.put(`${API_URL}/admin/product/${code}/update`, data, getHeaders());
  var ans = res?.data?.data;
  return ans;
});

export const sendNotificationTrack = wrap(async (trackIds, channel, sentBy) => {
  let data = {
    track_id_list: trackIds,
    channel,
    sent_by: sentBy,
  };
  var res = await axios.post(`${API_URL}/admin/track/send-manual`, data, getHeaders());
  var ans = res?.data?.data;
  return ans;
});

export const getThaiQRLogs = wrap(async (formData) => {
  var {id_card, name, tel} = formData;
  var qs = new URLSearchParams({
    page: 1,
    size: 100,
    ...(id_card && {id_card}),
    ...(name && {name}),
    ...(tel && {tel}),
  });
  var res = await axios.get(`${API_URL}/admin/payment/thai-qr-log?${qs}`, getHeaders());
  var ans = res?.data?.data?.qr_logs;
  return ans;
});

export const getCustomerInfoWP = wrap(async (userIdCard) => {
  var res = await axios.get(`${API_URL}/admin/customer/wp/${userIdCard}`, getHeaders());
  var ans = res?.data;
  return ans;
});

export const checkBlacklistBeforeApprove = wrap(async (user) => {
  let data = {
    user,
  };

  var res = await axios.post(`${API_URL}/admin/consider/check-blacklist-customer`, data, getHeaders());
  var ans = res?.data;
  return ans;
});

export const deleteConfigProfileMiradore = wrap(async (objData) => {
  var options = {
    ...getHeaders(),
    data: {...objData},
  };
  let res = await axios.delete(`${API_URL}/admin/mdm/deleteConfigProfile`, options);

  return res?.data;
});

export const deActivatedLostModeMiradore = wrap(async (objData) => {
  var options = {
    ...getHeaders(),
    data: {...objData},
  };
  let res = await axios.delete(`${API_URL}/admin/mdm/DeActivate/LostModeAndConfigProfile`, options);

  return res?.data;
});

export const uploadImg = async (base64, imageCat) => {
  var extension = null;
  base64.replace(/([A-Za-z0-9]+(\/[A-Za-z0-9]+)+)/, (m) => (extension = m));
  let newExtension = extension.replace('image/', '.');

  if (!newExtension) throw new Error('extension not found');

  var qs = new URLSearchParams({extension: newExtension, imageCat});
  var res = await axios.post(
    `${API_URL}/admin/contract/getUrlUpload?${qs}`,
    {},
    {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': extension,
      },
    },
  );
  var gcsUrl = res?.data?.data?.url;
  var dataUrl = base64;

  fetch(dataUrl)
    .then((res) => res.arrayBuffer())
    .then((buffer) => {
      axios.put(gcsUrl, buffer, {headers: {'Content-Type': extension}});
    });
  return gcsUrl;
};

export const getCouponData = async (payload) => {
  let product = productCode.map((el) => {
    return el.code;
  });

  payload.product_code = product;

  let res = null;
  await axios
    .post(`${API_URL}/admin/contract/checkCoupon`, payload, getHeaders())
    .then((response) => {
      // Handle successful response
      res = response?.data;
      const result = response?.data;
      if (result.success) {
        couponDetail['coupon_id'] = result.coupon_id;
        couponDetail['coupon_code'] = result.coupon_code;
        couponDetail['coupon_discount'] = result.coupon_discount;
      } else {
        couponDetail['coupon_id'] = '';
        couponDetail['coupon_code'] = result.coupon_code;
        couponDetail['coupon_discount'] = 0;
      }
    })
    .catch((error) => {
      // Handle error
      res = error?.response;
      couponDetail['coupon_id'] = '';
      couponDetail['coupon_code'] = res.data.coupon_code;
      couponDetail['coupon_discount'] = 0;
    });

  return res;
};

export const resetCouponData = () => {
  couponDetail = {};
  return;
};

export const FetchCouponData = () => {
  return couponDetail;
};

export const FetchRefinanceProductCode = (data, name) => {
  let temp = [];
  productCode = _.cloneDeep(temp);
  productCode.push({code: data, name: name});
  return;
};

export const getFGFData = async (idCard) => {
  try {
    const idCard = decryptString(localStorage.getItem('id_card')) || '';
    const res = await axios.post(`${API_URL}/admin/friend-get-friend/checkFGFCode`, { id_card: idCard }, getHeaders());
    const { data, success } = res;

    return data;
  } catch (err) { }
};

export const resetProductCode = () => {
  productCode = []
  return
}