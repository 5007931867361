import React, {useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Select, Modal} from 'antd';
import {getProductCondition} from '../../../services/api/svapi/product';

const SelectProductCondition = (props) => {
  const {filter, placeholder, handleChange, dataResponse, multiple, ...otherProp} = props;
  const {productCode} = filter || {};
  const [dataSc, setDataSc] = useState([]);
  const [loading, setLoading] = useState(false);

  const inputOptions = useMemo(() => {
    return {productCode};
  }, [productCode]);

  useEffect(() => {
    if (productCode) fetchData();
  }, [inputOptions]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const {result} = await getProductCondition(inputOptions);

      setDataSc(
        _.map(result, (dt) => {
          return {
            value: dt?.purchase_condition,
            label: dt?.purchase_condition,
            source: dt,
          };
        }),
      );
      if (dataResponse) {
        dataResponse(result);
      }
    } catch (error) {
      Modal.error({
        title: 'ไม่สามารถดึงข้อมูล Condition ได้',
        onOk() {},
      });
      setDataSc([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Select
      {...otherProp}
      style={{width: '100%'}}
      options={dataSc}
      placeholder={placeholder}
      loading={loading}
      showSearch
      onChange={(value) => (multiple ? handleChange(value) : handleChange(_.find(dataSc, (data) => data?.value === value)))}
      multiple={multiple}
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    />
  );
};

SelectProductCondition.propTypes = {
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  filter: PropTypes.object,
  dataResponse: PropTypes.func,
};

export default SelectProductCondition;
