import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Select, Tag} from 'antd';
import _ from 'lodash';
import {getCheckCoupon} from '../../../../../services/api/svapi/contract';
import {InputCouponStyled} from './InputCouponStyled';

const InputCoupon = (props) => {
  const {
    tokenSeparators = [''],
    form,
    fieldName = 'coupon_code',
    setErrorMessage,
    disabledCoupon = [],
    couponList = [],
    setCouponList,
    couponDataList,
    setCouponDataList,
    productList,
    productCodeList,
    placeholder,
    onChange,
    disabled,
    setCouponDetailList,
    onCouponChange,
    ...othersProps
  } = props;

  const [checkCouponLoading, setCheckCouponLoading] = useState(false);

  const checkCoupon = async (value) => {
    try {
      if (!value) return;

      setCheckCouponLoading(true);

      const payload = {
        coupon_code: value,
        product_code: [...productCodeList],
      };

      const res = await getCheckCoupon(payload);
      const {success, coupon_id, coupon_code, coupon_discount, coupon_description} = res;
      if (success) {
        const couponFormData = [...couponList];
        const couponData = [...couponFormData, coupon_code];
        form.setFieldValue(fieldName, couponData);
        form.setFields([{name: fieldName, errors: []}]);

        setCouponList(couponData);
        setCouponDataList((prev) => [...prev, {coupon_id, coupon_code, coupon_discount, coupon_description}]);

        setCouponDetailList && setCouponDetailList((prev) => [...prev, {coupon_id, coupon_code, coupon_discount, coupon_description}]);
        onChange && onChange(couponData);

        onCouponChange && onCouponChange([...couponDataList, {coupon_id, coupon_code, coupon_discount, coupon_description}]);
      }
    } catch (error) {
      return setErrorMessage({form, error: true, name: fieldName, txtError: [error?.message || 'Error Check Coupon']});
    } finally {
      setCheckCouponLoading(false);
    }
  };

  const handleCouponKeyDown = (e) => {
    const value = e?.target?.value;

    if (e.key === 'Enter' && value !== '') {
      if (_.isEmpty(productCodeList)) {
        setErrorMessage({form, error: true, name: fieldName, txtError: ['กรุณาเลือกสินค้า']});
      } else if (checkDuplicate(value)) {
        setErrorMessage({form, error: true, name: fieldName, txtError: ['ไม่สามารถใช้คูปองซ้ำได้']});
      } else {
        checkCoupon(value);
      }
    }
  };

  const handleOnCloseTag = (removedTag) => {
    // ลบค่าใน input
    const updatedCoupons = _.filter(couponList, (coupon) => coupon !== removedTag);
    setCouponList(updatedCoupons);

    // ลบค่าใน list coupon data
    const updatedCouponsData = _.filter(couponDataList, (coupon) => coupon?.coupon_code !== removedTag);

    setCouponDataList(updatedCouponsData);

    setCouponDetailList && setCouponDetailList((prev) => _.filter(prev, (coupon) => coupon?.coupon_code !== removedTag));

    // อัปเดตค่าในฟอร์มด้วย
    form.setFieldsValue({[fieldName]: updatedCoupons});

    onCouponChange && onCouponChange(updatedCouponsData);
  };

  const tagRender = ({label}) => {
    const isDisabled = disabledCoupon.includes(label);

    return (
      <Tag closable={!isDisabled} onClose={isDisabled ? undefined : () => handleOnCloseTag(label)} style={{margin: 3}}>
        {label}
      </Tag>
    );
  };

  const checkDuplicate = (couponValue) => {
    return _.includes(couponList, couponValue);
  };

  return (
    <InputCouponStyled>
      <Select
        {...othersProps}
        mode="tags"
        style={{width: '100%'}}
        open={false}
        placeholder={'กรุณาระบุสินค้าก่อนการใช้คูปอง' || placeholder}
        tokenSeparators={tokenSeparators}
        tagRender={tagRender}
        onInput={(e) => (e.target.value = _.toUpper(e.target.value))}
        onInputKeyDown={handleCouponKeyDown}
        onBlur={() => form.setFields([{name: fieldName, errors: []}])}
        value={couponList}
        loading={checkCouponLoading}
        disabled={checkCouponLoading || disabled}
      />
    </InputCouponStyled>
  );
};

export default InputCoupon;

InputCoupon.propTypes = {
  form: PropTypes.object.isRequired, // form
  fieldName: PropTypes.string, // ชื่อ field ใน form
  setErrorMessage: PropTypes.func.isRequired, // funtion set error message
  disabledCoupon: PropTypes.array, // คูปองที่ไม่ให้ลบได้
  couponList: PropTypes.array.isRequired, // รายการคูปองในช่อง input
  setCouponList: PropTypes.func.isRequired, // set รายการคูปองในช่อง input
  couponDataList: PropTypes.array.isRequired, // ข้อมูลรายละเอียดคูปอง
  setCouponDataList: PropTypes.func.isRequired, // set ข้อมูลรายละเอียดคูปอง
  productCodeList: PropTypes.array, // array product code
};
