import axios from 'axios';
import { STORAGE_API_URL, STORAGE_API_KEY, STORAGE_API_SECRET } from '../../../env';

export const createParams = (value) => {
  let params = [];
  let result = '';

  Object.keys(value).forEach((key) => {
    if (value[key]) params.push(key + '=' + value[key]);
  });

  if (params.length !== 0) result += params.join('&');

  return result;
};

export const canDownloadContract = (appInfo, excludedTypes) => {
  const {app_info} = appInfo
  const allowedStatuses = [
    'COMPLETED',
    'ACTIVE', 
    'WAIT_ADMIN_CONFIRM',
    'WAIT_FINANCE_APPROVE'
  ];

  return ( allowedStatuses.includes(app_info?.status) && excludedTypes.includes(app_info?.type) )
}