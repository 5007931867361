import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Upload as UploadAntd, Row, Col, Button, Modal} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import _ from 'lodash';
import {UploadStyled, UploadInlineStyle} from './UploadStyled';
import {uploadFile, checkOnline} from '../../../services/api/storageAPI';
import {handleRefreshTokenExpiration} from '../../../oauth';
import {MAX_FILE_SIZE_MB} from '../../../env';
import {TextXSMall} from '../../forms/text';
import {v4 as uuidv4} from 'uuid';

const MAX_FILE_SIZE_MB_NUMBER = Number(MAX_FILE_SIZE_MB || 30);
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB_NUMBER * 1024 * 1024;

const Upload = (props) => {
  const {
    disabled,
    inline,
    orderLabel,
    multiple,
    actiontype,
    nameBtn,
    iconNameBtn,
    accept,
    name,
    showUploadList = true,
    showRemoveIcon,
    defaultFileList,
    uploadBucket,
    handleChange,
    handleRemove,
    replaceFiles,
    maxCount,
    subDir,
    setLoading,
    width,
    linkAlign,
    bucket,
    listType,
    ...otherProps
  } = props?.item ?? props;

  const [onlineStatus, setOnlineStatus] = useState(true);
  const [fileList, setFileList] = useState([]);

  const StyledCompo = inline ? UploadInlineStyle : UploadStyled;

  useEffect(() => {
    if (defaultFileList) {
      const defaultFiles = _.map(defaultFileList, (item, idx) => {
        return {
          ...item,
          uid: uuidv4(),
          status: 'done',
          name: item?.fileNameOrigin ?? item?.fileName,
          type: item?.mimeType,
        };
      });
      setFileList(defaultFiles);
    }
  }, []);

  useEffect(() => {
    let intervalId;

    const checkStorageOnline = async () => {
      try {
        const response = await checkOnline(); // ฟังก์ชันตรวจสอบสถานะ
        if (response?.data) {
          setOnlineStatus(true); // หากออนไลน์ เปลี่ยนสถานะ
          clearInterval(intervalId); // หยุดการตรวจสอบ
        } else {
          setOnlineStatus(false); // หากไม่ออนไลน์ ตั้งสถานะเป็น false
        }
      } catch (error) {
        setOnlineStatus(false);
      }
    };

    // เริ่มการตรวจสอบทุกๆ 5 วินาที
    intervalId = setInterval(checkStorageOnline, 5000);

    // ตรวจสอบทันทีในครั้งแรก
    checkStorageOnline();

    // ล้าง interval เมื่อ component ถูก unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (setLoading) {
      const areAllFilesUploaded = fileList.every((file) => file.status === 'done');

      if (areAllFilesUploaded) {
        /* Set Loading false */
        setLoading(false);
      }
    }
  }, [fileList]);

  const customRequest = async ({file, onSuccess, onError}) => {
    try {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('bucket', bucket);
      formData.append('subDir', subDir);

      if (!bucket) return onError(new Error('bucket Not Found'));

      /* Set Loading true */
      setLoading && setLoading(true);
      const response = await uploadFile(formData);

      if (response && response.success && !_.isEmpty(response?.url)) {
        const {filename: fileName, fileNameOrigin, mimetype: mimeType, url} = response.url[0];

        /* Return response Filename and url */
        onSuccess({fileName, fileNameOrigin, mimeType, url}, file);
      } else {
        handleApiError(response);
      }
    } catch (error) {
      onError(new Error(error?.error || 'Upload failed'));
    }
  };

  const _handleRemove = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);

    handleRemove && handleRemove(newFileList);
  };

  const _handleChange = ({fileList: newFileList}) => {
    const updatedFileList = _.map(newFileList, (item) => ({
      ...item,
      url: item?.response?.url ?? item?.url,
      name: item?.response?.fileNameOrigin ?? item?.name,
      fileName: item?.fileName ?? item?.name,
      fileNameOrigin: item?.fileNameOrigin ?? item?.name,
      mimeType: item?.originFileObj?.type ?? item?.mimeType,
    }));

    setFileList(updatedFileList);

    handleChange && handleChange(updatedFileList);
  };

  const handleBeforeUpload = (file) => {
    /* replaceFile เมื่อกดอัพโหลดใหม่จะรีเซ็ตไฟล์ทั้งหมด */
    if (replaceFiles) setFileList([]);

    if (file.size > MAX_FILE_SIZE) {
      Modal.error({
        title: 'ขนาดไฟล์เกินกำหนด',
        content: `ไฟล์ต้องมีขนาดไม่เกิน ${MAX_FILE_SIZE_MB_NUMBER}MB`,
      });
      return UploadAntd.LIST_IGNORE; // ป้องกันไม่ให้ไฟล์ถูกเพิ่มเข้าไปในรายการ
    }

    return true;
  };

  const handleApiError = (error) => {
    const {code, message} = error?.originalError || {};
    if (_.includes(['AUTH_SESSION_EXPIRED', 'AUTH_REFRESH_TOKEN_EXPIRED', 'AUTH_INVALID_TOKEN'], code)) {
      Modal.warn({
        title: message,
        onOk() {
          handleRefreshTokenExpiration();
        },
      });
    } else {
      throw error;
    }
  };

  return (
    <StyledCompo
      orderLabel={inline ? orderLabel : false}
      name={name}
      title={!onlineStatus ? 'ไม่สามารถเชื่อมต่อ Storage Service ได้' : ''}
      width={width}
      linkAlign={linkAlign}>
      <UploadAntd
        {...otherProps}
        listType={listType ? listType : 'picture'}
        multiple={multiple}
        disabled={disabled || !onlineStatus}
        className={`${inline ? 'upload-file-style-inline' : 'upload-file-style'}`}
        accept={accept}
        maxCount={maxCount ? maxCount : 1}
        customRequest={customRequest}
        defaultFileList={defaultFileList}
        showUploadList={!showUploadList ? false : {showRemoveIcon: showRemoveIcon}}
        fileList={fileList}
        onRemove={_handleRemove}
        onChange={_handleChange}
        beforeUpload={handleBeforeUpload}>
        <Row gutter={[12, 12]}>
          <Col>
            <Button icon={<UploadOutlined />} className={!onlineStatus ? 'offline' : ''} disabled={disabled}>
              {!onlineStatus ? 'ไม่สามารถเชื่อมต่อ Storage Service ได้' : nameBtn || 'อัปโหลดไฟล์'}
            </Button>
          </Col>
        </Row>
        <TextXSMall text={`ไฟล์ขนาดไม่เกิน ${MAX_FILE_SIZE_MB_NUMBER}MB`} color={'#444950'} />
      </UploadAntd>
    </StyledCompo>
  );
};

Upload.propTypes = {
  fileList: PropTypes.array,
  defaultFileList: PropTypes.array,
  showRemoveIcon: PropTypes.bool,
  replaceFiles: PropTypes.bool,
  subDir: PropTypes.string.isRequired,
};

Upload.defaultProps = {
  showRemoveIcon: true,
  multiple: false,
  replaceFiles: false,
};

export default Upload;
