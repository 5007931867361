import React, {useState, useEffect} from 'react';
import {Tabs as TabsAntd} from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';
import TabsStyled from './TabsStyled';

const Tabs = ({tab, size, defaultActiveKey, activeKey, handleTabClick, stopChangeTab, ...otherProps}) => {
  const [stateDefaultActiveKey, setStateDefaultActiveKey] = useState();
  const [tabItems, setTabItems] = useState([]);

  const sizeTab = size || 'default';
  const propsTabs = tab || [];

  useEffect(() => {
    if (tab) {
      if (activeKey) {
        setStateDefaultActiveKey(activeKey);
      } else if (!defaultActiveKey && !stateDefaultActiveKey) {
        if (_.head(propsTabs)) {
          setStateDefaultActiveKey(_.head(propsTabs).key);
        }
      } else if (defaultActiveKey) {
        const at = _.find(propsTabs, (item) => String(item.key) === String(defaultActiveKey));
        if (at) {
          setStateDefaultActiveKey(at.key);
        }
      }
    }
  }, [tab]);

  const onTabClickFnc = (key) => {
    if (!stopChangeTab) {
      setStateDefaultActiveKey(key);
    }
    if (handleTabClick) handleTabClick(key);
    const getItem = _.find(propsTabs, (item) => String(item.key) === String(key));
    if (getItem && getItem.onClick) getItem.onClick();
  };

  useEffect(() => {
    if (!_.isEmpty(propsTabs) && stateDefaultActiveKey) {
      const items = propsTabs.map((Item) => {
        return {
          key: String(Item.key),
          label: Item.label,
          disabled: Item.disabled,
          children: String(Item.key) === String(stateDefaultActiveKey) ? Item.content : null,
        };
      });
      setTabItems(items);
    }
  }, [propsTabs, stateDefaultActiveKey]);

  return (
    <TabsStyled>
      <TabsAntd
        {...otherProps}
        activeKey={String(stateDefaultActiveKey)}
        defaultActiveKey={String(stateDefaultActiveKey)}
        size={sizeTab}
        items={tabItems}
        onTabClick={onTabClickFnc}
      />
    </TabsStyled>
  );
};

Tabs.protoType = {
  tab: PropTypes.array,
  activeKey: PropTypes.string,
  handleTabClick: PropTypes.func.isRequired,
};

export default Tabs;
