/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
  TableHead,
  TextField,
  MenuItem,
  FormControl,
  Select,
  Chip,
} from '@material-ui/core';
import {GET, POST, APPROVE_CONTACT_ONLINE, GET_CHECK_ICLOUD_BEFORE_APPROVE} from '../../services/index';
import {useParams, Link} from 'react-router-dom';
import {ArrowBack, Edit, CheckCircle, Cancel, Label} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import {auth} from '../../utils/firebase';
import {useAuthState} from '../../utils/hook';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useContract} from '../../hook/useContract';
import {useBranch} from '../../hook/useBranch';
import Wrapper from '../../views/wrapper/Wrapper';
import {Div, Background, Button, TextBold} from './styled-component';

import Loading from '../../components/loading/Loading';
import Modal from '../../components/modal';
import ChipStatus from '../../components/chip/ChipContractStatus';
import {Radio, Input, Space, Modal as ModalAntd, Col, Row, Image, Table as TableAntd, Typography} from 'antd';
import noImage from '../../assets/images/no_image.jpeg';
import {saveAs} from 'file-saver';
import {
  DetailContractColumn,
  DetailPackageByContractColumn,
  DetailInstallmentByContractColumn,
  OtherPaymentColumn,
} from '../../constants/global/columnTableForm';
import moment from 'moment';
import {convertStrToFormat, encryptString} from '../../functions/fn';
import {uploadContractImg} from '../../utils/auto-admin/api';
import {INSERT_OTHER_IMAGE} from '../../services/index';
import {color} from '../../resources/color';
import {ModalUpdateTypeLock, ModalHistoryTypeLock, ModalEditRemark} from './components';
import {APP_URL} from '../../env';
import {ButtonTheme, DatatableLog, Spinners} from '../../components';
import {useGetHistory, useUpdateTagChatconeByProcess, useSendChatconeByProcess} from '../../hook/chatcone';
import {ChatconeContractHistoryColumn} from './columns/ChatconeContractHistoryColumn';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {useLocation} from 'react-router-dom';
import SeconHandType from './components/SeconHandType';
import {canDownloadContract} from './utils/utils';
import {getTokenStorage} from './utils/utils';
import GenSignedLink from './components/GenSignedLink';
import {DatatableCouponDetail} from '../customer2/create-contract/components';
import {Icon} from '../../resources/icon';

const useStyles = makeStyles({
  icon_btn: {
    cursor: 'pointer',
    '&.save': {
      color: '#01D827',
    },
    '&.save:hover': {
      color: '#01B927',
    },
    '&.cancel': {
      color: '#E01E01',
    },
    '&.edit': {
      color: '#999999',
    },
  },
});

const {Text} = Typography;

const getError = (error) => {
  switch (error) {
    case 'cannot update new_first_date after transaction date':
      return 'ไม่สามารถกำหนดวันที่ที่เกินวันชำระงวดแรก';
    case 'contract is not active':
      return 'สัญญาไม่ได้เปิดใช้งาน';
    case 'contract is terminated already':
      return 'สัญญาถูกยกเลิกแล้ว';
    default:
      return error;
  }
};

const getTypeLock = (typeLock) => {
  switch (typeLock) {
    case 'MDM':
      return 'MDM';
    case 'ICLOUD':
      return 'iCloud';
    case 'MDMICLOUD':
      return 'MDM+iCloud';
    default:
      return '-';
  }
};

const ContractInfo = () => {
  const {
    contractInfo,
    contractExport,
    contractReciveExport,
    contractUpdate,
    dispatchGetContractInfo,
    dispatchExportContract,
    dispatchExportReciveContract,
    dispatchUpdateContract,
    dispatchTerminatedContract,
    dispatchImpoundedContract,
    dispatchUpdateContractGrade,
  } = useContract();
  const {contractNumber} = useParams();
  const [isEditDate, setIsEditDate] = useState(false);
  const [isEditGrade, setEditGrade] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmImage, setOpenConfirmImage] = useState(false);
  const [openConfirmImageError, setOpenConfirmImageError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openConfirmTerminated, setOpenConfirmTerminated] = useState(false);
  const [openConfirmApprove, setOpenConfirmApprove] = useState(false);

  const [openRemarkTerminated, setOpenRemarkTerminated] = useState(false);
  const [openConfirmImpounded, setOpenConfirmImpounded] = useState(false);

  const [openApproveContract, setOpenApproveContract] = useState(false);
  const [statusApproveContract, setStatusApproveContract] = useState('');
  const [remarkApprove, setRemarkApprove] = useState(contractInfo.info?.app_info?.remarkContractOnline || '');
  const [messageApproveError, setMessageApproveError] = useState('');
  const [loadingApprove, setLoadingApprove] = useState(false);

  const [isEditRemark, setIsEditRemark] = useState(false);
  const [isEditTypeLock, setIsEdiTypeLock] = useState(false);
  const [remark, setRemark] = useState('');
  const [grade, setGrade] = useState('-');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isEditdocumentNo, setIsEditdocumentNo] = useState(false);
  const [documentNo, setDocumentNo] = useState('');
  const [isValidDocNo, setIsValidDocNo] = useState(true);

  const [remarkTerminated, setRemarkTerminated] = useState('');
  const [remarkDetail, setRemarkDetail] = useState('');
  const [messageError, setMessageError] = useState('');
  const [visibleOtherImage, setVisibleOtherImage] = useState(false);
  const {user} = useAuthState(auth);

  const [typeLock, setTypeLock] = useState('DEFAULT');
  const classes = useStyles();
  const otherImages = contractInfo?.info?.app_info?.otherImage;
  const firstImage = otherImages ? otherImages[0] : null;

  const menu_user = JSON.parse(localStorage.getItem('menu_user'));
  const permissions = menu_user.length > 0 && menu_user.filter((el) => el.head === 'Tradesys')[0].menu;

  const perAction = permissions.length > 0 && permissions.filter((el) => el.path === '/contract')[0].actions;

  const {branchList} = useBranch();
  const BRANCH_NAMES = {};
  branchList.forEach((el) => {
    BRANCH_NAMES[el.value] = el.label;
  });

  const [modalUpdateTypeLockVisible, setModalUpdateTypeLockVisible] = useState(false);
  const [modalHistoryTypeLockVisible, setModalHistoryTypeLockVisible] = useState(false);
  const [checkError, setCheckError] = useState(false);

  const {pathname} = useLocation();
  const [getChatconeHistoryFn, getChatconeHistoryData, getChatconeHistoryLoading] = useGetHistory();
  const [updateTagChatconeByProcessFn, updateTagChatconeByProcessLoading] = useUpdateTagChatconeByProcess();
  const [sendChatconeByProcessFn, sendChatconeByProcessLoading] = useSendChatconeByProcess();
  const showSendChatconeBtn = contractInfo?.info?.app_info?.chatcone?.social_id && contractInfo?.info?.app_info?.status === 'ACTIVE';
  const showChatconeHistory = contractInfo?.info?.app_info?.chatcone?.social_id ? true : false;

  const showEditRemark =
    contractInfo.info?.on_app &&
    (contractInfo.info?.app_info?.status === 'ACTIVE' ||
      contractInfo.info?.app_info?.status === 'WAIT_ADMIN_CONFIRM' ||
      contractInfo.info?.app_info?.status === 'WAIT_FINANCE_APPROVE');

  const [remarkFieldName, setRemarkFieldName] = useState('');
  const [modalEditRemarkVisible, setModalEditRemarkVisible] = useState(false);

  //     const BRANCH_NAMES = {CENTER:'CENTER: วงเวียนใหญ่',RS:'RS: รังสิต',KHONKAEN:'KK: ขอนแก่น',CHONBURI:'CB: ชลบุรี',PHITSANULOK:'PL: พิษณุโลก'
  // ,CHIANGMAI:"CM: เชียงใหม่",FWG :"FWG: โฟกัส เซ็นทรัลเวสเกต",FBKP :"FBKP: โฟกัส บางกะปิ"}

  useEffect(() => {
    if (checkError && contractUpdate?.error) {
      setCheckError(false);
      ModalAntd.error({
        title: contractUpdate?.error,
      });
    }
  }, [contractUpdate?.loading]);

  useEffect(() => {
    if (contractInfo.info?.app_info?._id && showChatconeHistory) {
      getChatconeHistoryFn({ref_id: contractInfo.info?.app_info?._id, type: 'COMPLETE_CONTRACT'});
    }
  }, [contractInfo.info?.app_info?._id]);

  useEffect(() => {
    dispatchGetContractInfo(contractNumber);
    if (contractInfo.info?.app_info?.remarkContractOnline) {
      setRemarkApprove(contractInfo.info?.app_info?.remarkContractOnline);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractInfo.info?.app_info?.remarkContractOnline]);

  useEffect(() => {
    if (contractUpdate?.message === 'terminated contract success' && contractInfo?.info?.app_info?.chatcone?.social_id) {
      // CHATCONE: 6.2 ยกเลิกสัญญา
      onUpdateTagChatconeByProcess('TERMINATED_CONTRACT');
    }
  }, [contractUpdate?.message]);

  useEffect(() => {
    if (contractUpdate?.status === 'PENDING') {
      ModalAntd.confirm({
        title: 'ยืนยัน',
        icon: <Icon.warningAlert />,
        content: (
          <>
            <div>เนื่องจากสัญญามี transaction ที่มาจากการทำสัญญา turn หากกดยกเลิกสัญญา ระบบจะทำการยกเลิก transaction อัตโนมัติ</div>
            <div>ต้องการยกเลิกสัญญาหรือไม่?</div>
          </>
        ),
        onOk() {
          terminated(false);
        },
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
      });
    }
  }, [contractUpdate?.status]);

  const downloadContract = () => {
    if (contractInfo.info?.app_info?.pdfContract) {
      saveAs(contractInfo.info?.app_info?.pdfContract, contractNumber);
    } else {
      dispatchExportContract(contractNumber);
    }
  };

  const downloadContractPurchaseDeal = async () => {

    const showContractNo = !['INSTALLMENT', 'REFINANCE'].includes(contractInfo.info?.app_info?.type) ? contractInfo.info?.app_info?.document_no : contractNumber

    if (contractInfo.info?.app_info?.purchaseDealPDF) {
      saveAs(contractInfo.info?.app_info?.purchaseDealPDF, showContractNo);
    } else {
      ModalAntd.error({
        title: 'ไม่พบไฟล์ใบสัญญาซื้อขาย',
      });
    }
  };

  const downloadReciveContract = () => {
    const data = {
      contract_no_main: contractInfo.info?.app_info?.ref_contract_no?.main || contractNumber,
      contract_no_additional: contractInfo.info?.app_info?.ref_contract_no?.additional || contractNumber,
    };

    dispatchExportReciveContract(data);
  };

  const editContract = () => {
    setIsEditDate(true);
  };

  const editGrade = () => {
    setEditGrade(true);
    setGrade(contractInfo.info?.app_info?.grade);
  };

  const onChangeGrade = (e) => {
    setGrade(e.target.value);
  };

  const updateGrade = () => {
    dispatchUpdateContractGrade(contractNumber, {grade});
    cancelEditContract();
    setOpenConfirm(false);
    cancelEditGrade();
  };

  const cancelEditGrade = () => {
    setEditGrade(false);
  };

  const cancelEditContract = () => {
    setIsEditDate(false);
  };

  const onChangeDate = (date) => {
    setSelectedDate(date);
  };

  const editRemark = () => {
    setIsEditRemark(true);
  };

  const editDocumentNo = () => {
    setIsEditdocumentNo(true);
  };

  const cancelEditRemark = () => {
    setIsEditRemark(false);
  };

  const cancelDocumentNo = () => {
    setIsEditdocumentNo(false);
  };

  const onChangeRemark = (event) => {
    setRemark(event.target.value);
  };

  const onChangeDocumentNo = (event) => {
    const value = event.target.value;
    // Remove spaces and check the length
    const cleanedValue = value.replace(/\s+/g, '');
    setDocumentNo(event.target.value);
    setIsValidDocNo(cleanedValue.length === 9);
  };

  const onChangeTypeLock = (event) => {
    setTypeLock(event.target.value);
  };

  const updateContractDownPeriodDate = () => {
    dispatchUpdateContract(contractNumber, {new_first_date: selectedDate.toISOString(), include_down_period: true});
    cancelEditContract();
    setOpenConfirm(false);
    cancelEditRemark();
  };

  const updateRemark = () => {
    if (remark !== '') {
      dispatchUpdateContract(contractNumber, {remark});
      cancelEditContract();
      setOpenConfirm(false);
      cancelEditRemark();
    }
  };

  const updateDocumentNo = () => {
    if (documentNo !== '') {
      const documentNoRex = documentNo.replace(/\s+/g, '');
      dispatchUpdateContract(contractNumber, {documentNo: documentNoRex});
      setOpenConfirm(false);
      cancelDocumentNo();
      setCheckError(true);
    }
  };

  const updateTypeLock = () => {
    if (typeLock !== 'DEFAULT') {
      dispatchUpdateContract(contractNumber, {type_lock: typeLock});
      cancelEditContract();
      setOpenConfirm(false);
      setIsEdiTypeLock(false);
    }
  };

  // const terminated = () => {
  //     dispatchTerminatedContract(contractNumber);
  //     setOpenConfirmTerminated(false);
  // };

  const terminated = (confirmTransactionTurnDown = true) => {
    let remark = remarkTerminated;

    if (remarkTerminated === 'อื่นๆ') {
      remark = remarkDetail;
    }
    //const data = {remarkTerminated: remark};
    const data = {
      contract_no_main: contractInfo.info?.app_info?.ref_contract_no?.main || contractNumber,
      contract_no_additional: contractInfo.info?.app_info?.ref_contract_no?.additional || contractNumber,
      remarkTerminated: remark,
      type: contractInfo.info?.app_info?.type,
      confirmTransactionTurnDown: confirmTransactionTurnDown
    };

    dispatchTerminatedContract(contractNumber, data);
    setOpenConfirmTerminated(false);
    setOpenRemarkTerminated(false);
  };

  const onChangeRemarkTerminated = (e, ref) => {
    if (ref === 'remark') {
      setRemarkTerminated(e.target.value);
    } else if (ref === 'detail') {
      setRemarkDetail(e.target.value);
    }

    setMessageError('');
  };
  const onCancelRemarkTerminated = () => {
    setRemarkTerminated('');
    setMessageError('');
    setOpenRemarkTerminated(false);
  };

  const onOkRemarkTerminated = () => {
    if (!remarkTerminated) {
      setMessageError('กรุณาเลือกสาเหตุการยกเลิก');
    } else {
      if (remarkTerminated === 'อื่นๆ') {
        if (!remarkDetail) {
          setMessageError('กรุณาระบุ');
        } else {
          setOpenConfirmTerminated(true);
        }
      } else {
        setOpenConfirmTerminated(true);
      }
    }
  };

  const onChangeApproveContract = (e, ref) => {
    if (ref === 'status') {
      setStatusApproveContract(e.target.value);
    } else if (ref === 'remark') {
      setRemarkApprove(e.target.value);
    }

    setMessageApproveError('');
  };

  const onOkApproveContract = () => {
    if (!statusApproveContract) {
      setMessageApproveError('กรุณาเลือกอย่างใดอย่างหนึ่ง');
    } else {
      if (statusApproveContract === 'N') {
        if (!remarkApprove) {
          setMessageApproveError('กรุณาระบุ');
        } else {
          setOpenConfirmApprove(true);
        }
      } else {
        setOpenConfirmApprove(true);
      }
    }
  };

  const onOpenApproveContract = async () => {
    if (contractInfo?.info?.app_info?.registStatus !== 'SUCCESS' && !['INSTALLMENT', 'REFINANCE'].includes(contractInfo?.info?.app_info?.type)) {
      ModalAntd.error({
        title: 'กรุณาเซ็นสัญญาออนไลน์ก่อนการพิจารณาอนุมัติสัญญา',
      });
      return;
    }
    try {
      if (contractInfo?.info?.app_info?.mail) {
        setLoadingApprove(true);
        /* เช็คอีเมล์ ถ้า สถานะไอคลาวน์ = รอสมัครไอคลาวน์ แสดง alert เตือน */
        const res = await GET(GET_CHECK_ICLOUD_BEFORE_APPROVE(contractInfo?.info?.app_info?.mail));
        const {success} = res;
        if (success) {
          setOpenApproveContract(true);
        }
      } else {
        setOpenApproveContract(true);
      }
    } catch (error) {
      const {message} = error;
      ModalAntd.error({
        title: message,
      });
    } finally {
      setLoadingApprove(false);
    }
  };

  const onOpenApproveImage = () => {
    setOpenConfirmImage(true);
  };

  const onCancelRemarkApprove = () => {
    setStatusApproveContract('');
    setMessageApproveError('');
    setOpenApproveContract(false);
  };

  const impounded = () => {
    dispatchImpoundedContract(contractNumber);
    setOpenConfirmImpounded(false);
  };

  const openUpdateContract = () => {
    setOpenConfirm(true);
  };

  const approveContract = async () => {
    setOpenConfirmApprove(false);
    setLoadingApprove(true);
    const obj = {
      contractNo: contractNumber,
      statusApprove: statusApproveContract,
      remarkContractOnline: remarkApprove,
      userLogin: user.email,
    };

    try {
      let res = await POST(APPROVE_CONTACT_ONLINE, obj);
      const {success, message} = res;
      if (success) {
        ModalAntd.success({
          title: message,
          afterClose: () => {
            setOpenApproveContract(false);

            setStatusApproveContract(null);
            setRemarkApprove('');
            dispatchGetContractInfo(contractNumber);
          },
        });
        setLoadingApprove(false);

        /* CHATCONE: 6.1 สร้างสัญญาสำเร็จ (E-Contract) INSTALLMENT */
        if (contractInfo?.info?.app_info?.type === 'INSTALLMENT' && contractInfo?.info?.app_info?.chatcone?.social_id) {
          const setInput = {
            ref_id: contractNumber,
            process_type: 'COMPLETE_CONTRACT',
            route_from: `${APP_URL}${pathname}`,
          };

          await updateTagChatconeByProcessFn({...setInput});
        }
      }
    } catch (err) {
      const {message} = err;
      ModalAntd.error({
        title: message,
      });
      setLoadingApprove(false);
    }
  };

  const convertContractType = (type) => {
    let result = '';
    switch (type) {
      case 'INSTALLMENT':
        result = 'ผ่อนสินค้า';
        break;
      case 'REFINANCE':
        result = 'เทิร์น1';
        break;
      case 'TURN1':
        result = 'เทิร์น1';
        break;
      case 'TURN2':
        result = 'เทิร์น2';
        break;
      case 'SECONDHAND':
        result = 'ขายขาด';
        break;
      case 'REFINPACKAGE':
        result = 'ไอโฟนแลกเงิน + แพ็กเกจ';
        break;
      default:
        result = '';
        break;
    }
    return result;
  };

  const handleUploadOtherImage = async (file) => {
    try {
      if (contractInfo?.info?.app_info?.otherImage?.length >= 20) {
        setErrorMessage('ไม่สามารถอัปโหลดรูปภาพเกิน 20 รูปได้');
        setOpenConfirmImageError(true);
        setOpenConfirmImage(false);
      } else {
        const imageUrl = await uploadContractImg(file);

        const obj = {
          contractNo: contractNumber,
          urlImage: imageUrl,
        };
        const res = await POST(INSERT_OTHER_IMAGE, obj);
        window.location.reload();
      }
    } catch (error) {
      console.error('Uploaded failed:', error);
      setErrorMessage('เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ');
    }
  };

  const handleFileChange = (event) => {
    // ดึงข้อมูลไฟล์ที่ผู้ใช้เลือก
    const file = event.target.files[0];

    // เก็บข้อมูลไฟล์ที่ผู้ใช้เลือกลงใน state
    setSelectedFile(file);
  };

  const handleUploadButtonClick = () => {
    // ตรวจสอบว่าผู้ใช้เลือกไฟล์หรือยัง
    if (selectedFile) {
      // เรียกใช้ฟังก์ชัน handleUploadOtherImage โดยส่งข้อมูลไฟล์ที่ผู้ใช้เลือกมา
      handleUploadOtherImage(selectedFile);
    } else {
      console.error('กรุณาเลือกไฟล์รูปภาพก่อนทำการอัปโหลด');
    }
  };

  const redirectCouponContractV2Page = () => {
    const obj = {contractNo: contractNumber};
    const enCryptContractNo = encryptString(JSON.stringify(obj));
    localStorage.setItem('contarctNoCoupon', enCryptContractNo);
    window.location.assign('/v2/coupon/contract');
  };

  const openConfirmSendChatcone = () => {
    ModalAntd.confirm({
      title: 'ยืนยันการส่งแจ้งเตือน',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการส่งแจ้งเตือนไปยังแชทของลูกค้า?',
      onOk() {
        onSendChatconeByProcess('COMPLETE_CONTRACT');
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  // CHATCONE: 6.2 ยกเลิกสัญญา
  const onUpdateTagChatconeByProcess = async (processType) => {
    const setInput = {
      process_type: processType,
      ref_id: contractNumber,
      route_from: `${APP_URL}${pathname}`,
    };

    await updateTagChatconeByProcessFn({...setInput});
    await getChatconeHistoryFn({ref_id: contractInfo.info?.app_info?._id, type: 'COMPLETE_CONTRACT'});
  };

  // CHATCONE: 6.3 ส่งแจ้งเตือนไปยังแชท
  const onSendChatconeByProcess = async (processType) => {
    const setInput = {
      process_type: processType,
      ref_id: contractNumber,
      route_from: `${APP_URL}${pathname}`,
    };

    await sendChatconeByProcessFn({...setInput});
    await getChatconeHistoryFn({ref_id: contractInfo.info?.app_info?._id, type: 'COMPLETE_CONTRACT'});
  };

  const onSetModalEditRemark = async (name) => {
    setRemarkFieldName(name);
    setModalEditRemarkVisible(true);
  };

  // console.log('contractInfo +>>>>>>', contractInfo);

  return (
    <Wrapper>
      {(getChatconeHistoryLoading || updateTagChatconeByProcessLoading || sendChatconeByProcessLoading || contractUpdate?.loading) ? <Spinners /> : null}
      <ModalAntd
        title="อนุมัติสัญญา"
        open={openApproveContract}
        onOk={onOkApproveContract}
        onCancel={onCancelRemarkApprove}
        width={contractInfo.info?.app_info?.type !== 'INSTALLMENT' ? '1100px' : '560px'}
        footer={
          (perAction.includes('approve') && [
            <Button key="back" className="btn-cancel" onClick={onCancelRemarkApprove}>
              ยกเลิก
            </Button>,
            <Button key="submit" className="btn-submit" onClick={onOkApproveContract}>
              ยืนยัน
            </Button>,
          ]) ||
          null
        }>
        <Row justify="center">
          <Col style={{marginRight: '12px'}}>
            <p>ลายเซ็นลูกค้า</p>
            <Image src={contractInfo.info?.app_info.imageSignature || noImage} style={{width: '230px', height: '150px'}} />
          </Col>
          <Col style={{marginRight: '12px'}}>
            <p>รูปบัตรประชาชน</p>
            <Image src={contractInfo.info?.app_info.imageFrontIdCard || noImage} style={{width: '230px', height: '150px'}} />
            <p>ชื่อบัตรประชาชน : {contractInfo.info?.contract.customer_name}</p>
            <p>วันที่บัตรหมดอายุ : {contractInfo.info?.app_info.expiredDateIdCard}</p>
          </Col>
          {contractInfo.info?.app_info?.type !== 'INSTALLMENT' && (
            <>
              {contractInfo.info?.app_info?.imageBookBank && (
                <Col style={{marginRight: '12px'}}>
                  <p>รูป Bookbank</p>
                  <Image src={contractInfo.info?.app_info.imageBookBank || noImage} style={{width: '230px', height: '150px'}} />
                  <p>
                    ชื่อบัญชี : {contractInfo.info?.app_info?.bookBankName}
                    <br /> เลขที่บัญชี : {contractInfo.info?.app_info?.bookBankNo}
                    <br /> ธนาคาร : {contractInfo.info?.app_info?.bankName}
                    <br /> สาขา : {contractInfo.info?.app_info?.branchBankName}
                  </p>
                </Col>
              )}
              {contractInfo.info?.app_info?.imageNameChangeCertificate && (
                <Col>
                  <p>รูปใบเปลี่ยนชื่อ</p>
                  <Image src={contractInfo.info?.app_info.imageNameChangeCertificate || noImage} style={{width: '230px', height: '150px'}} />
                </Col>
              )}
            </>
          )}
        </Row>

        {!!contractInfo.info?.app_info?.imageNameChangeCertificate && contractInfo.info?.app_info?.type !== 'INSTALLMENT' ? (
          <p style={{color: 'red', marginTop: '1rem', marginLeft: '300px'}}>* กรุณาตรวจสอบใบเปลี่ยนชื่อกรณีชื่อหน้าสมุดบัญชีไม่ตรงกับบัตรประชาชน</p>
        ) : (
          <></>
        )}

        {perAction.includes('approve') && (
          <div style={{textAlign: 'center', marginTop: 20}}>
            <Radio.Group onChange={(e) => onChangeApproveContract(e, 'status')} value={statusApproveContract}>
              <Space direction="horizontal">
                <Radio value={'Y'}>อนุมัติ</Radio>
                <Radio value={'N'}>ไม่อนุมัติ</Radio>
              </Space>
            </Radio.Group>
          </div>
        )}
        {statusApproveContract === 'N' ? (
          <div>
            <p>
              <span style={{color: 'red'}}>* </span>หมายเหตุ
            </p>
            <Input.TextArea rows={2} placeholder="โปรดระบุ" onChange={(e) => onChangeApproveContract(e, 'remark')} defaultValue={remarkApprove} />
          </div>
        ) : null}
        <div>
          <span style={{color: 'red'}}>{messageApproveError}</span>
        </div>
      </ModalAntd>

      <ModalAntd title="สาเหตุการยกเลิก" open={openRemarkTerminated} onOk={onOkRemarkTerminated} onCancel={onCancelRemarkTerminated}>
        <Radio.Group onChange={(e) => onChangeRemarkTerminated(e, 'remark')} value={remarkTerminated}>
          <Space direction="vertical">
            <Radio value={'เลือกสินค้าผิด'}>เลือกสินค้าผิด</Radio>
            <Radio value={'ระบุราคา/เงินดาวน์/งวด ผิด'}>ระบุราคา/เงินดาวน์/งวด ผิด</Radio>
            <Radio value={'เลือกประเภทสัญญาผิด'}>เลือกประเภทสัญญาผิด</Radio>
            <Radio value={'อื่นๆ'}>อื่นๆ </Radio>
          </Space>
        </Radio.Group>
        {remarkTerminated === 'อื่นๆ' ? (
          <Input.TextArea rows={2} placeholder="โปรดระบุ" onChange={(e) => onChangeRemarkTerminated(e, 'detail')} />
        ) : null}
        <div>
          <span style={{color: 'red'}}>{messageError}</span>
        </div>
      </ModalAntd>

      <Modal
        open={contractExport.loading || contractUpdate.loading || loadingApprove || contractReciveExport.loading}
        noButton
        heightAuto
        style={{backgroundColor: 'unset', boxShadow: 'unset'}}>
        <Loading />
      </Modal>

      <Modal open={openConfirmApprove} setOpen={setOpenConfirmApprove} heightAuto handleSubmit={approveContract} style={{padding: '30px'}}>
        ต้องการยืนยันข้อมูลใช่หรือไม่?
      </Modal>
      <Modal open={openConfirm} setOpen={setOpenConfirm} heightAuto handleSubmit={updateContractDownPeriodDate} style={{padding: '30px'}}>
        ต้องการอัพเดทสัญญา?
      </Modal>
      <Modal open={openConfirmImage} setOpen={setOpenConfirmImage} heightAuto handleSubmit={handleUploadButtonClick} style={{padding: '30px'}}>
        ต้องการอัพโหลดรูปใช่หรือไม่
      </Modal>
      <Modal
        open={openConfirmImageError}
        setOpen={setOpenConfirmImageError}
        error={errorMessage}
        heightAuto
        handleSubmit={null}
        style={{padding: '30px'}}
      />
      <Modal open={openConfirmTerminated} setOpen={setOpenConfirmTerminated} heightAuto handleSubmit={()=>terminated(true)} style={{padding: '30px'}}>
        {contractInfo.info?.app_info?.type === 'REFINPACKAGE' ? (
          <>
            <p>{'เป็นสัญญาแบบรีไฟแนนซ์+ของแถมการยกเลิกจะถูกยกเลิกทั้งสินค้าหลัก/สินค้าเสริม'}</p>{' '}
            <p style={{textAlign: 'center'}}>{'ต้องการยกเลิกสัญญา?'}</p>
          </>
        ) : (
          <p>{'ต้องการยกเลิกสัญญา?'}</p>
        )}
      </Modal>
      <Modal open={openConfirmImpounded} setOpen={setOpenConfirmImpounded} heightAuto handleSubmit={impounded} style={{padding: '30px'}}>
        ต้องการยึดสัญญา?
      </Modal>
      <h1>
        {contractInfo.info?.contract.promisenumber} <ChipStatus status={contractInfo.info?.on_app} />
        &nbsp;
        {contractInfo.info?.app_info?.type === 'REFINPACKAGE' && (
          <Chip
            label={contractInfo.info?.app_info?.className}
            style={
              contractInfo.info?.app_info?.class === 'MAIN'
                ? {color: '#007bff', backgroundColor: '#007bff29'}
                : {color: '#F5B813', backgroundColor: 'rgba(245, 184, 19, 0.15)'}
            }
          />
        )}
      </h1>

      <Div>
        <Grid container item>
          <Background>
            <Link to="/contract">
              <ArrowBack /> Back
            </Link>
            {!contractInfo.info && !contractInfo.loading && <p>ไม่พบสัญญา</p>}
            {contractInfo.info && (
              <div style={{marginTop: '1rem'}}>
                <Row gutter={24}>
                  <Col className="gutter-row" span={12} style={{backgroundColor: '#f4f6f9', padding: '12px', borderRadius: '12px'}}>
                    <div>
                      <div style={{marginBottom: '12px', display: 'flex', gap: '12px'}}>
                        {/* ถ้ามีไฟล์ PDF สัญญาซื้อขายและสามารถดาวน์โหลดสัญญาได้ (สำหรับประเภท REFINANCE, TURN1, TURN2) */}
                        {contractInfo.info?.app_info?.purchaseDealPDF &&
                          canDownloadContract(contractInfo.info, ['REFINANCE', 'TURN1', 'TURN2']) && (
                            <Button
                              className="export"
                              style={{backgroundColor: '#41cd00', color: 'white'}}
                              onClick={() => downloadContractPurchaseDeal()}>
                              ดาวน์โหลดใบสัญญาซื้อขาย
                            </Button>
                          )}
                        {/* ถ้าสามารถดาวน์โหลดสัญญาได้ (สำหรับประเภท INSTALLMENT, REFINANCE, TURN1, TURN2) */}
                        {canDownloadContract(contractInfo.info, ['INSTALLMENT', 'REFINANCE', 'TURN1', 'TURN2']) && (
                          <Button className="export" onClick={() => downloadContract()}>
                            ดาวน์โหลดใบสัญญา
                          </Button>
                        )}
                      </div>
                      <p style={{fontWeight: '800'}}>
                        สถานะ:&nbsp;&nbsp;
                        <span
                          style={{
                            color:
                              contractInfo.info?.app_info?.status === 'COMPLETED' || contractInfo.info?.app_info?.status === 'ACTIVE'
                                ? 'green'
                                : '#000',
                          }}>
                          {contractInfo.info?.app_info?.statusName || contractInfo.info?.contract?.statuscase}
                        </span>
                        {['MAIN', 'ADDITIONAL'].includes(contractInfo.info?.app_info?.class) &&
                          ['WAIT_ADMIN_CONFIRM', 'ACTIVE', 'WAIT_FINANCE_APPROVE'].includes(contractInfo.info?.app_info?.status) &&
                          contractInfo.info?.app_info?.type === 'REFINPACKAGE' && (
                            <Button style={{marginLeft: '12px'}} className="load-btn" onClick={() => downloadReciveContract()}>
                              ดาวน์โหลดใบส่งสินค้า
                            </Button>
                          )}
                        {contractInfo.info?.app_info?.status === 'WAIT_ADMIN_CONFIRM' && (
                          <Button style={{marginLeft: '12px'}} className="wait-approve" onClick={() => onOpenApproveContract()}>
                            รอตรวจสอบ
                          </Button>
                        )}
                      </p>
                      <p>ชื่อผู้สร้างสัญญา: {contractInfo.info?.app_info?.admin_created?.admin_created_name}</p>
                      <p>วันที่สร้างสัญญา: {contractInfo.info?.contract?.tdate}</p>
                      {contractInfo.info?.app_info?.status === 'TERMINATED' && contractInfo.info?.app_info?.remarkTerminated && (
                        <div>
                          <p>สาเหตุการยกเลิก: {contractInfo.info?.app_info?.remarkTerminated}</p>
                          <p>
                            ชื่อผู้ยกเลิกสัญญา: {contractInfo.info?.app_info?.admin_terminated?.admin_terminated_name} ยกเลิกวันที่:{' '}
                            {moment.utc(contractInfo.info?.app_info?.terminated_date).format('DD/MM/YYYY HH:mm')}
                          </p>
                        </div>
                      )}
                      <p>ชื่อ: {contractInfo.info?.contract?.customer_name}</p>
                      <p>เบอร์โทร: {contractInfo.info?.contract?.telephone}</p>
                      <p>เลขบัตรประชาชน: {contractInfo.info?.contract?.idcard}</p>
                      <p>ที่อยู่: {contractInfo.info?.contract?.customer_address}</p>

                      {contractInfo.info?.app_info?.type === 'SECONDHAND' && <SeconHandType contractInfo={contractInfo} />}

                      <p>สาขา: {BRANCH_NAMES[contractInfo.info?.app_info?.branch]}</p>
                      <div style={{display: 'flex'}}>
                        <p style={{paddingTop: '10px'}}>หมายเหตุ1: {contractInfo.info?.app_info?.remark}</p>
                        {showEditRemark && (
                          <Button onClick={() => onSetModalEditRemark('remark')}>
                            <Edit className={`edit ${classes.icon_btn}`} />
                          </Button>
                        )}
                      </div>

                      {contractInfo.info?.app_info?.type !== 'SECONDHAND' && (
                        <>
                          <div style={{display: 'flex'}}>
                            <p style={{paddingTop: '10px'}}>หมายเหตุ2: {contractInfo.info?.app_info?.remark2}</p>
                            {showEditRemark && (
                              <Button onClick={() => onSetModalEditRemark('remark2')}>
                                <Edit className={`edit ${classes.icon_btn}`} />
                              </Button>
                            )}
                          </div>

                          {isEditGrade ? (
                            <div style={{display: 'flex'}}>
                              <p>เกรดหลังทำสัญญา:</p>
                              <Select style={{marginLeft: '1rem', width: '10rem'}} value={grade} onChange={onChangeGrade}>
                                <MenuItem value="-">-</MenuItem>
                                <MenuItem value="A">A (0-5)</MenuItem>
                                <MenuItem value="B">B (6-15)</MenuItem>
                                <MenuItem value="C">C (16-59)</MenuItem>
                                <MenuItem value="D">D (60+)</MenuItem>
                              </Select>
                              <Button onClick={() => cancelEditGrade()}>
                                <Cancel className={`cancel ${classes.icon_btn}`} />
                              </Button>
                              <Button onClick={() => updateGrade()}>
                                <CheckCircle className={`save ${classes.icon_btn}`} />
                              </Button>
                            </div>
                          ) : (
                            <div style={{display: 'flex'}}>
                              <p style={{paddingTop: '10px'}}>เกรดสัญญา: {contractInfo.info?.app_info?.grade || '-'}</p>
                              {contractInfo.info?.on_app && contractInfo.info?.app_info?.status === 'ACTIVE' && (
                                <Button onClick={() => editGrade()}>
                                  <Edit className={`edit ${classes.icon_btn}`} />
                                </Button>
                              )}
                            </div>
                          )}
                          <p>
                            เงินดาวน์:{' '}
                            {contractInfo.info?.app_info?.down_amount
                              ? new Intl.NumberFormat('th-TH').format(contractInfo.info?.app_info?.down_amount)
                              : '-'}
                          </p>
                          {isEditDate ? (
                            <div style={{display: 'flex'}}>
                              <div>{contractInfo?.info?.app_info?.type === 'INSTALLMENT' ? <p>วันที่ดาวน์วันแรก:</p> : <p>วันที่ชำระงวดแรก:</p>}</div>
                              <div>
                                <DatePicker dateFormat={'dd/MM/yyyy'} selected={selectedDate} onChange={onChangeDate} style={{marginLeft: '1rem'}} />
                              </div>
                              <Button onClick={() => cancelEditContract()}>
                                <Cancel className={`cancel ${classes.icon_btn}`} />
                              </Button>
                              <Button onClick={() => openUpdateContract()}>
                                <CheckCircle className={`save ${classes.icon_btn}`} />
                              </Button>
                            </div>
                          ) : (
                            <div style={{display: 'flex'}}>
                              {contractInfo?.info?.app_info?.type === 'INSTALLMENT' ? (
                                <p style={{paddingTop: '10px'}}>วันที่ดาวน์วันแรก: {contractInfo.info?.contract?.downfirstdate}</p>
                              ) : (
                                <p style={{paddingTop: '10px'}}>วันที่ชำระงวดแรก: {contractInfo.info?.contract?.firstdate}</p>
                              )}
                              {contractInfo.info?.can_update &&
                                !isEditDate &&
                                (contractInfo.info?.app_info?.status === 'WAIT_SIGNATURE' ||
                                  contractInfo.info?.app_info?.status === 'WAIT_ADMIN_CONFIRM' ||
                                  contractInfo.info?.app_info?.status === 'WAIT_FINANCE_APPROVE' ||
                                  contractInfo.info?.app_info?.status === 'ACTIVE') && (
                                  <Button onClick={() => editContract()}>
                                    <Edit className={`edit ${classes.icon_btn}`} />
                                  </Button>
                                )}
                            </div>
                          )}
                          <p>จำนวนงวดดาวน์: {contractInfo.info?.contract?.downperiod}</p>

                          <div style={{display: 'flex'}}>
                            <p>รูปแบบการล็อคเครื่อง: {getTypeLock(contractInfo.info?.app_info?.type_lock)}</p>
                            {contractInfo.info?.on_app && contractInfo.info?.app_info?.status === 'ACTIVE' && (
                              <Button onClick={() => setModalUpdateTypeLockVisible(true)}>
                                <Edit className={`edit ${classes.icon_btn}`} />
                              </Button>
                            )}
                          </div>

                          {contractInfo.info?.app_info?.type_lock !== 'ICLOUD' && (
                            <>
                              <p>ผู้ให้บริการ MDM: {contractInfo.info?.app_info?.mdm_provider_name}</p>
                              <p>MDM Site: {contractInfo.info?.app_info?.mdm_site_name}</p>
                            </>
                          )}

                          <Space size={[20, 8]} wrap>
                            <p>ประวัติการแก้ไขรูปแบบการล็อคเครื่อง: </p>
                            <ButtonTheme
                              title={'ประวัติการแก้ไข'}
                              style={{marginTop: '-8px', display: true, borderColor: 'transparent', backgroundColor: color.success}}
                              useFor={'CUSTOM'}
                              onClick={() => setModalHistoryTypeLockVisible(true)}></ButtonTheme>
                          </Space>

                          <p>อีเมล์ Icloud: {contractInfo.info?.app_info?.mail}</p>
                          <p>สถานะไอคลาวน์: {contractInfo.info?.app_info?.icloud_status_th}</p>

                          {isEditdocumentNo ? (
                            <div style={{display: 'flex'}}>
                              <p>เลขที่เอกสารรับซื้อ:</p>
                              <TextField
                                onChange={onChangeDocumentNo}
                                style={{marginLeft: '1rem'}}
                                defaultValue={contractInfo.info?.app_info?.document_no}
                                error={!isValidDocNo}
                                helperText={!isValidDocNo ? ' ต้องมีความยาว 9 ตัวอักษรพอดี' : ''}
                              />
                              <Button onClick={() => cancelDocumentNo()}>
                                <Cancel className={`cancel ${classes.icon_btn}`} />
                              </Button>
                              <Button onClick={() => updateDocumentNo()}>
                                <CheckCircle className={`save ${classes.icon_btn}`} />
                              </Button>
                            </div>
                          ) : (
                            <div style={{display: 'flex'}}>
                              <p style={{paddingTop: '10px'}}>เลขที่เอกสารรับซื้อ: {contractInfo.info?.app_info?.document_no}</p>
                              {(contractInfo.info?.app_info?.status === 'ACTIVE' ||
                                contractInfo.info?.app_info?.status === 'WAIT_ADMIN_CONFIRM' ||
                                contractInfo.info?.app_info?.status === 'WAIT_FINANCE_APPROVE') && !['TURN1', 'TURN2'].includes(contractInfo?.info?.app_info?.type)  && (
                                <Button onClick={() => editDocumentNo()}>
                                  <Edit className={`edit ${classes.icon_btn}`} />
                                </Button>
                              )}
                            </div>
                          )}
                          <p>ประเภทสัญญา: {convertContractType(contractInfo.info?.app_info?.type)}</p>
                          <p>รูปแบบสัญญา: {contractInfo?.info?.app_info?.contract_type ? contractInfo?.info?.app_info?.contract_type.text : '-'}</p>

                          {(contractInfo.info?.app_info?.class === 'MAIN' || contractInfo.info?.app_info?.class === 'ADDITIONAL') && (
                            <p>
                              สัญญาอ้างอิง:{' '}
                              {contractInfo.info?.app_info?.class === 'MAIN'
                                ? contractInfo.info?.app_info?.ref_contract_no?.additional
                                : contractInfo.info?.app_info?.ref_contract_no?.main}
                            </p>
                          )}
                          <Space size={[20, 8]} wrap>
                            <p>ประวัติการใช้คูปอง: </p>
                            <ButtonTheme
                              title={' ดูรายละเอียด'}
                              style={{marginTop: '-8px', display: true, borderColor: 'transparent', backgroundColor: color.edit}}
                              useFor={'CUSTOM'}
                              onClick={() => redirectCouponContractV2Page()}></ButtonTheme>
                          </Space>
                          <br />

                          <Row gutter={[16, 16]}>
                            <Col span={20}>
                              <DatatableCouponDetail data={contractInfo.info?.app_info?.couponDetailData} />
                            </Col>
                          </Row>

                          <input type="file" onChange={handleFileChange} />
                          <br />
                          <Button
                            style={{marginTop: '12px', display: selectedFile ? 'block' : 'none'}}
                            className="export"
                            onClick={() => onOpenApproveImage()}>
                            อัปโหลดรูปอื่นๆ
                          </Button>
                        </>
                      )}
                      {contractInfo.info?.app_info?.registId && (
                        <>
                          <GenSignedLink registerId={contractInfo.info?.app_info?.registId} status={contractInfo.info?.app_info?.registStatus} />
                        </>
                      )}
                    </div>
                  </Col>
                  <Col className="gutter-row" span={6} style={{height: '100vh'}}>
                    {/* <div style={{ height: '50vh' }}> */}
                    {contractInfo?.info?.image?.url && (
                      <center>
                        <Image width={'auto'} height={'49vh'} style={{borderRadius: 10}} src={contractInfo.info.image.url} />
                        {/* <img style={{ display: 'block', maxWidth: 500 }} alt="" src={contractInfo.info.image.url} /> */}
                      </center>
                    )}
                    {/* </div> */}
                    {/* <div style={{ height: '40vh' }}> */}
                    {contractInfo?.info?.app_info?.imeiImage && (
                      <center>
                        <Image width={'auto'} height={'49vh'} style={{marginTop: 10, borderRadius: 10}} src={contractInfo.info.app_info?.imeiImage} />
                        {/* <img style={{ display: 'block', maxWidth: 500 }} alt="" src={contractInfo.info.image.url} /> */}
                      </center>
                    )}
                    {/* </div> */}
                  </Col>
                  <Col className="gutter-row" span={6} style={{height: '100vh'}}>
                    {/* <div style={{ height: '50vh' }}> */}
                    {contractInfo?.info?.app_info?.machineDetailImage && (
                      <center>
                        <Image width={'auto'} height={'49vh'} style={{borderRadius: 10}} src={contractInfo.info.app_info?.machineDetailImage} />
                        {/* <img style={{ display: 'block', maxWidth: 500 }} alt="" src={contractInfo.info.image.url} /> */}
                      </center>
                    )}
                    {/* </div> */}
                    {/* <div style={{ height: '40vh' }}> */}
                    {typeof otherImages !== 'string' ? (
                      <div style={{position: 'relative'}}>
                        <center>
                          {firstImage && (
                            <Image
                              preview={{
                                visible: false,
                              }}
                              width={'auto'}
                              height={'49vh'}
                              style={
                                contractInfo?.info?.app_info?.otherImage?.length && contractInfo?.info?.app_info?.otherImage?.length > 1
                                  ? {marginTop: 10, borderRadius: 10, opacity: 0.5}
                                  : {marginTop: 10, borderRadius: 10}
                              }
                              src={firstImage}
                              onClick={() => setVisibleOtherImage(true)}
                            />
                          )}
                          <div style={{display: 'none'}}>
                            <Image.PreviewGroup
                              preview={{
                                visible: visibleOtherImage,
                                onVisibleChange: (vis) => setVisibleOtherImage(vis),
                              }}>
                              {otherImages?.map((image, index) => {
                                return <Image style={{width: '100%', height: 'auto', display: 'none'}} key={index} src={image} />;
                              })}
                            </Image.PreviewGroup>
                          </div>
                        </center>
                        {contractInfo?.info?.app_info?.otherImage?.length && contractInfo?.info?.app_info?.otherImage?.length > 1 ? (
                          <div style={{position: 'absolute', top: '45%', left: '45%'}}>
                            <Text style={{fontSize: 50, textShadow: '#000000 10px 0px 20px', color: 'white'}}>
                              +{contractInfo?.info?.app_info?.otherImage?.length}
                            </Text>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <Image style={{width: '100%', height: '49vh'}} src={otherImages} />
                    )}
                    {/* </div> */}
                  </Col>
                </Row>

                <div style={{marginTop: '1rem'}}>
                  <TextBold>รายการประกอบสัญญา</TextBold>
                  {['TURN1', 'TURN2', 'SECONDHAND'].includes(contractInfo.info?.app_info?.type) ? (
                    <div>
                      <p style={{color: 'red', marginTop: '1rem'}}>เงินทอน: {contractInfo.info.app_info?.change_amount} บาท</p>
                      <p>หมายเหตุเงินทอน: {contractInfo.info?.app_info?.remarkChange}</p>
                    </div>
                  ) : contractInfo.info?.app_info?.type !== 'INSTALLMENT' || contractInfo.info?.app_info?.type === 'REFINPACKAGE' ? (
                    <div>
                      <p style={{color: 'red', marginTop: '1rem'}}>วงเงินที่ได้รับ: {contractInfo.info.app_info?.capital_amount} บาท</p>
                      {/* <p style={{ color: 'red', marginTop: '1rem' }}>
                          เงินทอน: {contractInfo.info.app_info?.change_amount} บาท
                        </p> */}
                      <p>หมายเหตุอนุมัติวงเงิน: {contractInfo.info?.app_info?.remarkContractOnline}</p>
                      {/* <p>หมายเหตุเงินทอน: {contractInfo.info?.app_info?.remarkChange}</p> */}
                    </div>
                  ) : null}
                  {(contractInfo.info?.app_info?.class === 'MAIN' && contractInfo.info?.app_info?.type === 'REFINPACKAGE') ||
                  contractInfo.info?.app_info?.type !== 'REFINPACKAGE' ? (
                    <TableContainer>
                      <TableAntd columns={DetailContractColumn()} dataSource={contractInfo.info?.products} pagination={false} />
                    </TableContainer>
                  ) : null}
                </div>
                {contractInfo.info?.app_info?.class === 'ADDITIONAL' && contractInfo.info?.app_info?.type === 'REFINPACKAGE' && (
                  <div style={{marginTop: '1rem'}}>
                    <TextBold>{`แพคเก็จ ${contractInfo.info?.app_info?.package?.package_name}`} </TextBold>
                    <TableContainer>
                      <TableAntd
                        columns={DetailPackageByContractColumn()}
                        dataSource={contractInfo.info?.app_info?.package?.item}
                        pagination={false}
                      />
                    </TableContainer>
                  </div>
                )}

                {contractInfo.info?.app_info?.type !== 'SECONDHAND' && (
                  <div style={{marginTop: '1rem'}}>
                    <Row gutter={[24, 24]}>
                      <Col xs={{span: 24}} md={{span: 24}} xl={{span: 12}} lg={{span: 12}}>
                        <TextBold>ตารางการผ่อนชำระ</TextBold>
                        <TableContainer>
                          <TableAntd columns={DetailInstallmentByContractColumn()} dataSource={contractInfo.info?.periods} pagination={false} />
                        </TableContainer>
                      </Col>
                      <Col xs={{span: 24}} md={{span: 24}} xl={{span: 12}} lg={{span: 12}}>
                        <TextBold>รายการค่าใช้จ่ายอื่นๆ</TextBold>
                        <TableContainer>
                          <TableAntd columns={OtherPaymentColumn()} dataSource={contractInfo.info?.other_payment} pagination={false} />
                        </TableContainer>
                      </Col>
                    </Row>
                  </div>
                )}

                {showSendChatconeBtn && (
                  <div style={{marginTop: '3rem'}}>
                    <ButtonTheme useFor="SEND_NOTI" title="ส่งแจ้งเตือนไปยังแชท" onClick={() => openConfirmSendChatcone()} />
                  </div>
                )}
                {showChatconeHistory && (
                  <DatatableLog
                    style={{marginTop: '0px'}}
                    columns={ChatconeContractHistoryColumn()}
                    data={getChatconeHistoryData}
                    pagination={{pageSize: 5}}
                    showDivider={false}
                  />
                )}

                <div style={{marginTop: '3rem'}}>
                  {contractInfo.info?.on_app && contractInfo.info?.contract?.status === 0 && perAction.includes('cancel') && (
                    <Button className="terminate" onClick={() => setOpenRemarkTerminated(true)}>
                      ยกเลิกสัญญา
                    </Button>
                  )}
                  {contractInfo.info?.on_app && contractInfo.info?.contract?.status === 0 && perAction.includes('cancel') && (
                    <Button className="impound" style={{marginLeft: '2rem'}} onClick={() => setOpenConfirmImpounded(true)}>
                      ยึดสัญญา
                    </Button>
                  )}
                </div>
              </div>
            )}
            <div style={{marginTop: '1rem'}}>
              {contractReciveExport.error && <TextBold className="error">{getError(contractReciveExport.error)}</TextBold>}
              {contractExport.error && <TextBold className="error">{getError(contractExport.error)}</TextBold>}
              {contractUpdate.error && <TextBold className="error">{getError(contractUpdate.error)}</TextBold>}
            </div>
          </Background>
          {contractInfo.loading && <Loading />}
        </Grid>
      </Div>

      <ModalUpdateTypeLock
        visible={modalUpdateTypeLockVisible}
        onClose={() => setModalUpdateTypeLockVisible(false)}
        modalData={{
          contract_no: contractNumber,
          type_lock: contractInfo?.info?.app_info?.type_lock,
          mdm_provider_code: contractInfo?.info?.app_info?.mdm_provider_code,
          mdm_site_code: contractInfo?.info?.app_info?.mdm_site_code,
        }}
        onRefresh={() => dispatchGetContractInfo(contractNumber)}
      />

      <ModalHistoryTypeLock
        visible={modalHistoryTypeLockVisible}
        onClose={() => setModalHistoryTypeLockVisible(false)}
        title={'ประวัติการแก้ไขรูปแบบการล็อคเครื่อง'}
        modalData={{
          contract_no: contractNumber,
        }}
      />

      <ModalEditRemark
        visible={modalEditRemarkVisible}
        onClose={() => setModalEditRemarkVisible(false)}
        modalData={{
          contract_no: contractNumber,
          remarkFieldName,
          remark: contractInfo.info?.app_info?.remark,
          remark2: contractInfo.info?.app_info?.remark2,
          reApi: dispatchGetContractInfo,
        }}
        onRefresh={() => dispatchGetContractInfo(contractNumber)}
      />
    </Wrapper>
  );
};

export default ContractInfo;
