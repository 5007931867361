// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect} from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import {Modal, Card, Spin, Select, Col, Row} from 'antd';
import _ from 'lodash';
import {ContainerButton} from '../../styles/styledComponentGlobal';
import {FileDoneOutlined} from '@ant-design/icons';
import {GET, POST, PUT, GET_OPTION_BRANCH, GET_ITEM_BY_SN_CHECK_STOCK, GET_STOCK_LIST, CONFIRM_STOCK, DELETE_COUNT_DOC} from '../../services/index';
import {auth} from '../../utils/firebase';
import {SearchLayout} from '../../components/pageLayout/SearchLayout';
import {TableLayout} from './../../components/pageLayout/TableLayout';
import {ButtonTheme} from './../../components/buttons/index';
import {FORM_SN_CHECK_STOCK} from '../../constants/global/actionform';
import {useForm} from 'react-hook-form';
import RenderForm from './../../constants/global/RenderForm';
import {CHECK_STOCK_ITEMS} from './../../services/index';
import {useAuthState, useCurrentState} from '../../utils/hook';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {CheckStockSearchForm} from './form';
import {CheckStockColumn} from './column';

const CheckStockScreen = () => {
  let timeout = null;
  const {
    setValue,
    getValues,
    control,
    formState: {errors},
    clearErrors,
    setError,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const {user} = useAuthState(auth);
  const {user: user2} = useCurrentState(auth);
  const [optionBranch, setOptionBranch] = useState([]);
  const [docType, setDocType] = useState('');
  const [branch, setBranch] = useState(null);
  const [serialStatus, setSerialStatus] = useState(null);
  const [disableBranch, setDisableBranch] = useState(false);
  const [disabledBtnConfirm, setDisabledBtnConfirm] = useState(true);
  const [itemTotal, setItemTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [docNo, setDocNo] = useState('');
  const [header, setHeader] = useState({});
  const history = useHistory();

  const [checkStockAppleStatus, setCheckStockAppleStatus] = useState(null);

  // useEffect(() => {
  //   // localStorage.removeItem('checkStockAppleStatus');
  // }, []);

  useEffect(() => {
    if (user2 && user2.branch) {
      setBranch(user2.branch === 'CENTER' ? null : user2.branch);
      getOptionBranch();
    }
  }, [user2]);

  const optionAppleStatus = [
    {label: 'DEP', value: 'DEP'},
    {label: 'Non-DEP', value: 'NON_DEP'},
    {label: 'TBD', value: 'TBD'},
  ];

  const optionSerialStatus = [
    {value: 'READY', label: 'พร้อมขาย'},
    {value: 'NOT_READY', label: 'ไม่พร้อมขาย'},
  ];

  const getStockList = async (data) => {
    try {
      if (!_.isEmpty(data.document_no)) {
        setLoading(true);
        const res = await GET(GET_STOCK_LIST(data.document_no));
        const {success, result} = res;
        if (success) {
          const report = result.report.items.map((el, i) => {
            return {
              ...el,
              index: i + 1,
              appleStatus: el.checkStockAppleStatus,
            };
          });
          // setDataSource(result.report.items)
          setDataSource(report);
          setDocumentData(result.report);
          setBranch(result.report.branch);
          // setSerialStatus(result.report.serial_status);
          setHeader(result.report);
          setDocType(result.report.document_type);
          setDisableBranch(true);
          setDocNo(result.report.document_no);
          setDisabledBtnConfirm(false);
        }
      } else {
        Modal.warning({
          title: 'กรุณากรอกเลขที่เอกสาร',
        });
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setValue('sn', '');
      setLoading(false);
    }
  };

  const getItemBySn = async (sn) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(async () => {
      if (sn !== '') {
        try {
          setLoading(true);
          const res = await GET(GET_ITEM_BY_SN_CHECK_STOCK(sn));
          const {success, result} = res;

          if (!success) return;

          // let local = localStorage.getItem('checkStockAppleStatus');
          const local = checkStockAppleStatus;
          const {item_code, serial_number, in_system} = result.item;
          let updatedDataSource = [...dataSource];

          const existingIndex = updatedDataSource.findIndex((el) => el.serial_number === serial_number);
          const lastIndex = updatedDataSource.length;

          if (existingIndex === -1) {
            let added = false;

            /* prepare data update  */
            updatedDataSource = updatedDataSource.map((el) => {
              if (!added && el.item_code === item_code && !el.serial_number) {
                added = true;
                return {...el, appleStatus: local, serial_status: null, serial_number};
              }
              return el;
            });

            if (!added) {
              updatedDataSource.push({
                serial_number,
                serial_status: null,
                appleStatus: itemTotal === 0 ? '' : local,
                index: lastIndex + 1,
              });
            }

            /* ไม่มีในระบบ */
            if (in_system === false) {
              Modal.confirm({
                title: (
                  <>
                    S/N : <span style={{ color: "#0052BA" }}>{serial_number}</span> ไม่มีในระบบ
                  </>
                ),
                content: `กรุณายืนยัน หากต้องการนับสต็อกสินค้านี้ ?`,
                onOk: () => {
                  setItemTotal(itemTotal + 1);
                  setDataSource(updatedDataSource);
                  setValue('sn', '');
                },
                onCancel() {
                  setLoading(false);
                },
              });
            } else {
              setItemTotal(itemTotal + 1);
              setDataSource(updatedDataSource);
              setValue('sn', '');
            }
          } else {
            Modal.error({title: 'S/N ซ้ำ'});
          }
        } catch (err) {
          Modal.error({title: err.message, content: err.error || ''});
        } finally {
          setLoading(false);
        }

        // try {
        //   setLoading(true);
        //   const res = await GET(GET_ITEM_BY_SN_CHECK_STOCK(sn));
        //   const {success, result} = res;
        //   if (success) {
        //     let local = localStorage.getItem('checkStockAppleStatus');
        //     const {item_code, serial_number, appleStatus} = result.item;

        //     let arr = dataSource;
        //     let index = arr.findIndex((el) => el.serial_number == serial_number);
        //     let lastIndexArr = arr.length;
        //     if (index == -1) {
        //       for (const el of arr) {
        //         if (el.item_code) {
        //           if (!el.serial_number && item_code === el.item_code) {
        //             el.appleStatus = local;
        //             el.serial_status = null;
        //             el.serial_number = serial_number;
        //             setItemTotal(itemTotal + 1);
        //             break;
        //           }
        //         }
        //       }
        //       setDataSource(arr);
        //     } else {
        //       Modal.error({
        //         title: 'S/N ซ้ำ',
        //       });
        //     }

        //     if (arr.findIndex((el) => el.serial_number == serial_number) == -1) {
        //       if (itemTotal === 0) {
        //         arr.push({serial_number, serial_status: null, appleStatus: '', index: itemTotal});
        //       } else {
        //         arr.push({serial_number, serial_status: null, appleStatus: local, index: lastIndexArr + 1});
        //       }
        //       // localStorage.removeItem('checkStockAppleStatus')
        //       setItemTotal(itemTotal + 1);
        //     }

        //     setDataSource([]);
        //     setDataSource(arr);
        //   }
        // } catch (err) {
        //   const {message, error} = err;

        //   console.log('CCCCCCCCcc')
        //   Modal.error({
        //     title: message,
        //     content: error || '',
        //   });
        // } finally {
        //   setValue('sn', '');
        //   setLoading(false);
        // }
      }
    }, 300);
  };

  const getOptionBranch = async () => {
    try {
      setLoading(true);
      let res = await GET(GET_OPTION_BRANCH);
      const {success, result} = res;
      if (success) {
        const bb = user2.branch === 'CENTER' ? result : result.filter((el) => el.value === user2.branch);
        setOptionBranch(bb);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const checkStockItems = async () => {
    try {
      if (!branch) {
        return Modal.warning({
          title: 'กรุณากรอกสาขา',
        });
      }
      setLoading(true);

      let arr_items = [];
      dataSource.forEach((el) => {
        if (el.serial_number) {
          // arr_items.push({ serial_number: el.serial_number, serial_status: el.serial_status, check_stock_apple_status: el.appleStatus })
          arr_items.push({serial_number: el.serial_number, check_stock_apple_status: el.appleStatus});
        }
      });
      let obj = {
        branch,
        // serial_status: serialStatus,
        items: arr_items,
        userLogin: user.email,
        documentNo: header?.document_no || '',
      };

      let res = await POST(CHECK_STOCK_ITEMS, obj);
      const {success, result} = res;
      if (success) {
        const report = result.stock.items.map((el, i) => {
          return {
            ...el,
            index: i + 1,
            appleStatus: el.check_stock_apple_status,
          };
        });
        setDisableBranch(true);
        setDataSource(report);
        // setDataSource(result.stock.items);
        setHeader(result.stock);
        setDocNo(result.stock.document_no);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmStock = async () => {
    try {
      setLoading(true);

      let arr_items = [];

      dataSource.forEach((el) => {
        if (el.serial_number) {
          let tempCheckAppleStatus = '';
          if (!!el.checkStockAppleStatus) {
            tempCheckAppleStatus = el.checkStockAppleStatus;
          } else if (!!el.check_stock_apple_status) {
            tempCheckAppleStatus = el.check_stock_apple_status;
          }
          arr_items.push({serialNumber: el.serial_number, appleStatus: tempCheckAppleStatus});
        }
      });

      let obj = {
        userLogin: user.email,
        documentNo: header?.document_no,
        checkAppleStatuses: arr_items,
      };
      let res = await POST(CONFIRM_STOCK, obj);
      const {success, result} = res;
      if (success) {
        history.push({
          pathname: '/print-pick',
          state: {data: {...result.report, page: 'checkstock', document_no: docNo}},
        });
        // localStorage.removeItem('checkStockAppleStatus');
        setCheckStockAppleStatus(null);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const validateInputSN = (value) => {
    const minLength = 10;
    const pattern = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]*$/;

    if (value.length < minLength) {
      return 'กรุณาระบุค่าอย่างน้อย 10 ตัว';
    }
    if (!pattern.test(value)) {
      return 'กรุณากรอกเฉพาะตัวอักษร A-Z, ตัวเลข และอักขระพิเศษเท่านั้น';
    }
    return null; // Validation passed
  };

  const onChangeInputSN = (value, ref) => {
    setDisabledBtnConfirm(false);

    if (ref === 'sn') {
      const errorMessage = validateInputSN(value);
      if (errorMessage) {
        setError('sn', {type: 'custom', message: errorMessage});
      } else {
        clearErrors('sn');
      }

      setValue(ref, value);
    } else if (ref === 'branch') {
      setBranch(value);
    }
  };

  const handleAddSerialNumber = (value, ref) => {
    const serialNumber = value;

    const errorMessage = validateInputSN(value);
    if (!errorMessage) getItemBySn(serialNumber);
  };

  const detailUser = [
    {
      label: (
        <>
          สาขา<span style={{color: 'red'}}> *</span> :{' '}
        </>
      ),
      value: (
        <Select
          placeholder="กรุณาเลือกสาขา"
          style={{width: '100%'}}
          value={branch}
          disabled={disableBranch}
          onChange={(e) => onChangeInputSN(e, 'branch')}>
          {optionBranch.length > 0 &&
            optionBranch.map((el, i) => (
              <Select.Option value={el.value} key={i}>
                {el.label}
              </Select.Option>
            ))}
        </Select>
      ),
    },
    // {
    //   label: (
    //     <>
    //       Serial Status<span style={{color: 'red'}}> *</span> :{' '}
    //     </>
    //   ),
    // value: (
    //   <Select
    //     placeholder="กรุณาเลือก Serial Status"
    //     style={{width: '100%'}}
    //     value={serialStatus}
    //     disabled={disableBranch}
    //     onChange={(e) => onChange(e, 'serial_status')}>
    //     {optionSerialStatus.length > 0 &&
    //       optionSerialStatus.map((el, i) => (
    //         <Select.Option value={el.value} key={i}>
    //           {el.label}
    //         </Select.Option>
    //       ))}
    //   </Select>
    // ),
    // },
    {label: 'เลขที่เอกสาร : ', value: docNo},
    {
      label: 'ผู้สร้างเอกสาร :',
      value: `${header?.user_create?.name || ''} ${(header?.create_date && moment(header?.create_date).format('YYYY-MM-DD HH:mm')) || ''}`,
    },
    {
      label: 'แก้ไขล่าสุดโดย :',
      value: `${header?.user_update?.name || ''} ${(header?.update_date && moment(header?.update_date).format('YYYY-MM-DD HH:mm')) || ''}`,
    },
  ];

  const formSn = (
    <RenderForm
      control={control}
      setValue={setValue}
      getValues={getValues}
      clearErrors={clearErrors}
      errors={errors}
      formList={FORM_SN_CHECK_STOCK({fn: {onChangeInputSN, handleAddSerialNumber}, data: {docType}})}
    />
  );

  const countProducts = [
    {label: 'S/N', value: formSn},
    {label: 'จำนวนที่ยิงแล้ว', value: itemTotal},
  ];

  const onClearCustom = (setValue, formSearch) => {
    formSearch.forEach((val) => setValue(val.name, null));
    setDataSource([]);
    setBranch(null);
    setHeader({});
    setDisableBranch(false);
    setSerialStatus(null);
    setItemTotal(0);
    setDocNo('');
    setDocType('');
  };

  const onChangeSelectedAppleStatus = (value, index) => {
    // localStorage.setItem('checkStockAppleStatus', value);
    setCheckStockAppleStatus(value);

    let data = [...dataSource];
    let selectedIndex = data.findIndex((el) => el.index === index);

    if (!!data[selectedIndex].check_stock_apple_status) {
      data[selectedIndex].check_stock_apple_status = value;
      data[selectedIndex].appleStatus = value;
    } else {
      data[selectedIndex].appleStatus = value;
    }
    setDataSource(data);
  };

  const onCancelCountDoc = async () => {
    const data = {
      documentNo: docNo,
      // userLogin: user.email,
      // items:dataSource
    };
    if (data.documentNo !== '') {
      try {
        const res = await POST(DELETE_COUNT_DOC, data);
        const {success, message} = res;
        if (success) {
          Modal.success({
            title: message,
            afterClose: () => {
              getStockList(documentData);
              // localStorage.removeItem('checkStockAppleStatus');
              setCheckStockAppleStatus(null);
            },
          });
        }
      } catch (err) {
        const {message, error} = err;
        Modal.error({
          title: message,
          content: error || '',
        });
      }
    } else {
      Modal.warning({
        title: 'กรุณากรอกเลขที่เอกสาร',
      });
    }
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <SearchLayout
            props={{
              title: 'นับสต็อกสินค้า',
              icon: <FileDoneOutlined />,
              spanSearch: 24,
              formSearch: CheckStockSearchForm(),
              onSearch: (data) => getStockList(data),
              onClearCustom: onClearCustom,
            }}
          />

          <div style={{marginTop: '20px', padding: '12px'}}>
            <Row gutter={[48, 48]} justify="left" style={{marginTop: '12px'}}>
              {detailUser.map((val, i) => {
                return (
                  <Col xs={{span: 24}} md={{span: 24}} xl={{span: 4}} lg={{span: 4}} key={i}>
                    <span style={{fontWeight: '800'}}>{val.label}</span>
                    {val.value}
                  </Col>
                );
              })}
            </Row>
            <Row gutter={[16, 16]} justify="left" className="bg-scan-products">
              {countProducts.map((val, s) => {
                return (
                  <Col xs={{span: 24}} md={{span: 24}} xl={{span: 8}} lg={{span: 8}} key={s}>
                    <span style={{fontWeight: '800'}}>{`${val.label} : `}</span>
                    <br />
                    {s > 0 && val.value > 0 ? `${val.value} ชิ้น` : val.value}
                  </Col>
                );
              })}
            </Row>
            <TableLayout
              props={{
                title: 'รายการสินค้า',
                columns: CheckStockColumn({option: {optionAppleStatus}, onChangeSelectedAppleStatus}),
                dataSource: dataSource,
                footerContent: (
                  <ContainerButton center>
                    {documentData && documentData?.status_count_stock !== 'CANCEL' ? (
                      <>
                        {docType === 'CONFIRM_CHECKSTOCK' ? (
                          <ButtonTheme useFor="CONFIRM" title={'พิมพ์เอกสาร'} onClick={confirmStock} />
                        ) : (
                          <>
                            <ButtonTheme useFor="SUBMIT" title={'ตรวจสอบ'} onClick={checkStockItems} />
                            <ButtonTheme useFor="CONFIRM" title={'ยืนยันและพิมพ์เอกสาร'} onClick={confirmStock} disabled={disabledBtnConfirm} />
                          </>
                        )}
                        <ButtonTheme useFor="REJECT" title={'ยกเลิก'} onClick={() => onCancelCountDoc()} />
                      </>
                    ) : (
                      <></>
                    )}
                  </ContainerButton>
                ),
              }}
            />
          </div>
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default CheckStockScreen;
