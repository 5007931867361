import axios from 'axios';
import { auth } from '../utils/firebase';

console.log('✅ Axios Config Loaded!');


export const logout = async () => {
  await auth.signOut();
  localStorage.removeItem('token');
  localStorage.removeItem('menu_user');
  localStorage.removeItem('branchReportMonitorStock');
  localStorage.removeItem('checkStockAppleStatus');
  localStorage.removeItem('contractNo');
  localStorage.removeItem('userAdmin'); // For Adminv2 Web
  localStorage.removeItem('menuUser'); // For Adminv2 Web
  localStorage.removeItem('userAdminV2'); // oauth version 2
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      logout()
    }
    return Promise.reject(error);
  }
);

export default axios;