import React, { useState, useEffect, useRef } from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import { Scheduler } from '@aldabil/react-scheduler';
import { Modal, Card, Row, Col, Tree, DatePicker, Button, Tag, Space } from 'antd';
import { MobileOutlined, UserOutlined, CalendarOutlined, DatabaseOutlined, SearchOutlined, CheckOutlined, CloseOutlined} from '@ant-design/icons';
import moment from 'moment';
import { GET, GET_DATA_CALENDAR, DELETE, DELETE_APPOINTMENT, GET_BRANCH_OPTION_SPECIFIC, GET_BRANCH_CALENDAR_OPTION } from '../../../services';
import { useLocation } from 'react-router-dom';
import { auth } from '../../../utils/firebase';
import { useCurrentState } from './../../../utils/hook';
import { useBranch } from '../../../hook/useBranch';
import _ from 'lodash';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import { APP_URL } from '../../../env.js';
import { useSendChatconeByProcess, useGetHistoryConfirmAppointmentContract } from '../../../hook/chatcone';
import { ButtonTheme, Spinners } from '../../../components';
import { calAppointMentDiffTime, calAppointMentSecondhandDiffTime } from '../../../utils/auto-admin/time';
import { AppointmentModal } from './modal/AppointmentModal.js';
import { color } from '../../../resources/color';

const SchedulerDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [dataEvents, setEvents] = useState([]);

  const [defaultEvents, setDefaultEvents] = useState([]);
  const [visible, setVisible] = useState(false);
  const [optionBranch, setOptionBranch] = useState([]);
  const [branchDefault, setBranchDefault] = useState([]);
  const [defaultValue, setDefaultValue] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(['WAIT_PROMISE']);
  const [title, setTitle] = useState('');
  const location = useLocation();
  const { user } = useCurrentState(auth);
  const { branchList, branchSpecificList } = useBranch();

  const [calendarBranchOption, setCalendarBranchOption] = useState([]);
  const [totalContractType, setTotalContractType] = useState({ INSTALLMENT: 0, REFINANCE: 0 });
  const [expand, setExpand] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment().endOf('month'));
  const [selectedType, setSelectedType] = useState('ALL');

  const { pathname } = useLocation();
  const canvasRef = useRef(null);
  const [sendChatconeByProcessFn, sendChatconeByProcessLoading] = useSendChatconeByProcess();
  const [getHistoryConfirmAppointmentContractFn, historyConfirmAppointmentContractData] = useGetHistoryConfirmAppointmentContract();

  const defaultExpand = ['ALL_BRANCH'];
  const statusOptions = [
    {
      title: 'สถานะทั้งหมด',
      key: 'ALL_STATUS',
      children: [
        {
          key: 'FOLLOWUP',
          title: 'รอนัดทำสัญญา',
        },
        {
          key: 'WAIT_PROMISE',
          title: 'รอทำสัญญา',
        },
      ],
    },
  ];

  useEffect(() => {
    if (user && user.branch && branchDefault.length === 0) {
      let bh = [];
      let st = ['FOLLOWUP', 'WAIT_PROMISE'];
      // user.branch = 'KHONKAEN'
      if (user.branch === 'CENTER') {
        bh = branchSpecificList.map((el) => {
          return el.value;
        });
      } else {
        bh = [user.branch];
      }
      setBranchDefault(bh);
      setSelectedBranch(bh);
      setInitail();
    }
  }, [user]);

  const setInitail = async () => {
    if (location.state) {
      const { data } = location.state;
      const {
        registerId,
        fullName,
        phoneNumber,
        considerId,
        end,
        start,
        status,
        branch,
        type,
        remark,
        remark_appointment,
        contract_type,
        show_installment,
        full_name,
        chatcone,
        remark_consider
      } = data;

      const obj = {
        registerId,
        considerId,
        endTime: end ? moment(end.split(' ')[1], 'HH:mm') : null,
        startTime: start ? moment(start.split(' ')[1], 'HH:mm') : null,
        startDate: start ? moment(start.split(' ')[0]) : null,
        endDate: end ? moment(end.split(' ')[0]) : null,
        fullName,
        phoneNumber,
        status,
        type,
        contract_type,
        branch,
        remark,
        remark_appointment,
        show_installment,
        full_name,
        chatcone,
        remark_consider,
      };

      setDefaultValue(obj);

      if (type === 'FOLLOWUP') {
        setTitle('วันที่ติดตามครั้งถัดไป');
        setVisible(true);
      } else if (type === 'APPOINTMENT') {
        setTitle('นัดทำสัญญา');
        setVisible(true);
      }

      if (chatcone?.social_id && registerId) {
        await getHistoryConfirmAppointmentContractFn({ ref_id: registerId });
      }
    }

    await getDataCalendar(_, selectedType);
    await getBranch();
    await getCalendarBranch();
  };

  const getBranch = async () => {
    try {
      const res = await GET(GET_BRANCH_OPTION_SPECIFIC);
      const { success, result } = res;
      if (success) {
        setOptionBranch(result);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const getDataCalendar = async (ref, type = 'ALL') => {
    try {
      setLoading(true);
      
      setSelectedType(type);
      const startDateFormat = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
      const endDateFormat = endDate ? moment(endDate).format('YYYY-MM-DD') : '';


      const res = await GET(GET_DATA_CALENDAR(startDateFormat, endDateFormat, type));
      const { success, result } = res;
      if (success) {
        const data = result.data.map((val) => {
          return {
            event_id: { considerId: val.considerId, registerId: val.registerId },
            start: new Date(val.start),
            startTime: val.start.split(' ')[1],
            end: new Date(val.end),
            endTime: val.end.split(' ')[1],
            branch: val.branch,
            branchName: val.branch_name,
            considerId: val.considerId,
            registerId: val.registerId,
            product: val.product,
            combo: val.combo,
            title: val.title,
            user_create: val.user_create,
            user_update: val.user_update,
            status: val.status,
            type: val.type,
            contract_type: val.contract_type,
            signContractStatus: val.signContractStatus,
            phoneNumber: val.profile.phoneNumber,
            remark_appointment: val.remark_appointment,
            remark: val.remark,
            fullName: `คุณ ${val?.profile?.firstName}  ${val?.profile?.lastName}`,
            installment: val?.installment,
            show_installment: val?.show_installment ? 'SHOW' : 'NOT_SHOW',
            userCreateDateTime: val?.create_date
            ? val.user_update
              ? `${val.user_update.split(' ')[0]} ${val?.create_date} น.`
              : `${val.user_create.split(' ')[0]} ${val?.create_date} น.`
            : '',
            fullNameCard: `คุณ ${val?.profile?.firstName}  ${val?.profile?.lastName}`,
            full_name: `${val?.profile?.firstName} ${val?.profile?.lastName}`,
            chatcone: val?.chatcone,
            consider_appointment_id: val?._id,
            typeLock: val?.typeLock
          };
        });

        const userBranch = user && user.branch;
        if (userBranch) {
          const branchCheck = ref === 'selected' ? selectedBranch : userBranch;
          const filter = data.filter((el) => {
            return branchCheck.includes(el.branch) && selectedStatus.includes(el.status);
          });

          const events =
            ref === 'selected' ? filter : user && user.branch === 'CENTER' ? data.filter((el) => selectedStatus.includes(el.status)) : filter;
          setEvents(events);

          calTotalContractType(events);
        }
        setDefaultEvents(data);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const openModal = ({ scheduler }) => {
    // if(user && user.branch === "CENTER"){
    if (scheduler.edited) {
      const {
        registerId,
        end,
        start,
        considerId,
        title,
        branch,
        status,
        startTime,
        endTime,
        phoneNumber,
        remark_appointment,
        remark,
        type,
        contract_type,
        show_installment,
        full_name,
        chatcone,
        consider_appointment_id,
        typeLock
      } = scheduler.edited;

      if (status === 'APPOINTMENT') {
        setTitle('นัดทำสัญญา');
      } else {
        setTitle('วันที่ติดตามครั้งถัดไป');
      }

      if (!_.isEmpty(chatcone)) {
        getHistoryConfirmAppointmentContractFn({ ref_id: registerId });
      }

      const obj = {
        registerId,
        considerId,
        startDate: moment(start),
        endDate: moment(end),
        fullName: title,
        branch,
        startTime: moment(startTime, 'HH:mm'),
        endTime: moment(endTime, 'HH:mm'),
        remark,
        remark_appointment,
        phoneNumber,
        type,
        contract_type,
        show_installment,
        full_name,
        chatcone,
        consider_appointment_id,
        typeLock
      };
      setDefaultValue(obj);
      setVisible(true);
      scheduler.close();
    } else {
      Modal.error({
        title: 'กรุณากดนัดหมายในหน้าพิจารณาสัญญา',
      });
    }
    // }else{
    //     Modal.error({
    //         title: "ไม่สามารถแก้ไขได้",
    //     });
    // }
  };

  const closeModal = () => {
    setVisible(false);
  };

  const handleDelete = async (deletedId) => {
    const { registerId, considerId } = deletedId;
    if (branchDefault === 'CENTER') {
      try {
        let res = await DELETE(DELETE_APPOINTMENT(registerId, considerId));
        const { success, message } = res;
        if (success) {
          getDataCalendar(_, selectedType);
        }
      } catch (err) {
        const { message } = err;
        Modal.error({
          title: message,
        });
      }
    } else {
      Modal.error({
        title: 'ไม่สามารถลบได้',
      });
    }
  };

  const setCalender = {
    weekDays: [1, 2, 3, 4, 5, 6, 7],
    weekStartOn: 6,
    startHour: 6,
    endHour: 20,
    step: 60,
    cellRenderer: () => {
      return (
        <button
          style={{ background: '#f6f6f6', border: '1px solid #dee2e6' }}
          onClick={() => Modal.error({ title: 'กรุณากดนัดหมายในหน้าพิจารณาสัญญา' })}
        />
      );
    },
  };

  const eventColor = (status, contract_type) => {
    if (status === 'WAIT_PROMISE') {
      // if (contract_type === 'INSTALLMENT') {
      //   return '#f0c002';
      // } else {
      //   // REFINANCE
      //   return '#0000cc';
      // }

      switch (contract_type) {
        case 'INSTALLMENT':
          return '#ffc107'; // สีสำหรับ INSTALLMENT #ffc107
        case 'TURN2':
          return '#13D1C8'; // สีสำหรับ TURN2
        case 'TURN1':
          return '#2457C5'; // สีสำหรับ TURN1
        case 'SECONDHAND':
          return '#FF698A'; // สีสำหรับ SECONDHAND
        default:
          return '#2457C5'; // สี default หากไม่มี type ที่ตรงกัน
      }
    } else {
      // FOLLOWUP
      return '#ab2d2d';
    }
  };

  const renderTag = (tag) => {
    if (tag === 'SUCCESS') {
      return <CheckOutlined style={{ color: color.success }} />;
    }
    return <CloseOutlined style={{ color: color.red }} />;
  };

  const getCalendarBranch = async () => {
    try {
      const res = await GET(GET_BRANCH_CALENDAR_OPTION);

      const { success, result } = res;
      if (success) {
        const calendarBranch = [
          {
            title: 'สาขาทั้งหมด',
            key: 'ALL_BRANCH',
            children: [...result],
          },
        ];

        setCalendarBranchOption([...calendarBranch]);
        setExpand([...defaultExpand]);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const handleOnCheck = (e, type) => {
    let branch = selectedBranch;
    let status = selectedStatus;

    switch (type) {
      case 'STATUS':
        status = e;
        break;
      case 'BRANCH':
        branch = e;
        break;
      default:
        break;
    }

    const filter = defaultEvents?.filter((el) => {
      return status?.includes(el.status) && branch?.includes(el.branch);
    });

    setEvents(filter);
    setSelectedStatus(status);
    setSelectedBranch(branch);

    calTotalContractType(filter);
  };

  const calTotalContractType = (data) => {
   
    const normalizedData = data.map(item => ({
      ...item,
      contract_type: item.contract_type === 'REFINANCE' ? 'TURN1' : item.contract_type
    }));

  // จัดกลุ่มตาม contract_type
    const groupByContractType = _.groupBy(normalizedData, 'contract_type');
    // const groupByContractType = _.groupBy(data, 'contract_type');
    const defaultContractType = { INSTALLMENT: 0, TURN1: 0, TURN2: 0, SECONDHAND: 0 };
    const groupLengths = _.mapValues(groupByContractType, (group) => group.length);

    setTotalContractType({ ...defaultContractType, ...groupLengths });
  };

  const getTypeLock = (typeLock) => {
    switch (typeLock) {
      case 'MDM':
        return 'MDM';
      case 'ICLOUD':
        return 'iCloud';
      case 'MDMICLOUD':
        return 'MDM+iCloud';
      default:
        return '-';
    }
  };

  const getSignContractStatus = (typeLock) => {
    switch (typeLock) {
      case 'SUCCESS':
        return <text style={{ color: color.success }}>(เซ็นแล้ว)</text>;
      case 'WAITING_SIGNATURE':
        return <text style={{ color: color.red }}>(รอลูกค้าเซ็น)</text>;
      default:
        return '-';
    }
  };

  const getExtraComponent = (event) => {
    const { hours, minutes } = event?.contract_type === 'SECONDHAND' ? calAppointMentSecondhandDiffTime(event?.start, event?.end) : calAppointMentDiffTime(event?.start, event?.end); 

    let comboText = '';
    if (event?.combo && event?.combo.length !== 0) {
      if (event.combo.length > 1) {
        comboText += '+ Combo ';
        comboText += event.combo.map((item) => item.name).join(' + ');
      } else if (event.combo.length === 1) {
        comboText += `+ Combo ${event.combo[0]?.name}`;
      }
    }

    return (
      <>
        <div ref={canvasRef} style={{ width: '800px' }}>
          <div style={{ background: eventColor(event.status, event.contract_type) }}>
            
            <Space>
            <div style={{ paddingLeft: '15px' }}>
              <img
                    src="/dist/img/app-logo.png"
                    alt="Ufriend Logo"
                    className="brand-image img-circle"
                    style={{ backgroundColor: '#0052ba', height: '50px', width: '50px' }}
              />
            </div>
            <Row style={{ justifyContent: 'center', width: '800px', paddingLeft: 10, }} gutter={16}>
              <Col span={12} className="title-schedule-white-20">
                {event?.fullNameCard}
              </Col>
              <Col span={12} className="title-schedule-white-20">
                {event?.phoneNumber}
              </Col>

              <Col span={12}>
                <Row>
                  <Col span={12} className="title-schedule-white-24">
                    <CalendarOutlined className="icon-schedule-white" />
                    {dayjs(event?.start).format('DD/MM/YYYY')}
                  </Col>
                  <Col span={12} className="title-schedule-white-24">
                    {`${event?.startTime} น.`}
                  </Col>
                </Row>
              </Col>

              <Col span={12} className="title-schedule-white-24">
                {event?.branchName}
              </Col>
            </Row>

            </Space>
          </div>

          <Row gutter={16}>
            <Col span={24} className="schedule-20" style={{ paddingLeft: '15px' }}>
              <MobileOutlined className="icon-schedule-black" />
              {`${event?.product} ${comboText}`}
            </Col>
          </Row>

          {event?.show_installment === 'SHOW' ? (
            <Row gutter={16}>
              <Col span={24} className="schedule-20" style={{ paddingLeft: '15px' }}>
                <DatabaseOutlined className="icon-schedule-black" />
                {event?.installment}
              </Col>
            </Row>
          ) : null}

          {event?.remark_appointment ? (
            <Row gutter={16}>
              <Col span={24} className="schedule-16" style={{ paddingLeft: '36px' }}>
                {`หมายเหตุ: ${event.remark_appointment}`}
              </Col>
            </Row>
          ) : null}

          <Row gutter={16}>
            <Col span={24} className="schedule-16" style={{ textAlign: 'center' }}>
              {`${event.contract_type === 'SECONDHAND' ? 'ระยะเวลาดำเนินการ' : 'ระยะเวลาการทำสัญญา'} ${hours ? `${hours} ชั่วโมง` : ''}${minutes ? ` ${minutes} นาที` : ''} ค่ะ`}
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24} className="p-schedule" style={{ textAlign: 'right', paddingRight: '15px' }}>
              {`${event?.userCreateDateTime}`}
            </Col>
          </Row>
        </div>

        {event?.remark || event?.signContractStatus || ['TURN1', 'TURN2', 'SECONDHAND'].includes(event?.contract_type) ? (
          <Row gutter={16}>
            <Col span={24} className="schedule-16 schedule-bg-gray" style={{ paddingLeft: '15px' }}>
              <UserOutlined className="icon-schedule-black schedule-bg-gray" />
              สำหรับเจ้าหน้าที่
            </Col>
        </Row>
        ) : null}
        
        {event?.remark ? (
          <>
            <Row gutter={24}>
              <Col span={24} className="schedule-16 schedule-bg-gray" style={{ paddingLeft: '36px' }}>
                {`${event.remark}`}
              </Col>
            </Row>
            
          </>
        ) : null}

        {['TURN1', 'TURN2', 'SECONDHAND'].includes(event?.contract_type) && (
          <Row gutter={24}>
            <Col span={24} className="schedule-16 schedule-bg-gray" style={{ paddingLeft: '36px', paddingBottom: '5px' }}>
              {`สถานะเซ็นสัญญาซื้อออนไลน์: `} {renderTag(event?.signContractStatus || 'WAITING_SIGNATURE')} { getSignContractStatus(event?.signContractStatus || 'WAITING_SIGNATURE')}
            </Col>
          </Row>
        )}

        {event?.typeLock ? (
          <Row gutter={16}>
            <Col span={24} className="schedule-16" style={{ paddingLeft: '36px' }}>
              {`รูปแบบการล็อคเครื่อง: ${getTypeLock(event.typeLock)}`}
            </Col>
          </Row>
        ) : null}

        {event?.chatcone?.social_id ? (
          <Row gutter={16}>
            <Col span={24} className="schedule-16" style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
              <ButtonTheme useFor="SEND_NOTI" title="ส่งแจ้งเตือนไปยังแชท" onClick={() => handleCapture(event)} />
            </Col>
          </Row>
        ) : null}


      </>
    );
  };

  const handleCapture = async (event) => {
    const { chatcone, consider_appointment_id, registerId, start, startTime, contract_type, typeLock } = event;
    const canvasElement = canvasRef.current;
    if (canvasElement) {
      const canvas = await html2canvas(canvasElement);
      const base64String = canvas.toDataURL().split(',')[1];

      /* CHATCONE: 4 CONFIRM_APPOINTMENT_CONTRACT ส่งรูปใบนัด */
      let processType = 'CONFIRM_APPOINTMENT_CONTRACT';

      if (contract_type === 'INSTALLMENT' && typeLock === 'MDM') {
        processType = 'CONFIRM_APPOINTMENT_CONTRACT_INSTALLMENT_MDM';
      } else if (contract_type === 'INSTALLMENT' && typeLock === 'MDMICLOUD') {
        processType = 'CONFIRM_APPOINTMENT_CONTRACT_INSTALLMENT_MDMICLOUD';
      } else if (_.includes(['REFINANCE', 'TURN1'], contract_type) && typeLock === 'ICLOUD') {
        processType = 'CONFIRM_APPOINTMENT_CONTRACT_REFINANCE_ICLOUD';
      } else if (_.includes(['REFINANCE', 'TURN1'], contract_type) && typeLock === 'MDMICLOUD') {
        processType = 'CONFIRM_APPOINTMENT_CONTRACT_REFINANCE_MDMICLOUD';
      } else if (_.includes(['TURN2'], contract_type)) {
        processType = 'CONFIRM_APPOINTMENT_CONTRACT_TURN2';
      }

      if (chatcone?.social_id) {
        const setInput = {
          process_type: processType,
          register_id: registerId,
          ref_id: consider_appointment_id,
          route_from: `${APP_URL}${pathname}`,
          imageBase64String: base64String,
          data: {
            date: dayjs(start).format('DD/MM/YYYY'),
            time: startTime,
          },
        };
        await sendChatconeByProcessFn({ ...setInput });
      }

    }
  };

  const handleCalendarSearch = async (type) => {
    const isSelectBranch = selectedBranch?.length > 0;

    if (isSelectBranch) {
      await getDataCalendar(_, type);
      setSelectedType(type);
    } else {
      Modal.warning({
        title: 'กรุณาเลือกสาขาที่ต้องการค้นหา',
      });
    }
  };

  const getOpacity = (type) => (selectedType === 'ALL' || selectedType === null || selectedType === type ? 1 : 0.5);

  return (
    <Wrapper>
      {(loading || sendChatconeByProcessLoading) ? <Spinners /> : null}
      <Card title="ปฎิทินนัดทำสัญญา">
        {branchDefault.length > 0 && (
          <>
            <Row style={{ justifyContent: 'center', marginBottom: '22px' }}>
              <Col span={3}>
                <Row style={{ justifyContent: 'center' }}>
                  <Col>
                    <DatePicker
                      allowClear={true}
                      placeholder={'เลือกวันที่'}
                      defaultValue={endDate}
                      value={startDate}
                      style={{ width: '100%', marginTop: 5 }}
                      format={'YYYY-MM-DD'}
                      onChange={(e) => setStartDate(e)}
                    />
                  </Col>
                </Row>
                <Row style={{ justifyContent: 'center', marginBottom: '12px' }}>
                  <Col>
                    <DatePicker
                      allowClear={true}
                      placeholder={'เลือกวันที่'}
                      defaultValue={endDate}
                      value={endDate}
                      style={{ width: '100%', marginTop: 5 }}
                      format={'YYYY-MM-DD'}
                      onChange={(e) => setEndDate(e)}
                    />
                  </Col>
                </Row>
                <Row style={{ justifyContent: 'center', marginBottom: '12px' }}>
                  <Col>
                    <Button type="primary" icon={<SearchOutlined />} style={{ width: 90 }} onClick={() => handleCalendarSearch(selectedType)}>
                      ค้นหา
                    </Button>
                  </Col>
                </Row>
              </Col>

              <Col span={21}>
                <Row style={{ justifyContent: 'center' }} gutter={[0, 16]}>
                  <Col>
                    <Card style={{ backgroundColor: '#ffc107', fontSize: '20px', cursor: 'pointer', opacity: getOpacity('INSTALLMENT') }} className="card-summary" onClick={() => { handleCalendarSearch('INSTALLMENT'); }}>
                      <span>{`ผ่อน : `}</span>
                      <span>{`${totalContractType?.INSTALLMENT || 0} รายการ`}</span>
                    </Card>
                  </Col>

                  <Col>
                    <Card style={{ backgroundColor: '#2457C5', fontSize: '20px', cursor: 'pointer', opacity: getOpacity('TURN1') }} className="card-summary" onClick={() => { handleCalendarSearch('TURN1'); }}>
                      <span>{`เทิร์น1 : `}</span>
                      <span>{`${totalContractType?.TURN1 || 0} รายการ`}</span>
                    </Card>
                  </Col>

                  <Col> 
                    <Card style={{ backgroundColor: '#13D1C8', fontSize: '20px', cursor: 'pointer', opacity: getOpacity('TURN2') }} className="card-summary" onClick={() => { handleCalendarSearch('TURN2'); }}>
                      <span>{`เทิร์น2 : `}</span>
                      <span>{`${totalContractType?.TURN2 || 0} รายการ`}</span>
                    </Card>
                  </Col>

                  <Col>
                    <Card style={{ backgroundColor: '#FF698A', fontSize: '20px', cursor: 'pointer', opacity: getOpacity('SECONDHAND') }} className="card-summary" onClick={() => { handleCalendarSearch('SECONDHAND'); }}>
                      <span>{`ขายขาด : `}</span>
                      <span>{`${totalContractType?.SECONDHAND || 0}  รายการ`}</span>
                    </Card>
                  </Col>

                  <Col>
                    <Card style={{ backgroundColor: '#2E3136', fontSize: '20px', cursor: 'pointer', opacity: getOpacity('ALL') }} className="card-summary" onClick={() => { handleCalendarSearch('ALL'); }}>
                      <span>{`รวม : `}</span>
                      <span>{`${totalContractType?.INSTALLMENT + totalContractType?.TURN1 + totalContractType?.TURN2 + totalContractType?.SECONDHAND || 0}  รายการ`}</span>
                    </Card>
                  </Col>


                </Row>
              </Col>
            </Row>

            <Row>
              <Col span={3}>
                <Tree
                  checkable
                  defaultExpandAll={true}
                  onCheck={(e) => handleOnCheck(e, 'STATUS')}
                  treeData={statusOptions}
                  checkedKeys={selectedStatus}
                />
                <Tree
                  checkable
                  onCheck={(e) => handleOnCheck(e, 'BRANCH')}
                  treeData={calendarBranchOption}
                  checkedKeys={selectedBranch}
                  expandedKeys={expand}
                  onExpand={(expandedKeys) => {
                    setExpand([...expandedKeys]);
                  }}
                />
              </Col>
              <Col span={21}>
                <Scheduler
                  week={setCalender}
                  month={setCalender}
                  day={setCalender}
                  events={dataEvents.map((e) => ({ ...e, color: eventColor(e.status, e.contract_type) }))}
                  view={'month'}
                  viewerTitleComponent={(event) => <div 
                    className="title-schedule-white-24" 
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}
                  >
                    ใบนัด
                  </div>}
                  viewerExtraComponent={(fields, event) => getExtraComponent(event)}
                  customEditor={(scheduler) => openModal({ scheduler })}
                  onDelete={
                    branchDefault === 'CENTER'
                      ? handleDelete
                      : () =>
                        Modal.error({
                          title: 'ไม่สามารถลบได้',
                        })
                  }
                />
              </Col>
            </Row>
          </>
        )}
      </Card>
      <AppointmentModal
        visible={visible}
        onClose={closeModal}
        title={title}
        data={{ reApi: getDataCalendar, options: { optionBranch }, defaultValue }}
        historyConfirmAppointmentContractData={historyConfirmAppointmentContractData}
      />
    </Wrapper>
  );
};

export default SchedulerDashboard;
