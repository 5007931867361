import React, {useState, useEffect, useRef, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import Wrapper from '../../../views/wrapper/Wrapper';
import {Modal, Card, Spin, message} from 'antd';
import {SettingOutlined} from '@ant-design/icons';
import {PageLayout} from '../../../components/pageLayout/PageLayout';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {MonitorCustomerColumn} from '../../../constants/global/columnTableForm.js';
import {dateType, optionWalkIn, optionProductHandFilter} from './hook/useConstant.js';
import moment from 'moment';
import {CSVLink} from 'react-csv';
import {ButtonTheme} from '../../../components/buttons';
import {auth} from '../../../utils/firebase';
import {useCurrentState} from './../../../utils/hook';
import {Icon} from '../../../resources/icon';
import {removeNewlineText, convertStrToFormat, createParams} from '../../../functions/fn';
import {
  POST,
  PUT,
  GET,
  GET_CONSIDER_DATA,
  GET_SUMMARY_CONSIDER,
  GET_CONSIDER_STATUS,
  CONSIDER_RECEIVE,
  GET_CONSIDER_DETAIL,
  GET_CONSIDER_DETAIL_BY_ID,
  DELETE_CONSIDER,
  GET_BRANCH,
  EDIT_NO_PROMISE_CONSIDER,
  GET_BRANCH_USER,
  GET_BLACKLIST_LINK,
} from '../../../services';
import {getConsiderDataExport} from '../../../utils/api_other_domain';
import {useBranch} from '../../../hook/useBranch';
import {CancelConsiderModal} from './modal/CancelConsiderModal';
import {EditRemarkMonitorModal} from './modal/EditRemark';
import {EditMainSocialMonitorModal} from './modal/EditMainSocial';
import {BlacklistLinkModal} from './modal/BlacklistLinkModal';
import dayjs from 'dayjs';
import {MonitorCustomerSearchForm} from './form';
import {useLocation} from 'react-router-dom';
import {ChatconeFollowContractModal} from '../chatcone/modal/ChatconeFollowContractModal';
import {EditSocailIdAndTagsModal} from '../chatcone/modal/EditSocialIdAndTags.jsx';
import {useUpdateTagChatconeByProcess, useUpdateTagBulkChatcone} from '../../../hook/chatcone';
import {APP_URL} from '../../../env';
import {Spinners} from '../../../components';
import _, {set} from 'lodash';
import {ApproveCustomerOldModal} from './consider_dapp/ApproveCustomer.js';
import {ApproveCustomerVividModal} from './consider_vivid/ApproveCustomer.js';
import {optionSignOnline, typeNew} from './hook/useConstant.js';
import {getConsiderDetail as apiGetConsiderDetail} from '../../../services/api/svapi/consider';

const MonitorCustomer = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [pipelineStatus, setPipelineStatus] = useState([]);
  const [optionsConsiderStatus, setOptionsConsiderStatus] = useState([]);
  const [optionsBranch, setOptionsBranch] = useState([]);
  const [optionsBranchUser, setOptionsBranchUser] = useState([]);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [visibleEditSocialModal, setVisibleEditSocialModal] = useState(false);
  const [visibleApproveOldModal, setVisibleApproveOldModal] = useState(false);
  const [visibleApproveVividModal, setVisibleApproveVividModal] = useState(false);
  const [visibleCancelConsiderModal, setVisibleCancelConsiderModal] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});
  const [valueSearch, setValueSearch] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [considerId, setConsiderId] = useState('');
  const [dataExport, setDataExport] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [company, setCompany] = useState('');
  const [action, setAction] = useState(null);

  const pageRef = useRef(1); //1
  const sizeRef = useRef(10);
  const [requiredDate, setRequiredDate] = useState(false);

  const [totalSize, setTotalSize] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const {pathname} = useLocation();
  const [visibleChatconeFollowContractModal, setVisibleChatconeFollowContractModal] = useState(false);
  const [updateTagChatconeByProcessFn, updateTagChatconeByProcessLoading] = useUpdateTagChatconeByProcess();
  const [updateTagBulkChatconeFn, updateTagBulkChatconeLoading] = useUpdateTagBulkChatcone();
  const [visibleEditSocialIdModal, setVisibleEditSocialIdModal] = useState(false);
  const menu_user = JSON.parse(localStorage.getItem('menu_user'));
  const permissions = menu_user.length > 0 && menu_user.filter((el) => el.head === 'Ufriend')[0]?.menu;
  const perAction = (permissions.length > 0 && permissions.filter((el) => el.path === '/monitor-customer')[0]?.actions) || [];

  const [filter, setFilter] = useState({
    fullName: '',
    tel: '',
    dateType: '',
    appointmentStartDate: '',
    appointmentEndDate: '',
    registerStartDate: '',
    registerEndDate: '',
    status: 'WAIT',
    type: '',
    // status: 'CONSIDERING',
    branch: '',
    walk_in: '',
    productHand: '',
    remark: '',
    signOnlineStatus: '',
  });

  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
  });

  const {user} = useCurrentState(auth);
  const history = useHistory();
  const {branchSpecificList} = useBranch();

  const [defaultSearch, setDefaultSearch] = useState({
    name: '',
    tel: '',
    dateType: '',
    startDate: '',
    endDate: '',
    status: '',
    type: '',
    branch: '',
    walk_in: '',
    productHand: '',
    remark: '',
    signOnlineStatus: '',
  });

  const filterMemo = useMemo(() => {
    const data = {...filter};
    data.page = data?.page || 1;
    data.size = data?.size || 10;

    pageRef.current = data.page;
    sizeRef.current = data.size;

    data.fullName = data.fullName || '';
    data.tel = data.tel || '';

    data.dateType = data.dateType || '';
    data.status = data.status || '';
    data.type = data.type || '';
    data.branch = data.branch || '';

    if (typeof data.walk_in === 'boolean') {
      data.walk_in = data.walk_in === true ? 'walk_in' : data.walk_in === false ? 'none' : '';
    }

    data.walk_in = data.walk_in === 'walk_in' ? true : data.walk_in === 'none' ? false : '';

    data.productHand = data.productHand || '';
    data.remark = data.remark || '';
    data.signOnlineStatus = data.signOnlineStatus || '';

    if (data.dateType === 'registerDate') {
      data.registerStartDate = data.startDate ? moment(data.startDate).format('YYYY-MM-DD') : '';
      data.registerEndDate = data.endDate ? moment(data.endDate).format('YYYY-MM-DD') : '';
      data.appointmentStartDate = '';
      data.appointmentEndDate = '';
    } else if (data.dateType === 'appointmentDate') {
      data.registerStartDate = '';
      data.registerEndDate = '';
      data.appointmentStartDate = data.startDate ? moment(data.startDate).format('YYYY-MM-DD') : '';
      data.appointmentEndDate = data.endDate ? moment(data.endDate).format('YYYY-MM-DD') : '';
    } else if (data.dateType === '') {
      data.registerStartDate = '';
      data.registerEndDate = '';
      data.appointmentStartDate = '';
      data.appointmentEndDate = '';
    }
    setPagination({
      ...pagination,
      page: 1,
    });
    return data;
  }, [filter]);

  const paginationMemo = useMemo(() => {
    const data = {...pagination};

    data.page = data.page || 1;
    data.size = data.size || 10;
    return data;
  }, [pagination]);

  /* ------------- สำหรับการ Search ------------- */
  useEffect(() => {
    setDefaultSearch({
      fullName: '',
      tel: '',
      dateType: '',
      startDate: '',
      endDate: '',
      status: 'WAIT',
      type: '',
      statusMonitor: 'WAIT',
      // status: 'CONSIDERING', type: '', statusMonitor: 'CONSIDERING',
      branch: '',
      walk_in: '',
      productHand: '',
      remark: '',
      signOnlineStatus: '',
    });

    getSummaryConsider();
    onFetchOptionsConsiderStatus();
    getBranch();
    getBranchUser();
  }, []);

  useEffect(() => {
    getDataConsider(filterMemo);
  }, [filterMemo, paginationMemo]);

  useEffect(() => {
    if (defaultSearch) {
      const dateType = defaultSearch?.dateType || '';
      const startDate = defaultSearch?.startDate || '';
      const endDate = defaultSearch?.endDate || '';

      if (dateType === '' && startDate === '' && endDate === '') {
        setRequiredDate(false);
      } else {
        setRequiredDate(true);
      }
    }
  }, [defaultSearch]);

  const actionFilter = (data) => {
    setFilter(data);
  };

  const onFetchOptionsConsiderStatus = async () => {
    const res = await GET(GET_CONSIDER_STATUS);
    setOptionsConsiderStatus(res.status);
  };

  const getSummaryConsider = async () => {
    setLoading(true);
    try {
      let className;
      let styled;
      let res = await GET(GET_SUMMARY_CONSIDER);
      const {success, result} = res;
      if (success) {
        const data =
          result &&
          result.summary.map((el, i) => {
            switch (el.id) {
              case 'WAIT':
                className = 'timeline-status border-zindex';
                styled = {marginLeft: '40%'};
                break;
              case 'CONSIDERING':
                className = 'timeline-status border-zindex';
                styled = {marginLeft: '58%'};
                break;
              case 'WAIT_APPOINTMENT_PROMISE':
                className = 'timeline-status border-zindex';
                styled = {marginLeft: '75%'};
                break;
              case 'WAIT_PROMISE':
                className = 'border-zindex';
                styled = {marginLeft: '92%'};
                break;

              default:
                break;
            }

            return {
              ...el,
              className,
              styled,
            };
          });

        setPipelineStatus(data);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const getBranch = async () => {
    try {
      const res = await GET(GET_BRANCH);
      const {success, result} = res;
      if (success) {
        let temp = {label: 'ไม่มี', value: ''};
        let arr = [...result.branch];
        arr.unshift(temp);
        setOptionsBranch(arr);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const getBranchUser = async () => {
    try {
      const res = await GET(GET_BRANCH_USER);
      const {success, result} = res;
      if (success) {
        const option = result.map((el) => {
          if (el.value === 'ON_HOLD') {
            el.label = 'ยังไม่ได้ตัดสินใจ';
          }
          return {
            value: el.value,
            label: el.label,
          };
        });
        setOptionsBranchUser(option);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const checkValidDateMoment = (data) => {
    let validResult = '';
    if (data.isValid() === true) {
      validResult = data;
    } else {
      validResult = '';
    }
    return validResult;
  };

  /* ------------- สำหรับการดึงข้อมูล ------------- */

  /* ดึงข้อมูล List Data  */
  const getDataConsider = async (data) => {
    const {page, size} = pagination;
    const {
      fullName,
      tel,
      dateType,
      appointmentStartDate,
      appointmentEndDate,
      registerStartDate,
      registerEndDate,
      status,
      type,
      branch,
      walk_in,
      productHand,
      remark,
      signOnlineStatus,
    } = data;

    setLoading(true);
    setValueSearch(data);

    let startDate = moment(new Date(appointmentStartDate !== '' ? appointmentStartDate : registerStartDate !== '' ? registerStartDate : ''));
    let endDate = moment(new Date(appointmentEndDate !== '' ? appointmentEndDate : registerEndDate !== '' ? registerEndDate : ''));

    startDate = checkValidDateMoment(startDate);
    endDate = checkValidDateMoment(endDate);

    if (status === '') {
      setDefaultSearch({
        fullName: '',
        tel: '',
        firstName: '',
        lastName: '',
        dateType: dateType,
        startDate: startDate,
        endDate: endDate,
        status: null,
        type: '',
        statusMonitor: null,
        branch: '',
        walkIn: '',
        productHand: '',
        remark: '',
        signOnlineStatus: '',
      });
    } else {
      setDefaultSearch({
        fullName: '',
        tel: '',
        firstName: '',
        lastName: '',
        dateType: dateType,
        startDate: startDate,
        endDate: endDate,
        status: status,
        type: '',
        statusMonitor: status,
        branch: '',
        walkIn: '',
        productHand: '',
        remark: '',
        signOnlineStatus: '',
      });
    }

    const params = createParams({
      page,
      size,
      fullName,
      tel,
      appointmentStartDate,
      appointmentEndDate,
      registerStartDate,
      registerEndDate,
      status,
      type,
      branch,
      walk_in,
      productHand,
      remark,
      signOnlineStatus,
    });

    try {
      let res = await GET(GET_CONSIDER_DATA(params));
      let {success, result} = res;

      if (success) {
        setTotalPage(result?.totalPage);
        setTotalSize(result?.totalItem);
        const data =
          result &&
          result.data.map((el, i) => {
            return {
              ...el,
              key: el.registerId,
              rowNo: i + 1 + (pagination?.page - 1) * pagination?.size,
              fullName: el.profile.fullName,
              userGrade: el?.userGrade,
              phoneNumber: el?.profile?.phoneNumber,
              facebook: el?.profile?.facebook,
              lineId: el?.profile?.lineId,
              typeStatement: el.statement ? el.statement?.type : '',
              registerDate: moment(el.registerDate).format('DD/MM/YYYY'),
              statusName: (el.status && el.status.text) || '',
              appointmentDate: el.appointment?.date ? el.appointment && moment(el.appointment.date).format('DD/MM/YYYY HH:MM') : '',
              branch: el.appointment?.branch ? el.appointment.branch.branch_name : '',
              interestedBranch: el?.interestedBranch !== '' ? el?.interestedBranch : 'ON_HOLD',
              aLocalScale: el?.aScore?.aLocalScale || '-',
              platform: el?.platform || '',
              signOnlineStatus: el?.signOnline?.status || '',
              company: el?.company || '',
            };
          });

        setDataSource([...new Map(data.map((item) => [item['key'], item])).values()]);
        setLoading(false);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const considerReceive = async (record) => {
    try {
      const res = await PUT(CONSIDER_RECEIVE(record.registerId));
      const {success, result} = res;
      if (success) {
        setConsiderId(result.id);
        let company = record?.company === '' ? 'DAPP' : record?.company;
        setCompany(company);
        if (company === 'VIVID') {
          getConsiderDetailVivid(record.registerId);
        } else {
          getConsiderDetail(record.registerId);
        }
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  /* ดึงรายละเอียดข้อมูลพิจารณา */
  const getConsiderDetail = async (registerId) => {
    setLoading(true);
    try {
      const res = await GET(GET_CONSIDER_DETAIL(registerId));
      const {success, result} = res;
      if (success) {
        const data = [result].map((el) => {
          const contact = el.contact.map((val, i) => {
            return {
              [`relationship${i + 1}`]: val.relationship,
              [`firstName${i + 1}`]: val.firstName,
              [`lastName${i + 1}`]: val.lastName,
              [`phoneNumber${i + 1}`]: val.phoneNumber,
            };
          });
          return {
            registerId,
            type: el.type,
            customer_grade: el.customer_grade,
            model: el.product.model,
            storage: el.product.storage,
            color: el.product.color,
            productImage: el.product.image,
            period: el.summary.period || el?.period,
            downPayment: el.summary.downPayment || 0,
            installments: el.summary.installments || 0,
            maximum: el.summary.maximum,
            idCard: el.profile.idCard,
            phoneNumber: el.profile.phoneNumber,
            prefix: el.profile.prefix,
            firstName: el.profile.firstName,
            lastName: el.profile.lastName,
            address: el.profile.address,
            zipcode: el.profile.zipcode,
            province: el.profile.province,
            district: el.profile.district,
            subdistrict: el.profile.subdistrict,
            facebook: el.profile.facebook,
            lineId: el.profile.lineId,
            countPerson: el.profile.countPerson,
            phoneSystem: el.profile.phoneSystem,
            serial_number: el.profile.serial_number,
            occupation: el.job.type || '',
            college: el.job.college || '',
            faculty: el.job.faculty || '',
            branch: el.job.branch || '',
            interestedBranch: el.interestedBranch !== '' ? el.interestedBranch : 'ON_HOLD',
            degree: el.job.degree || '',
            grade: el.job.grade || '',
            year: el.job.year || '',
            avgIncome: el.job.salary || '',
            jobDescription: el.job.occupation || '',
            business: el.job.business || '',
            companyName: el.job.companyName || '',
            companyLocation: el.job.companyLocation || '',
            position: el.job.position || '',
            experience: el.job.experience || '',
            salary: el.job.salary || '',
            companyPhoneNumber: el.job.companyPhoneNumber || '',
            incomeDoc: el.statement.type || '',
            // filePasswordSlip: el.statement.password || '',
            filePassword: el.statement.password || '',
            fileListStatement: el.statement.images || [],
            studentImages: el.job.studentImages || [],
            transcriptOrTermFees: el.job.transcriptOrTermFees,
            imeiImageList: el.profile.imeiImages.images,
            iphoneImageList: el.profile.iphoneStorage.images,
            userCreateId: (el.consider && el.consider.user_create && el.consider.user_create.id) || '',
            userCreateName: (el.consider && el.consider.user_create && el.consider.user_create.name) || '',
            status: el.status,
            othersDetails: el?.othersDetails || '',
            remark_consider: (el.consider && el.consider.remark) || '',
            remark: el.remark || '',
            remark_approve_special: (el.consider && el.consider.remark_approve_special) || '',
            remark_reject: (el.consider && el.consider.remark_reject) || '',
            is_approve_special: (el.consider && el.consider.is_approve_special) || null,
            knownUfriendFrom: (el.knownUfriendFrom && el.knownUfriendFrom) || '',
            salary_avg: el.salary_avg || '',
            statusApp:
              el.status !== 'CONSIDERING' || el.status !== 'WAIT'
                ? ['WAIT_APPOINTMENT_PROMISE', 'WAIT_PROMISE', 'FOLLOWUP', 'SUCCESS_PROMISE'].includes(el.status)
                  ? 'APPROVED'
                  : el.status
                : '',
            considerId: (el.consider && el.consider.id) || '',
            ...contact[0],
            ...contact[1],
            ...contact[2],
            channel: el?.channel,
            chatcone: el?.chatcone,
            dateOfBirth: el?.profile?.dateOfBirth ? moment(el.profile.dateOfBirth) : null,
            newPeriod: el.summary.newPeriod,
            newDownPayment: el.summary.newDownPayment || null,
            newMaximum: el.summary.newMaximum || null,
            newInstallment: el.summary.newInstallment || null,
            aScore: el.aScore || null,
            friendGetFriend: el?.friendGetFriend,
            proveWork: el?.proveWork || [],
            riderAppication: el?.riderAppication || [],
            governmentOfficial: el?.governmentOfficial || [],
            proveOwnBusinessImage: el?.proveOwnBusinessImage || [],
            proveOwnBusinessImageFlag: el?.proveOwnBusinessImageFlag,
            salesChannels: el?.salesChannels || null,
            acceptCondition: el?.acceptCondition,
            walkin: el?.walk_in,
            chanelSalary: el?.job?.chanelSalary || '',
            salesChannelsImage: el?.salesChannelsImage?.images || [],
            urlOnlineSale: el?.salesChannelsImage?.link || '',
            combo: el?.combo || {},
            typeLock: el?.typeLock,
            defaultTypeLock: el?.defaultTypeLock,
            productGroup: el?.product?.category || '',
            productHand: el?.productHand || '',
          };
        });

        setDefaultValue(data);
        setVisibleApproveOldModal(true);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const getConsiderDetailVivid = async (registerId) => {
    setLoading(true);
    try {
      const res = await apiGetConsiderDetail(registerId);

      const {success, result} = res;
      if (success) {
        const data = [result].map((el) => {
          const contact = el.contact.map((val, i) => {
            return {
              [`relationship${i + 1}`]: val.relationship,
              [`firstName${i + 1}`]: val.firstName,
              [`lastName${i + 1}`]: val.lastName,
              [`phoneNumber${i + 1}`]: val.phoneNumber,
            };
          });
          return {
            registerId,
            type: el.type,
            customer_grade: el.customer_grade,
            // model: el.product.model,
            // storage: el.product.storage,
            // color: el.product.color,
            // productImage: el.product.image,
            period: el.summary.period || null,
            downPayment: el.summary.downPayment || null,
            installments: el.summary.installments || null,
            maximum: el.summary.maximum || null,
            total_price: el?.summary?.totalPrice || null,
            idCard: el.profile.idCard,
            phoneNumber: el.profile.phoneNumber,
            prefix: el.profile.prefix,
            firstName: el.profile.firstName,
            lastName: el.profile.lastName,
            address: el.profile.address,
            zipcode: el.profile.zipcode,
            province: el.profile.province,
            district: el.profile.district,
            subdistrict: el.profile.subdistrict,
            facebook: el.profile.facebook,
            lineId: el.profile.lineId,
            countPerson: el.profile.countPerson,
            phoneSystem: el.profile.phoneSystem,
            serial_number: el.profile.serial_number,
            occupation: el.job.type || '',
            college: el.job.college || '',
            faculty: el.job.faculty || '',
            branch: el.job.branch || '',
            interestedBranch: el.interestedBranch !== '' ? el.interestedBranch : 'ON_HOLD',
            degree: el.job.degree || '',
            grade: el.job.grade || '',
            year: el.job.year || '',
            avgIncome: el.job.salary || '',
            jobDescription: el.job.occupation || '',
            business: el.job.business || '',
            companyName: el.job.companyName || '',
            companyLocation: el.job.companyLocation || '',
            position: el.job.position || '',
            experience: el.job.experience || '',
            salary: el.job.salary || '',
            companyPhoneNumber: el.job.companyPhoneNumber || '',
            incomeDoc: el.statement.type || '',
            // filePasswordSlip: el.statement.password || '',
            filePassword: el.statement.password || '',
            fileListStatement: el.statement.images || [],
            studentImages: el.job.studentImages || [],
            transcriptOrTermFees: el.job.transcriptOrTermFees,
            imeiImageList: el.profile.imeiImages.images,
            iphoneImageList: el.profile.iphoneStorage.images,
            userCreateId: (el.consider && el.consider.user_create && el.consider.user_create.id) || '',
            userCreateName: (el.consider && el.consider.user_create && el.consider.user_create.name) || '',
            status: el.status,
            othersDetails: el?.othersDetails || '',
            remark_consider: (el.consider && el.consider.remark) || '',
            remark: el.remark || '',
            remark_approve_special: (el.consider && el.consider.remark_approve_special) || '',
            remark_reject: (el.consider && el.consider.remark_reject) || '',
            is_approve_special: (el.consider && el.consider.is_approve_special) || null,
            knownUfriendFrom: (el.knownUfriendFrom && el.knownUfriendFrom) || '',
            salary_avg: el.salary_avg || '',
            statusApp:
              el.status !== 'CONSIDERING' || el.status !== 'WAIT'
                ? ['WAIT_APPOINTMENT_PROMISE', 'WAIT_PROMISE', 'FOLLOWUP', 'SUCCESS_PROMISE'].includes(el.status)
                  ? 'APPROVED'
                  : el.status
                : '',
            considerId: (el.consider && el.consider.id) || '',
            ...contact[0],
            ...contact[1],
            ...contact[2],
            channel: el?.channel,
            chatcone: el?.chatcone,
            dateOfBirth: el?.profile?.dateOfBirth ? moment(el.profile.dateOfBirth) : null,
            newPeriod: el.summary.newPeriod,
            newDownPayment: el.summary.newDownPayment || null,
            newMaximum: el.summary.newMaximum || null,
            newInstallment: el.summary.newInstallment || null,
            aScore: el?.aScore || null,
            friendGetFriend: el?.friendGetFriend,
            proveWork: el?.proveWork || [],
            riderAppication: el?.riderAppication || [],
            governmentOfficial: el?.governmentOfficial || [],
            proveOwnBusinessImage: el?.proveOwnBusinessImage || [],
            proveOwnBusinessImageFlag: el?.proveOwnBusinessImageFlag,
            salesChannels: el?.salesChannels || null,
            acceptCondition: el?.acceptCondition,
            walkin: el?.walk_in,
            chanelSalary: el?.job?.chanelSalary || '',
            salesChannelsImage: el?.salesChannelsImage?.images || [],
            urlOnlineSale: el?.salesChannelsImage?.link || '',
            combo: el?.combo || {},
            typeLock: el?.typeLock,
            defaultTypeLock: el?.defaultTypeLock,
            productHand: el?.productHand || '',
            appointmentDate: el?.appointmentDate || '',

            //from down here is fields relate to 'vivid'
            condition_cal_system: el?.condition || '',
            condition_approve: el?.newCondition ?? el?.condition,

            //mock vivid if integrate change false condition to ''
            urlSignOnline: el?.signOnline?.urlSign || '',
            signOnlineStatus: el?.signOnline?.status || '',

            /* Step 2 */
            model_newPrd: el?.product.model,
            storage_newPrd: el?.product.storage,
            color_newPrd: el?.product.color,
            productGroup_newPrd: el?.product?.category,
            productImage_newPrd: el.product.image,

            /* Step 1 */
            productGroup: el?.type === 'TURN2' ? el?.productTurn?.category || '' : el?.product?.category || '',
            model: el?.type === 'TURN2' ? el?.productTurn?.model || '' : el.product.model || '',
            storage: el?.type === 'TURN2' ? el?.productTurn?.storage || '' : el.product.storage || '',
            color: el?.type === 'TURN2' ? el?.productTurn?.color || '' : el.product.color || '',
            productImage: el?.type === 'TURN2' ? el?.productTurn?.image || '' : el.product.image || '',
            productCode: el?.type === 'TURN2' ? el?.productTurn?.code || '' : el.product.code || '',

            product: el?.product,
            productTurn: el?.productTurn,
            estimateDevice: el?.estimateDevice,
            discountPeriod: el?.discountPeriod,
            discountTurn1: el?.discountTurn1,
            changeAmount: el?.changeAmount,
            coupon: el?.coupon,
            couponDiscount: el?.coupon_discount,
            remark_condition: el?.consider.remark_condition,
            consider_status: el?.consider?.status,
            purchaseDealPDF: el?.purchaseDealPDF,
          };
        });

        setDefaultValue(data);
        setVisibleApproveVividModal(true);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleProductGroup = (value) => {
    if (!value) {
      return '';
    }
    switch (true) {
      case value?.includes('iPhone'):
        return 'Smartphone';
      case value.includes('iPad'):
        return 'Tablet';
      default:
        return value;
    }
  };

  const deleteConsider = async (id) => {
    try {
      const res = await POST(DELETE_CONSIDER, {registerId: id});
      const {success, message} = res;
      if (success) {
        /* CHATCONE: 4.2 CANCEL_PROMISE ยกเลิกนัดทำสัญญา */
        await handleChatconeCancelPromise(id);

        Modal.success({
          title: message,
          afterClose: () => {
            getDataConsider(valueSearch);
            setSelectedRowKeys([]);
          },
        });
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const noPromiseConsider = async (record) => {
    try {
      const {registerId} = record;
      const res = await POST(EDIT_NO_PROMISE_CONSIDER, {registerId: registerId});
      const {success, message} = res;
      if (success) {
        /* CHATCONE: 3.1 NO_PROMISE ไม่นัดทำสัญญา */
        await handleChatconeNoPromise(record);

        Modal.success({
          title: message,
          afterClose: () => {
            getDataConsider(valueSearch);
          },
        });
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const openModal = (record, name) => {
    if (record) {
      setAction(name);
      setDefaultValue(record);
      if (name === 'remark') {
        setVisibleEditModal(true);
      } else if (name === 'APPROVE' || name === 'VIEW') {
        if (name === 'APPROVE' && record.status.code === 'WAIT' && user && user.role === 'Admin') {
          considerReceive(record);
        } else {
          let company = record?.company === '' ? 'DAPP' : record?.company;
          setCompany(company);
          if (company === 'VIVID') {
            getConsiderDetailVivid(record.registerId);
          } else {
            getConsiderDetail(record.registerId);
          }
        }
      } else if (name === 'FOLLOWUP' || name === 'APPOINTMENT') {
        getConsiderDetailByID(record, name);
      } else if (name === 'NO_APPOINTMENT') {
        onConfirmNoPromiseConsider(record);
      } else if (name === 'mainSocial') {
        setVisibleEditSocialModal(true);
      } else if (name === 'CANCEL_CONSIDER') {
        setVisibleCancelConsiderModal(true);
      } else if (name === 'SEND_CHATCONE') {
        setVisibleChatconeFollowContractModal(true);
      } else if (name === 'EDIT_SOCIAL_ID') {
        setVisibleEditSocialIdModal(true);
      }
    }
  };

  const getConsiderDetailByID = async (record, ref) => {
    const {consider, fullName, phoneNumber, registerId, channel, chatcone, type} = record;
    try {
      const res = await GET(GET_CONSIDER_DETAIL_BY_ID(registerId, consider.id));
      const {success, result} = res;

      if (success) {
        const {considerId, start, end, branch, remark, remark_appointment, remark_consider} = result;

        history.push({
          pathname: '/scheduler-dashboard',
          state: {
            data: {
              fullName,
              phoneNumber,
              start,
              end,
              branch,
              status: ref === 'FOLLOWUP' ? 'FOLLOWUP' : 'WAIT_PROMISE',
              considerId,
              registerId,
              type: ref,
              remark,
              remark_appointment,
              full_name: fullName,
              channel: channel,
              chatcone: chatcone,
              contract_type: type?.code,
              remark_consider,
            },
          },
        });
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const onConfirmCancel = () => {
    Modal.confirm({
      title: 'ยืนยันเปลี่ยนสถานะเป็น ยกเลิกนัด!',
      icon: <Icon.warningAlert />,
      content: `จำนวน ${selectedRowKeys.length} รายการ`,
      onOk() {
        deleteConsider(selectedRowKeys);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onClearCustom = (setValue, formSearch) => {
    formSearch.map((val) => {
      return setValue(val.name, null);
    });

    setDefaultSearch({
      firstName: '',
      lastName: '',
      dateType: '',
      startDate: '',
      endDate: '',
      status: null,
      type: '',
      statusMonitor: null,
      walk_in: '',
      productHand: '',
      remark: '',
    });

    setRequiredDate(false);

    pageRef.current = 1;
    sizeRef.current = 10;
  };

  const onClearCustomField = () => {
    setDefaultSearch({...defaultSearch, status: null, statusMonitor: null});
  };

  // const onPageSizeChangeCustom = (pageData, sizeData) => {
  //   const sizeChange = sizeRef.current !== sizeData;
  //   if (sizeChange) {
  //     pageRef.current = 1;
  //   } else {
  //     pageRef.current = pageData;
  //   }
  //   sizeRef.current = sizeData;
  //   setPagination({
  //     page: pageRef.current,
  //     size: sizeRef.current,
  //   });
  // };

  const fetchDataForExport = async () => {
    setLoading(true);

    let params = '';

    Object.keys(filterMemo).forEach((key) => {
      if (filterMemo[key] !== '' && key !== 'page' && key !== 'size') {
        params += `&${key}=${filterMemo[key]}`;
      }
    });

    if (params) {
      params = '?' + params.substring(1);
    }

    const response = await getConsiderDataExport(params);
    if (response.status === 200) {
      const {success, result} = response.data;

      if (success) {
        const data =
          result &&
          result.data.map((el, i) => {
            return {
              ...el,
              key: el.registerId,
              rowNo: i + 1,
              fullName: el.profile.fullName,
              userGrade: el?.userGrade,
              phoneNumber: el?.profile?.phoneNumber,
              facebook: el?.profile?.facebook,
              lineId: el?.profile?.lineId,
              typeStatement: el.statement ? el.statement?.type : '',
              registerDate: moment(el.registerDate).format('DD/MM/YYYY'),
              statusName: (el.status && el.status.text) || '',
              appointmentDate: el.appointment?.date ? el.appointment && moment(el.appointment.date).format('DD/MM/YYYY HH:MM') : '',
              branch: el.appointment?.branch ? el.appointment.branch.branch_name : '',
              interestedBranch: el?.interestedBranch !== '' ? el?.interestedBranch : 'ON_HOLD',
              createdLatestAgentEmail: el?.chatcone?.created_latest_agent_email
                ? dayjs(el.chatcone?.created_latest_agent_email).format('DD/MM/YYYY HH:mm')
                : '',
            };
          });

        let prepareData = [...new Map(data.map((item) => [item['key'], item])).values()];

        const dataEx = prepareData.map((el, i) => {
          return {
            // 'เลขที่': i + 1,
            'ชื่อ-นามสกุล': el?.fullName.trim() || '',
            เบอร์โทรศัพท์: convertStrToFormat(el?.phoneNumber, 'phone_number') || '',
            Facebook: el?.facebook || '',
            'Line ID': el?.lineId || '',
            เอกสารรายได้: el?.statement?.type || '',
            วันที่ลงทะเบียน: el?.registerDate || '',
            สถานะ: (el.status && el.status.text) || '',
            'Walk in': el?.walk_in || '',
            'ผู้แก้ไข Walk in': el?.updatedWalkinUser || '',
            Platform: el?.platform || '',
            'สถานะ Blacklist': el?.statusBlacklistName,
            ประเภทสัญญา: (el?.type && el?.type.text) || '',
            เลขที่สัญญา: el?.promise || '',
            วันที่นัดหมาย: el?.appointmentDate || '',
            สาขาที่นัดทำสัญญา: el?.branch || '',
            สาขาที่สนใจ: el?.interestedBranch !== '' ? el?.interestedBranch : 'ยังไม่ได้ตัดสินใจ',
            ลักษณะสินค้า: el?.productHands || '',
            สินค้า: el.product?.text == null ? '' : el.product?.model?.brand.text + ' ' + el.product?.model?.text,
            ความจุ: el.product?.storage ? el.product?.storage?.text : '',
            สี: el.product?.color ? el.product?.color?.text : '',
            สินค้าคอมโบ: el?.combo || '',
            'เกรดลูกค้า ก่อนทำสัญญา': el?.customer_grade || '',
            'เกรดลูกค้า หลังทำสัญญา': el?.userGrade || '',
            'A Score': el?.aLocalScale || '',
            ช่องทางติดต่อหลัก: el?.main_social || '',
            หมายเหตุ: removeNewlineText(el?.remark) || '',
            'รู้จัก Ufriend จาก': el?.knownUfriendFrom?.label || '',
            'หมายเหตุ(จากลูกค้า)': el?.othersDetails || '',
            ผู้รับงาน: el?.user_update || '',
            ผู้นัดทำสัญญา: el?.user_create || '',
            วันที่กดนัดทำสัญญา: el?.create_date_appointment ? moment(el.create_date_appointment).format('DD/MM/YYYY HH:mm') : '',
            'agent email': el?.chatcone?.agent_email || '',
            tag: el?.chatcone?.tag || '',
            channel: el?.channel ? `${el?.channel}` : '',
            'social id': el?.chatcone?.social_id ? `=""${el?.chatcone?.social_id}""` : '',
            'latest agent email': el?.chatcone?.latest_agent_email,
            'วันเวลาที่บันทึก latest agent email': el?.createdLatestAgentEmail,
          };
        });
        setDataExport(dataEx);
        setLoading(false);
        if (params !== '') {
          setVisibleModal(true);
        }
      }
    } else {
      setLoading(false);
      const {message, error} = response.data;

      Modal.error({
        title: message || '',
        content: error || '',
      });
    }
  };

  const onClose = () => {
    setVisibleModal(false);
  };

  const getBlacklistLink = async (idCard, statusBlacklist) => {
    if (statusBlacklist === 'BLACKLIST' || statusBlacklist === 'WARNING') {
      try {
        const res = await POST(GET_BLACKLIST_LINK, {id_card: idCard});
        const {success, result} = res;
        if (success) {
          infoBlacklist(result.blacklist);
        }
      } catch (err) {
        console.error('getBlacklistLink error ---> ', err);
      }
    }
  };

  const infoBlacklist = (data) => {
    return Modal.info({
      width: '1300px',
      title: 'รายชื่อลิงก์',
      content: <BlacklistLinkModal data={data} />,
    });
  };

  const handleChangeDate = (data, ref) => {
    // let value = data === undefined ||clo
    // if ((ref === 'dateType' && data === undefined) || ((ref === 'startDate' && data === null) && (ref === 'endDate' && data === null))) {
    //   setRequiredDate(false);
    // } else {
    //   setRequiredDate(true);
    // }

    // setRequiredDate(true);

    // if ((ref === 'dateType' && data === undefined) && (_.isEmpty(defaultSearch.startDate) && _.isEmpty(defaultSearch.endDate))) {
    //   setRequiredDate(false);
    // }
    // else if ((ref === 'startDate' && data === null) && (_.isEmpty(defaultSearch.dateType) && _.isEmpty(defaultSearch.endDate))) {
    //   setRequiredDate(false);
    // }

    // else if ((ref === 'endDate' && data === null) && (_.isEmpty(defaultSearch.dateType) && _.isEmpty(defaultSearch.startDate))) {
    //   setRequiredDate(false);
    // } else {
    //   setRequiredDate(true);
    // }

    switch (ref) {
      case 'startDate':
        setDefaultSearch({...defaultSearch, startDate: data?.toDate() || data});
        break;
      case 'endDate':
        setDefaultSearch({...defaultSearch, endDate: data?.toDate() || data});
        break;
      case 'dateType':
        setDefaultSearch({...defaultSearch, dateType: data || ''});
        break;
      default:
        break;
    }
  };

  const onChangeSelectStatus = (value) => {
    setDefaultSearch({...defaultSearch, status: value, statusMonitor: value});
  };

  const onConfirmNoPromiseConsider = async (record) => {
    Modal.confirm({
      title: 'ยืนยันไม่นัดทำสัญญา',
      icon: <Icon.warningAlert />,
      content: 'ยืนยันไม่นัดทำสัญญาใช่หรือไม่?',
      onOk: async () => {
        await noPromiseConsider(record);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const handleChatconeNoPromise = async (record) => {
    /* CHATCONE: 3.1 NO_PROMISE ไม่นัดทำสัญญา */
    const {registerId, chatcone} = record;

    if (chatcone?.social_id) {
      const setInput = {
        process_type: 'NO_PROMISE',
        ref_id: registerId,
        route_from: `${APP_URL}${pathname}`,
      };

      await updateTagChatconeByProcessFn({...setInput});
    }
  };

  const handleChatconeCancelPromise = async (registerIds) => {
    /* CHATCONE: 4.2 CANCEL_PROMISE ยกเลิกนัดทำสัญญา */
    const chatconeData = _.filter(dataSource, (item) => item?.chatcone?.social_id && registerIds?.includes(item.registerId));
    const initInput = {
      process_type: 'CANCEL_PROMISE',
      ref_name: 'user_before_verify',
      route_from: `${APP_URL}${pathname}`,
    };

    if (!_.isEmpty(chatconeData)) {
      const inputUpdate = _.map(chatconeData, (item) => {
        return {
          social_id: item?.chatcone?.social_id,
          channel_id: item?.chatcone?.channel_id,
          fullName: item?.fullName,
          ref_id: item?.registerId,
          register_id: item?.registerId,
          ref_name: 'user_before_verify',
        };
      });

      const setInputUpdateTagBulk = {
        ...initInput,
        inputUpdate,
      };

      await updateTagBulkChatconeFn({...setInputUpdateTagBulk});
    }
  };

  return (
    <Wrapper>
      {updateTagChatconeByProcessLoading || updateTagBulkChatconeLoading ? <Spinners /> : null}
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          {optionsConsiderStatus.length > 0 && (
            <>
              <div className="line-div"></div>
              <div style={{textAlign: 'right', marginTop: '-18px'}}>
                {pipelineStatus.map((el, i) => {
                  return (
                    <p className={el.className} style={el.styled} key={i}>
                      {el.text}
                      <p className="content-timeline">{el.count}</p>
                    </p>
                  );
                })}
              </div>
              <PageLayout
                searchLayout={{
                  title: 'พิจารณาลูกค้าและนัดทำสัญญา',
                  icon: <SettingOutlined />,
                  spanSearch: 24,
                  formJustify: 'left',
                  formName: 'considerSearchForm',
                  formSearch: MonitorCustomerSearchForm({
                    defaultSearch,
                    onClearCustomField,
                    handleChangeDate,
                    onChangeSelectStatus,
                    options: {optionsConsiderStatus, typeNew, dateType, branchSpecificList, optionWalkIn, optionProductHandFilter, optionSignOnline},
                    data: {requiredDate},
                  }),
                  onSearch: (data) => actionFilter(data),
                  dataSearchDefault: defaultSearch,
                  onClearCustom,
                  // fetchValue: (data) => fetchDataForm(data),
                }}
                tableLayout={{
                  columns: MonitorCustomerColumn({
                    options: {optionsConsiderStatus, optionsBranch, optionsBranchUser, optionProductHandFilter},
                    openModal,
                    user,
                    getBlacklistLink,
                  }),
                  dataSource: dataSource,
                  rowSelection: {
                    selectedRowKeys,
                    onChange: (keys, record) => {
                      setSelectedRowKeys(keys);
                    },
                    getCheckboxProps: (record) => ({
                      disabled: ['REJECTED', 'SUCCESS_PROMISE', 'CANCEL_PROMISE', 'CANCEL_CONSIDER', 'WAIT_APPOINTMENT_PROMISE'].includes(
                        record?.status?.code,
                      ),
                    }),
                  },
                  extraContent: (
                    <ContainerButton right>
                      <ButtonTheme useFor="REJECT" title={'ยกเลิกนัด'} disabled={!selectedRowKeys.length > 0} onClick={onConfirmCancel} />
                      {perAction.includes('excel') ? (
                        <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} onClick={() => fetchDataForExport()} />
                      ) : (
                        ''
                      )}
                    </ContainerButton>
                  ),
                  pagination: {
                    showSizeChanger: true,
                    current: pagination.page,
                    pageSize: pagination.size,
                    // onChange: (p, s) => onPageSizeChangeCustom(p, s),
                    onChange: (p, s) => setPagination({page: p, size: s}),
                    pageSizeOptions: ['10', '20', '30', '40', '100', '200', '300'],
                    total: totalSize || 0,
                    showTotal: (totalSize, range) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                    defaultPageSize: pagination.size,
                    defaultCurrent: 1,
                  },
                }}
              />
            </>
          )}
        </Spin>
      </Card>
      <EditRemarkMonitorModal
        visible={visibleEditModal}
        title={'แก้ไขหมายเหตุ'}
        onClose={() => setVisibleEditModal(false)}
        data={{reApi: getDataConsider, record: defaultValue, valueSearch}}
      />
      <EditMainSocialMonitorModal
        visible={visibleEditSocialModal}
        title={''}
        onClose={() => setVisibleEditSocialModal(false)}
        data={{reApi: getDataConsider, record: defaultValue, valueSearch}}
      />
      <ApproveCustomerOldModal
        visible={visibleApproveOldModal}
        title={'รายละเอียดข้อมูล (แบบเก่า)'}
        onClose={() => setVisibleApproveOldModal(false)}
        data={{reApi: getDataConsider, record: defaultValue, considerId, valueSearch, company, action}}
      />
      <ApproveCustomerVividModal
        visible={visibleApproveVividModal}
        title={'รายละเอียดข้อมูล (แบบใหม่)'}
        onClose={() => setVisibleApproveVividModal(false)}
        data={{reApi: getDataConsider, record: defaultValue, considerId, valueSearch, company, action}}
      />
      <CancelConsiderModal
        visible={visibleCancelConsiderModal}
        title={'คอนเฟิร์มสถานะไม่ใช้งาน'}
        onClose={() => setVisibleCancelConsiderModal(false)}
        data={{reApi: getDataConsider, record: defaultValue, valueSearch}}
      />
      <Modal
        title={'ต้องการดาวน์โหลด Excel?'}
        open={visibleModal}
        width={550}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <ContainerButton right>
          <CSVLink data={dataExport} filename={'รายงานพิจารณาลูกค้า'}>
            <ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={() => onClose()} />
          </CSVLink>
          <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
        </ContainerButton>
      </Modal>

      <ChatconeFollowContractModal
        visible={visibleChatconeFollowContractModal}
        title={'ยืนยันการส่งแจ้งเตือน'}
        content={'แจ้งเตือนติดตามนัดลูกค้าทำสัญญา'}
        onClose={() => setVisibleChatconeFollowContractModal(false)}
        input={defaultValue}
      />
      <EditSocailIdAndTagsModal
        visible={visibleEditSocialIdModal}
        title={'แก้ไข Social Id'}
        onClose={() => setVisibleEditSocialIdModal(false)}
        data={{
          process_type: 'CONSIDER_EDIT_SOCIAL_ID',
          ref_id: defaultValue?.registerId,
          ref_name: 'user_before_verify',
          chatcone: defaultValue?.chatcone,
          reApi: getDataConsider,
          valueSearch,
        }}
      />
    </Wrapper>
  );
};
export default MonitorCustomer;
