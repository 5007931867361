import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, InputNumber, Space } from 'antd';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
import Modal from '../../../components/modal';
import { translateType } from '../../../screens/transactions/utils';
import * as number from '../../../utils/number';
import { useReceiptPaymentDispatch, useReceiptPaymentState } from '../hooks/useReceiptPayment';
import { IconView } from './component';
import * as logic from './logic';
import { Cell, CellRow, Table, TableBody, TableColGroup, TableContainer, TableData, TableHead, TableHeads, TableRow } from './styled-table-component';
import * as validate from './validate';

const COL1_WIDTH = 220;
const SWAL_COLOR = '#1890ff';

const TableView = (props) => {
  const { user } = props;

  const { dispatchGetSummaryAmount, dispatchUpdatePaidParams, dispatchUpdateTransactionStatus, dispatchGetAmountContract } = useReceiptPaymentDispatch();
  const { receiptSummary, receiptFilterState, receiptPaidParamsState } = useReceiptPaymentState();
  const history = useHistory();

  const refNodeInputFee = useRef(null);
  const [visibleChild, setVisibleChild] = useState({});
  const [visibleEditCell, setVisibleEditCell] = useState({ id: null, period: null, type: null });
  const [dataTableState, setDataTableState] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');

  const onExpandChild = (period) => {
    const expanded = { ...visibleChild };
    if (expanded[period]) {
      delete expanded[period];
    } else {
      expanded[period] = true;
    }
    setVisibleChild(expanded);
  };

  const onConfirmRemoveTransaction = (id, transDisplayId) => {
    if (!id || !receiptFilterState?.contractNo) {
      Swal.fire({
        title: 'ไม่สามารถทำรายการนี้ได้ กรุณา Refresh ฺBrowser แล้วลองใหม่อีกครั้ง',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: SWAL_COLOR,
      });
      return;
    }
    const reqRemove = { status: 'CANCELED', update_by: user.displayName };
    const reqUpdate = {
      contractNumber: receiptFilterState.contractNo,
      params: {
        transaction_date: logic.getDateQueryFormat(receiptPaidParamsState?.transaction_date || null),
      },
      reqSummaryAmount: {
        contractNumber: receiptFilterState.contractNo,
        data: {
          transaction_date: logic.getDateQueryFormat(receiptPaidParamsState?.transaction_date || null),
          paid_amount: logic.transAmountToNumber(receiptPaidParamsState?.paid_amount),
        },
      },
      callToFn: () => {
        history.push({ pathname: '/customer', search: `?id_card=${receiptSummary?.summary_info?.user?.id_card}` });
      },
      additionalMessage: `ไม่สามารถลบใบเสร็จ ${transDisplayId} ต้องลบใบเสร็จที่ได้รับการยืนยันล่าสุดก่อน`,
    };
    if (lodashGet(receiptPaidParamsState?.additional_charges, '[0]')) {
      reqUpdate.reqSummaryAmount.data.additional_charges = [...receiptPaidParamsState.additional_charges];
    }
    dispatchUpdateTransactionStatus(id, reqRemove, reqUpdate);
  };

  const onRemoveTransaction = (id, transDisplayId) => {
    Swal.fire({
      title: 'คุณต้องการยกเลิกรายการจ่ายนี้หรือไม่',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      showCancelButton: true,
      confirmButtonColor: SWAL_COLOR,
    }).then((res) => {
      const { isConfirmed } = res || {};
      if (isConfirmed) {
        onConfirmRemoveTransaction(id, transDisplayId);
      }
    });
  };

  const onPreviewLink = (url) => {
    setOpenModal(true);
    setPreviewUrl(url);
  };

  const resetEditState = () => {
    refNodeInputFee.current = '';
    setVisibleEditCell({ id: null, period: null, type: null });
  };

  const onEditFee = (id, period, type) => {
    setVisibleEditCell({ id, period, type });
  };

  const onCancelEditFee = () => {
    resetEditState();
  };

  const checkIsEnableEdit = (id, period, type) => {
    return visibleEditCell.id === id && visibleEditCell.period === period && visibleEditCell.type === type;
  };

  const checkIsStatusActive = () => {
    return lodashGet(receiptSummary, 'summary_info.contract.status', null) === logic.enumContractStatus.active;
  };

  const checkIsHideEdit = (id, period, type) => {
    if (lodashGet(receiptSummary, 'summary_info.contract.status', null) !== logic.enumContractStatus.active) {
      return true;
    }
    if (!visibleEditCell.id || typeof period !== 'number' || !visibleEditCell.type) {
      return false;
    }
    return visibleEditCell.id !== id || visibleEditCell.period !== period || visibleEditCell.type !== type;
  };

  const onChangeEditFeeValue = (value) => {
    refNodeInputFee.current = value;
  };

  const onSaveFee = (period, type) => {
    if (typeof period !== 'number' || !type) {
      return;
    }
    const feeValue = refNodeInputFee.current;
    const errorValue = validate.validateFeeValue(feeValue);
    if (errorValue) {
      Swal.fire({
        title: 'จำนวนเงินไม่ถูกต้อง',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: SWAL_COLOR,
      });
      return;
    }
    const charges = logic.transAdditionalCharges(receiptPaidParamsState?.additional_charges, period, type, feeValue) || [];
    const params = {
      transaction_date: logic.getDateQueryFormat(receiptPaidParamsState.transaction_date || null),
      paid_amount: parseInt(receiptPaidParamsState.paid_amount, 10) || 0,
      additional_charges: [...charges],
    };
    if (receiptPaidParamsState.paid_type === 'CONTRACT') {
      params.type = 'CLOSED_CONTRACT';
      params.paid_amount = receiptSummary?.amount_contract;
    }

    dispatchGetAmountContract(receiptFilterState?.contractNo, params); //  /close
    dispatchGetSummaryAmount(receiptFilterState?.contractNo, params);

    const paidParams = {
      ...receiptPaidParamsState,
      additional_charges: [...charges],
    };
    dispatchUpdatePaidParams(paidParams);
    resetEditState();
  };

  useEffect(() => {
    if (receiptSummary?.summary_info) {
      const dataTable = logic.createTableData(receiptSummary?.summary_info?.payment);
      setDataTableState(dataTable);
    }
  }, [receiptSummary?.summary_info]);

  useEffect(() => {
    setVisibleChild({});
  }, [receiptFilterState?.contractNo]);

  return (
    <TableContainer>
      <Modal open={openModal} setOpen={setOpenModal} noButton heightAuto onRequestClose={() => setPreviewUrl('')}>
        {previewUrl ? <img width="400px" src={previewUrl} alt="" /> : <p>image cannot load</p>}
      </Modal>
      <Table fullWidth>
        <TableColGroup span="9" />
        <TableColGroup span="3" />
        <TableHeads>
          <TableRow>
            <TableHead width={COL1_WIDTH}>งวดที่</TableHead>
            <TableHead>กำหนดชำระ</TableHead>
            <TableHead>Ref</TableHead>
            <TableHead>ค่างวด</TableHead>
            {/* <TableHead>เงินต้น</TableHead> */}
            {/* <TableHead>ดอกเบี้ย</TableHead> */}
            <TableHead>{'ล่าช้า(วัน)'}</TableHead>
            <TableHead>ค่าปรับ</TableHead>
            <TableHead>ค่าทวงถาม</TableHead>
            <TableHead>ยอดชำระ</TableHead>
            <TableHead>ค้างชำระ</TableHead>
            <TableHead>รวมที่ต้องจ่าย</TableHead>
          </TableRow>
        </TableHeads>
        <TableBody>
          {Array.isArray(dataTableState) &&
            dataTableState.map((item, index) => {
              if (item.is_transaction) {
                if (lodashGet(visibleChild, `${item.period}`, false)) {
                  return (
                    <TableRow key={index.toString()} success={item.is_paid} danger={item.is_remain}>
                      <TableData textLeft width={COL1_WIDTH}>
                        <Cell>
                          <CellRow>
                            {'slip_url' in item && item.slip_url && <IconView name="link" onClick={() => onPreviewLink(item.slip_url)} />}
                            {item.type == 'INSTALLMENT' && item.is_enable_delete && checkIsStatusActive() && (
                              <IconView name="delete" onClick={() => onRemoveTransaction(item.trans_id, item.col_3)} />
                            )}
                          </CellRow>
                          <span>{item.col_1}</span>
                          <span>{translateType(item.type) || 'N/A'}</span>
                        </Cell>
                      </TableData>
                      <TableData textLeft>{logic.getDateLabel(item.col_2)}</TableData>
                      <TableData textLeft>{item.col_3}</TableData>
                      <TableData>{number.formatComma(item.col_4, 0)}</TableData>
                      <TableData>{number.formatComma(item.col_5, 0)}</TableData>
                      <TableData>{number.formatComma(item.col_6, 0)}</TableData>
                      <TableData>{number.formatComma(item.col_7, 0)}</TableData>
                      <TableData>{number.formatComma(item.col_8, 0)}</TableData>
                      <TableData>{item.col_9}</TableData>
                      <TableData focusLess>{number.formatComma(item.col_10)}</TableData>
                      {/* <TableData focusLess>{number.formatComma(item.col_11)}</TableData> */}
                      {/* <TableData focusLess>{number.formatComma(item.col_12)}</TableData> */}
                    </TableRow>
                  );
                }
                return null;
              }
              return (
                <TableRow key={index.toString()} success={item.is_paid} danger={item.is_remain}>
                  <TableData textLeft width={COL1_WIDTH}>
                    {item.is_has_child && (
                      <Cell>
                        <span>{item.col_1}</span>
                        <Button onClick={() => onExpandChild(item.period)}>
                          {lodashGet(visibleChild, item.period, false) ? 'ซ่อนรายการ' : 'ดูรายการ'}
                          {lodashGet(visibleChild, item.period, false) ? <UpOutlined /> : <DownOutlined />}
                        </Button>
                      </Cell>
                    )}
                    {!item.is_has_child && item.col_1}
                  </TableData>
                  <TableData textLeft>{logic.getDateLabel(item.col_2)}</TableData>
                  <TableData>{item.col_3}</TableData>
                  <TableData>{number.formatComma(item.col_4, 0)}</TableData>
                  <TableData>{number.formatComma(item.col_5, 0)}</TableData>
                  {/* <TableData>{item.col_6}</TableData> */}
                  <TableData> 
                    {!item.is_remain && number.formatComma(item.col_6, 0)}
                    {item.is_remain && (
                      <>
                        {checkIsHideEdit(item.id, item.period, logic.enumEditFee.overdue) ? (
                          <span>
                            {logic.getPreChangeCharges(
                              receiptPaidParamsState?.additional_charges,
                              item.period,
                              logic.enumEditFee.overdue,
                              item.col_6,
                            )}
                          </span>
                        ) : (
                          <Cell>
                            {!checkIsEnableEdit(item.id, item.period, logic.enumEditFee.overdue) && (
                              <>
                                <IconView
                                  name="edit"
                                  tooltipLabel="แก้ไขค่าปรับ"
                                  onClick={() => onEditFee(item.id, item.period, logic.enumEditFee.overdue)}
                                />
                                <span>
                                  {logic.getPreChangeCharges(
                                    receiptPaidParamsState?.additional_charges,
                                    item.period,
                                    logic.enumEditFee.overdue,
                                    item.col_6,
                                  )}
                                </span>
                              </>
                            )}
                            {checkIsEnableEdit(item.id, item.period, logic.enumEditFee.overdue) && (
                              <>
                                <Space>
                                  <IconView
                                    name="save"
                                    tooltipLabel="บันทึกค่าปรับ"
                                    onClick={() => onSaveFee(item.period, logic.enumEditFee.overdue)}
                                  />
                                  <IconView name="cancel" tooltipLabel="ยกเลิกแก้ไขค่าปรับ" onClick={onCancelEditFee} />
                                </Space>
                                <InputNumber
                                  size="small"
                                  placeholder={`เดิม ${number.formatComma(item.col_6, 0)}`}
                                  style={{ minWidth: 60, marginLeft: '1rem', textAlign: 'end' }}
                                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                  onChange={(value) => onChangeEditFeeValue(value)}
                                />
                              </>
                            )}
                          </Cell>
                        )}
                      </>
                    )}
                  </TableData>
                  <TableData>
                    {!item.is_remain && number.formatComma(item.col_7, 0)}
                    {item.is_remain && (
                      <>
                        {checkIsHideEdit(item.id, item.period, logic.enumEditFee.warning) ? (
                          <span>
                            {logic.getPreChangeCharges(
                              receiptPaidParamsState?.additional_charges,
                              item.period,
                              logic.enumEditFee.warning,
                              item.col_7,
                            )}
                          </span>
                        ) : (
                          <Cell>
                            {!checkIsEnableEdit(item.id, item.period, logic.enumEditFee.warning) && (
                              <>
                                <IconView
                                  name="edit"
                                  tooltipLabel="แก้ไขค่าทวงถาม"
                                  onClick={() => onEditFee(item.id, item.period, logic.enumEditFee.warning)}
                                />
                                <span>
                                  {logic.getPreChangeCharges(
                                    receiptPaidParamsState?.additional_charges,
                                    item.period,
                                    logic.enumEditFee.warning,
                                    item.col_7,
                                  )}
                                </span>
                              </>
                            )}
                            {checkIsEnableEdit(item.id, item.period, logic.enumEditFee.warning) && (
                              <>
                                <Space>
                                  <IconView
                                    name="save"
                                    tooltipLabel="บันทึกค่าทวงถาม"
                                    onClick={() => onSaveFee(item.period, logic.enumEditFee.warning)}
                                  />
                                  <IconView name="cancel" tooltipLabel="ยกเลิกแก้ไขค่าทวงถาม" onClick={onCancelEditFee} />
                                </Space>
                                <InputNumber
                                  size="small"
                                  placeholder={`เดิม ${number.formatComma(item.col_7, 0)}`}
                                  style={{ minWidth: 60, marginLeft: '1rem', textAlign: 'end' }}
                                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                  onChange={(value) => onChangeEditFeeValue(value)}
                                />
                              </>
                            )}
                          </Cell>
                        )}
                      </>
                    )}
                  </TableData>
                  <TableData>{item.col_8}</TableData>
                  <TableData focusLess>{number.formatComma(item.col_9, 0)}</TableData>
                  <TableData focusLess>{number.formatComma(item.col_10, 0)}</TableData>
                  {/* <TableData focusLess>{number.formatComma(item.col_11, 0)}</TableData> */}
                  {/* <TableData focusLess>{number.formatComma(item.col_12, 0)}</TableData> */}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TableView.propTypes = {
  user: PropTypes.any,
};

TableView.defaultProps = {
  user: null,
};

export default TableView;
