import React, {useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Select, Modal} from 'antd';
import axios from 'axios';
import {API_CHATCONE_SERVICE} from '../../../env.js';

const SelectChatconeSocial = (props) => {
  const {filter, placeholder, handleChange, dataResponse, multiple, ...otherProp} = props;
  const {channel_name, channel_id} = filter || {};

  const [dataSc, setDataSc] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_CHATCONE_SERVICE}/masterdata/chatcone-social-id`, filter);

      const result = response?.data?.data;
      setDataSc(
        _.map(result, (dt) => {
          return {
            label: dt.channel_name,
            value: dt.channel_id,
            source: dt,
          };
        }),
      );
      if (dataResponse) {
        dataResponse(result);
      }
    } catch (error) {
      Modal.error({
        title: 'ไม่สามารถดึงข้อมูล Chatcone Social ได้',
        onOk() {},
      });
      setDataSc([]);
    }
  };

  const inputOptions = useMemo(() => {
    return {
      input: filter ? {...filter} : {},
    };
  }, [channel_name, channel_id]);

  useEffect(() => {
    fetchData();
  }, [inputOptions]);

  return (
    <Select
      {...otherProp}
      style={{width: '100%'}}
      options={dataSc}
      placeholder={placeholder}
      showSearch
      onChange={(value) => handleChange(_.find(dataSc, (data) => data?.value === value))}
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    />
  );
};

SelectChatconeSocial.propTypes = {
  placeholder: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  filter: PropTypes.object,
  dataResponse: PropTypes.func,
};

export default SelectChatconeSocial;
