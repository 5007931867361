export const CouponDetailColumn = () => {
  return [
    {
      title: 'ลำดับ',
      dataIndex: 'no',
      key: 'no',
      width: '3%',
      align: 'center',
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'Code สำหรับลูกค้า',
      dataIndex: 'coupon_code',
      key: 'coupon_code',
      width: '20%',
    },
    {
      title: 'รายละเอียด',
      dataIndex: 'coupon_description',
      key: 'coupon_description',
      width: '32%',
    },
    {
      title: 'ส่วนลด',
      dataIndex: 'coupon_discount',
      key: 'coupon_discount',
      width: '20%',
    },
  ];
};
