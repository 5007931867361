import { create } from 'apisauce';
import axios from 'axios';
import { API_URL } from '../../env.js';
import { logout } from '../../services/axiosConfig.js';

export const MODE_API = {
  UAT: API_URL,
  PRODUCTION: API_URL,
};

export const CONNECT_API = MODE_API.UAT;

export const CONNECT_API_EXCEL = `${API_URL}/static/template/`;

const apis = axios.create({
  baseURL: CONNECT_API,
  timeout: 300000, // 10 seconds
});

const api = create({ axiosInstance: apis });

apis.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      logout()
    }
    return Promise.reject(error);
  }
);


export const exportDownPayment = async (downPaymentNumber) => {
  try {
    return await api.post(`${API_URL}/admin/down-payment/${downPaymentNumber}/export`, {}, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/octet-stream',
      },
      responseType: 'blob'
    });
  } catch (error) {
    return error;
  }
};