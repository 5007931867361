import {SV_API, handleSVApiError} from '../utils';
const path = 'admin';

export const getContractDefault = async (formData) => {
  try {
    const res = await SV_API.post(`${path}/contract/get-contract-default`, formData);
    return res.data;
  } catch (error) {
    return handleSVApiError(error);
  }
};

export const getCheckCoupon = async (formData) => {
  try {
    const res = await SV_API.post(`${path}/contract/checkCoupon`, formData);
    return res.data;
  } catch (error) {
    return handleSVApiError(error);
  }
};

export const createContract = async (formData) => {
  try {
    const res = await SV_API.post(`${path}/contract/create_contract`, formData);
    return res.data;
  } catch (error) {
    return handleSVApiError(error);
  }
};
