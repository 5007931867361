import {Select} from 'formik-antd';
import {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useFormikContext} from 'formik';
import {getOption, getOptionBranchSpecific} from './api';
import {GET, GET_BRANCH_OPTION_SPECIFIC} from '../../services';

const BranchsOption = (props) => {
  const [choices, setChoices] = useState([]);
  const admin_branch = localStorage.getItem('admin_branch');

  useEffect(() => {
    (async () => {
      const res = await getOptionBranchSpecific();
      const {success, result} = res;
      if (success) {
        let branchFilter = result;
        if (admin_branch !== 'CENTER') {
          branchFilter = result.filter((el) => el.value === admin_branch);
        }

        setChoices(branchFilter);
      }
    })();
  }, []);

  const filterOption = (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return <Select {...props} showSearch optionFilterProp="value" options={choices} filterOption={filterOption}></Select>;
};

BranchsOption.propTypes = {
  name: PropTypes.string.isRequired,
};

export default BranchsOption;
