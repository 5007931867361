import _ from 'lodash';
import React, {useState, useEffect} from 'react';
import {Modal, Tag, Row, Col, Image, Input, Button, Typography, Card} from 'antd';
import {ButtonTheme} from '../../../components/buttons';
import {EditOutlined} from '@ant-design/icons';
import {saveAs} from 'file-saver';
import {color} from '../../../resources/color';
import {SecondHandDetailForm, SecondHandDetailForm2} from '../form';
import {DEFAULT_NO_IMAGE} from '../../../env';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import GenSignedLink from '../../contract/components/GenSignedLink';
import {POST, PUT, EDIT_REMARK_SECOND_HAND, POST_APPROVE_DOC_NEW} from '../../../services/index';
import {TextSmall} from '../../../components/forms/text';

const {Text} = Typography;
const {TextArea} = Input;

export const DetailNewModal = ({title, visible, onClose, data}) => {
  const fields = SecondHandDetailForm();
  const fieldRemark = SecondHandDetailForm2();

  const menu_user = JSON.parse(localStorage.getItem('menu_user'));
  const permissions = menu_user.length > 0 && menu_user.filter((el) => el.head === 'SecondHand')[0].menu;
  const perAction = permissions.length > 0 && permissions.filter((el) => el.path === '/buy-second-hand-report')[0].actions;

  const [enabledEditRemark, setEnabledEditRemark] = useState(false);
  const [remark, setRemark] = useState(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({});
  const [visibleDetailImage, setVisibleDetailImage] = useState(false);
  const [detailImage, setDetailImage] = useState([]);
  const [visibleMobileImage, setVisibleMobileImage] = useState(false);
  const [mobileImage, setMobileImage] = useState([]);
  const [visibleDocumentImage, setVisibleDocumentImage] = useState(false);
  const [documentImage, setDocumentImage] = useState([]);

  const tagStatus =
    {
      SUCCESS: color.success,
      WAIT_FINANCE_APPROVE: color.tranfer,
      WAIT_ADMIN_APPROVE: color.tracking,
      WAIT_SIGNATURE: color.edit,
    }[value.status] || color.disabled;

  useEffect(async () => {
    if (JSON.stringify(data && data.record) !== '{}' && data.record) {
      const dataValue = Object.keys(data.record);
      const newValue = {};
      dataValue.forEach((val) => {
        newValue[val] = data.record[val];
      });

      const detailImg = [];
      if(data.record?.profile_image) detailImg.push(data.record?.profile_image);
      if(data.record?.book_bank_image) detailImg.push(data.record?.book_bank_image);

      const mobileImg = data?.record?.imageMobile || [];
      const documentImg = data?.record?.imageDocument || [];

      setValue(newValue);
      setDetailImage(detailImg || []);
      setMobileImage(mobileImg || []);
      setDocumentImage(documentImg || []);
      setRemark(data.record['remark']);
    }
  }, [data.record]);

  const showTerminateDate = ['TERMINATE'].includes(value.status);

  //เส้นกดอนุมัติ หรือไม่อนุมัติ
  const approveDoc = async (action) => {
    if (value.status !== 'WAIT_ADMIN_APPROVE') return;

    const payload = {
      id: value.key,
      status: 'WAIT_ADMIN_APPROVE',
      button: action,
    };

    try {
      const {success, message} = await POST(POST_APPROVE_DOC_NEW, payload);
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            data.reApi(data.valueSearch);
            onClose();
          },
        });
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message || 'เกิดข้อผิดพลาด',
        content: error || '',
      });
    }
  };

  const handleChangeRemark = (e) => setRemark(e.target.value);

  const onEditRemark = async () => {
    setLoading(true);
    try {
      const {success} = await PUT(EDIT_REMARK_SECOND_HAND(value.key), {remark});
      if (success) {
        data.reApi(data.valueSearch);
        data.reApi2(value?.document_no, 'VIVID');
        setEnabledEditRemark(false);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  //ฟังก์ชันดาวน์โหลดสัญญาตัวใหม่
  const downloadContract = async () => {
    try {
      if (!_.isEmpty(value['pdf_url'])) {
        saveAs(value['pdf_url'], value['document_no']);
      } else {
        throw new Error('กรุณาตรวจสอบอีกครั้ง')
      }
    } catch (err) {
      const {error} = err;
      Modal.error({
        title: 'ไม่พบไฟล์ใบสัญญาซื้อขาย',
        content: error,
      });
    }
  };

  return (
    <>
      <Modal title={title || 'modal'} open={visible} width={1000} onCancel={onClose} footer={null} maskClosable={false}>
        <div>
          <Row style={{marginBottom: '22px', marginTop: '22px'}}>
            <Col xs={{span: 24}} md={{span: 24}} xl={{span: 12}} lg={{span: 12}} style={{textAlign: 'left'}}>
              <Text strong>รูปคู่บัตรประชาชน/รูปหน้าบัญชีธนาคาร/รูปลายมือชื่อ</Text>
              <p></p>
              <div style={{textAlign: 'center', position: 'relative'}}>
                <Image
                  width={'auto'}
                  height={150}
                  src={detailImage[0]}
                  fallback={DEFAULT_NO_IMAGE}
                  preview={{
                    visible: false,
                  }}
                  onClick={() => setVisibleDetailImage(true)}
                />
                <div style={{display: 'none'}}>
                  <Image.PreviewGroup
                    preview={{
                      visible: visibleDetailImage,
                      onVisibleChange: (vis) => setVisibleDetailImage(vis),
                    }}>
                    {detailImage.map((el, i) => {
                      return <Image height={100} src={el} key={i} />;
                    })}
                  </Image.PreviewGroup>
                </div>
                {detailImage.length > 1 && (
                  <div style={{position: 'absolute', top: '20%', left: '44%'}}>
                    <Text style={{fontSize: 50, textShadow: '#000000 10px 0px 20px', color: 'white'}}>+{detailImage.length}</Text>
                  </div>
                )}
              </div>
              <p></p>
              <Text strong>รูปเครื่อง</Text>
              <p></p>
              <div style={{textAlign: 'center', position: 'relative'}}>
                <Image
                  width={'auto'}
                  height={150}
                  src={(mobileImage && mobileImage[0]) || []}
                  fallback={DEFAULT_NO_IMAGE}
                  preview={{
                    visible: false,
                  }}
                  onClick={() => setVisibleMobileImage(true)}
                />
                <div style={{display: 'none'}}>
                  <Image.PreviewGroup
                    preview={{
                      visible: visibleMobileImage,
                      onVisibleChange: (vis) => setVisibleMobileImage(vis),
                    }}>
                    {mobileImage.map((el, i) => {
                      return <Image height={100} src={el} key={i} />;
                    })}
                  </Image.PreviewGroup>
                </div>
                {mobileImage.length > 1 && (
                  <div style={{position: 'absolute', top: '20%', left: '44%'}}>
                    <Text style={{fontSize: 50, textShadow: '#000000 10px 0px 20px', color: 'white'}}>+{mobileImage.length}</Text>
                  </div>
                )}
              </div>
              <p></p>
              <Text strong>รูปเอกสาร</Text>
              <p></p>
              <div style={{textAlign: 'center', position: 'relative'}}>
                <Image
                  width={'auto'}
                  height={150}
                  src={(documentImage && documentImage[0]) || []}
                  fallback={DEFAULT_NO_IMAGE}
                  preview={{
                    visible: false,
                  }}
                  onClick={() => setVisibleDocumentImage(true)}
                />
                <div style={{display: 'none'}}>
                  <Image.PreviewGroup
                    preview={{
                      visible: visibleDocumentImage,
                      onVisibleChange: (vis) => setVisibleDocumentImage(vis),
                    }}>
                    {documentImage.map((el, i) => {
                      return <Image height={100} src={el} key={i} />;
                    })}
                  </Image.PreviewGroup>
                </div>
                {documentImage.length > 1 && (
                  <div style={{position: 'absolute', top: '20%', left: '44%'}}>
                    <Text style={{fontSize: 50, textShadow: '#000000 10px 0px 20px', color: 'white'}}>+{documentImage.length}</Text>
                  </div>
                )}
              </div>
            </Col>
            <Col xs={{span: 24}} md={{span: 24}} xl={{span: 12}} lg={{span: 12}}>
              <div>
                {value['status'] !== 'WAIT_SIGNATURE' && value['type'] === 'SECONDHAND' && !_.isEmpty(value['pdf_url']) ? (
                  <ContainerButton center>
                    <ButtonTheme
                      useFor="CUSTOM"
                      title="ดาวน์โหลดสัญญาซื้อขาย"
                      bgcolor={color.clear}
                      className="export"
                      onClick={() => downloadContract()}
                      style={{borderRadius: '5px'}}
                    />
                  </ContainerButton>
                ) : (
                  <></>
                )}
              </div>
              <p></p>
              <div style={{marginLeft: '20px'}}>
                {fields.map((field) =>
                  field.name === 'terminate_date' && !showTerminateDate ? null : (
                    <div key={field.key} style={{marginBottom: '10px', width: 'auto'}}>
                      <Row>
                        <Col span={6} style={{fontWeight: 'bold', marginRight: '10px'}}>
                          {field.label}
                        </Col>

                        {field.name === 'status' ? (
                          <Col span={6}>
                            <Tag color={tagStatus} style={{width: '120px', textAlign: 'center'}}>
                              {value[field.name] === 'WAIT_ADMIN_APPROVE'
                                ? 'รอเจ้าหน้าที่อนุมัติ'
                                : value[field.name] === 'WAIT_FINANCE_APPROVE'
                                ? 'รอการเงินอนุมัติ'
                                : value[field.name] === 'WAIT_SIGNATURE'
                                ? 'รอเซ็นสัญญา'
                                : value[field.name] === 'SUCCESS'
                                ? 'จ่ายเงินสำเร็จ'
                                : value[field.name] === 'TERMINATE'
                                ? 'ยกเลิก'
                                : value[field.name]}
                            </Tag>
                          </Col>
                        ) : field.name === 'condition' ? (
                          <Col span={12}>{`Condition ${value['condition']}`}</Col>
                        ) : field.name === 'insurance_end_date' ? (
                          value['insurance_end_date'] === '' ? (
                            <Col span={6}>-</Col>
                          ) : (
                            <Col span={6}>{value[field.name]}</Col>
                          )
                        ) : field.name === 'approve_date' ? (
                          value['status'] === 'WAIT_ADMIN_APPROVE' || value['status'] === 'TERMINATE' ? (
                            <Col span={6}>-</Col>
                          ) : (
                            <Col span={6}>{value[field.name]}</Col>
                          )
                        ) : field.name === 'terminate_date' ? (
                          showTerminateDate ? (
                            <Col span={6}>{value[field.name]}</Col>
                          ) : (
                            <Col span={6}></Col>
                          )
                        ) : (
                          <Col span={12}>{value[field.name] || '-'}</Col>
                        )}
                      </Row>
                    </div>
                  ),
                )}
              </div>
              <br></br>
              <div style={{marginLeft: '20px'}}>
                {value['registId'] && value['type'] === 'SECONDHAND' ? (
                  <>
                    <GenSignedLink registerId={value['registId']} status={value['registStatus']} />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Col>
          </Row>

          {value['type'] === 'SECONDHAND' && (
            <Row style={{justifyContent: 'center'}}>
              <Col>
                {fieldRemark.map((field) => (
                  <div key={field.key} style={{marginBottom: '10px', width: 'auto', marginInline: '20px'}}>
                    <Row>
                      <div style={{fontWeight: 'bold', marginRight: '10px', marginBottom: '10px'}}>{field.label}</div>
                      {field.name === 'remark' ? (
                        <div style={{width: '100%'}}>
                          {enabledEditRemark ? (
                            <Row style={{justifyContent: 'center'}}>
                              <TextArea style={{width: 800}} maxLength={1500} rows={10} value={remark} onChange={(e) => handleChangeRemark(e)} />
                              <div style={{display: 'inline-block', marginTop: '10px'}}>
                                <Button
                                  type="ghost"
                                  style={{marginRight: '10px'}}
                                  // style={{ backgroundColor: color.danger, color: color.white, borderRadius: 60 }}
                                  title="Cancel"
                                  onClick={() => setEnabledEditRemark(false)}>
                                  Cancel
                                </Button>
                                <Button type="primary" title="OK" onClick={() => onEditRemark()}>
                                  OK
                                </Button>
                              </div>
                            </Row>
                          ) : (
                            <Card style={{borderRadius: '15px', marginInline: '50px'}}>
                              <TextSmall
                                cursor={'pointer'}
                                style={{display: 'inline-block', width: '100%'}}
                                onClick={() => setEnabledEditRemark(true)}
                                icon={<EditOutlined style={{borderRadius: '50%'}} />}
                                text={value[field.name] || ''}
                              />
                            </Card>
                          )}
                        </div>
                      ) : (
                        <Col span={12}>{value[field.name]}</Col>
                      )}
                    </Row>
                  </div>
                ))}
              </Col>
              <Col>
                {value['status'] === 'WAIT_ADMIN_APPROVE' && (
                  <div>
                    <ContainerButton center style={{marginTop: '40px'}}>
                      {perAction.includes('approve') && (
                        <ButtonTheme
                          useFor="CUSTOM"
                          title="อนุมัติ"
                          bgcolor={color.success}
                          onClick={() => approveDoc('WAIT_FINANCE_APPROVE')}
                          style={{width: '130px', borderRadius: '30px', boxShadow: '2px 2px 2px #707070'}}
                        />
                      )}
                      {perAction.includes('cancel') && (
                        <ButtonTheme
                          useFor="CUSTOM"
                          title="ไม่อนุมัติ"
                          bgcolor={color.danger}
                          onClick={() => approveDoc('TERMINATE')}
                          style={{width: '130px', marginLeft: '20px', borderRadius: '30px', boxShadow: '2px 2px 2px #707070'}}
                        />
                      )}
                    </ContainerButton>
                  </div>
                )}
              </Col>
            </Row>
          )}
        </div>
      </Modal>
    </>
  );
};
