import axios from "axios";
import { API_REGISTER_SERVICE } from "../env";
import { createParams } from "../functions/fn";

export const getEstimateDeviceOptions = async () => {
  try {
    const response = await axios.get(
      `${API_REGISTER_SERVICE}/app/option/estimate-device`,
    )
    return response.data;
  } catch (error) {
    return error;
  }
}

export const getRegisterBranch = async (payload) => {
  try {
    const response = await axios.post(
      `${API_REGISTER_SERVICE}/app/option/register-branch`,
      payload
    )
    return response.data;
  } catch (error) {
    return error;
  }
}

export const getRegisterAllProductModel = async (payload) => {
  try {
    var params = createParams(payload)
    const response = await axios.get(
      `${API_REGISTER_SERVICE}/app/option/product-model?${params}`,
    )
    return response.data;
  } catch (error) {
    return error;
  }
}

export const getRegisterAllProductStorage = async (payload) => {
  try {
    var params = createParams(payload)
    const response = await axios.get(
      `${API_REGISTER_SERVICE}/app/option/product-storage?${params}`,
    )
    return response.data;
  } catch (error) {
    return error;
  }
}

export const getRegisterAllProductColor = async (payload) => {
  try {
    var params = createParams(payload)
    const response = await axios.get(
      `${API_REGISTER_SERVICE}/app/option/product-color?${params}`,
    )
    return response.data;
  } catch (error) {
    return error;
  }
}

export const getRegisterProductCodeAndPeriods = async (payload) => {
  try {
    var params = createParams(payload)
    const response = await axios.get(
      `${API_REGISTER_SERVICE}/app/option/product-code?${params}`,
    )
    return response.data;
  } catch (error) {
    return error;
  }
}