import styled from 'styled-components';

export const DatatableCouponDetailStyle = styled.div`
  .table-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }

  .ant-table-wrapper {
    margin-bottom: 30px;

    table > thead > tr > th {
      background: #f0f5ff;
      color: #262626;
      font-weight: 600;
    }

    table > thead > tr > td {
      color: #d9d9d9;
      font-weight: 600;
    }
  }
`;
