import React, { useEffect } from "react";
import { Collapse as CollapseAntd, Button, Row, Col, Typography } from "antd";
import _ from 'lodash';
const { Panel } = CollapseAntd;
const Collapse = (props) => {
  const { options, initialSelectedOption, selectedOption, setSelectedOption, activePanels, setActivePanels, disabled, hideValueHeader, multiple = false, format = 'text' } = props.item;
  //required field -> options, format, activePanels, setActivePanels,hideValueHeader
  //activePanels, setActivePanels -> state เอาไว้จัดการเปืดปืด each Panel
  //hideValueHeader -> boolean เอาไว้ show ค่าที่เลือกบน header

  //format == 'button' -> ถ้าต้องการ content เป้นปุ่ม
  //initialSelectedOption -> เอาไว้ set default active button
  //selectedOption, setSelectedOption -> state เอาไว้จัดการค่าที่เลือก เมื่อกดปุ่ม
  //disabled -> ไม่ให้เลือกปุ่มสักปุ่ม
  //mulitple -> boolean เอาไว้ว่าสามารถเลือกมากกว่า 1 ข้อได้

  //format == 'text' -> ถ้าต้องการ content เป้น text string

  useEffect(() => {
    if (format === 'button') {
      setSelectedOption((prev) => {
        const updatedSelected = { ...prev };
        options.forEach(({ key }) => {
          updatedSelected[key] = initialSelectedOption[key] || [];
        });
        return updatedSelected;
      });
    }
  }, [initialSelectedOption, options]);

  const handleChange = (category, value, name, multiple) => {
    setSelectedOption((prev) => {
      let newValues;

      if (multiple) {
        newValues = prev[category]?.some((item) => item.code === value)
          ? prev[category].filter((item) => item.code !== value)
          : [...(prev[category] || []), { code: value, name }];
      } else {
        newValues = [{ code: value, name }]; // Store as an array with objects
      }

      const updatedSelection = { ...prev, [category]: newValues };

      // Collapse the panel after selection (only if it's not multiple)
      if (!multiple) {
        const cloneActivePanels = _.cloneDeep(activePanels)
        const result = cloneActivePanels.filter((key) => key !== category)
        handleActivePanels(result);
      }

      return updatedSelection;
    });
  };

  const handleActivePanels = (category) => {
    setActivePanels(category);
  }

  return (
    <CollapseAntd activeKey={activePanels} onChange={(e) => handleActivePanels(e)
    } accordion={false} style={{ marginTop: '1%' }}>
      {
        options.map(({ key, name, value }) => (
          <Panel
            key={key}
            header={
              <Row gutter={[8, 8]}>
                <Col span={17}>
                  <Typography.Text style={{
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                    display: "block"
                  }}>
                    {name}
                  </Typography.Text>
                </Col>
                <Col span={7} style={{ textAlign: "right" }}>
                  {!hideValueHeader && (
                    <span style={{
                      color: "#1890ff",
                      fontWeight: "bold",
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "block",
                      maxWidth: "100%",
                      textAlign: "right"
                    }}>
                      {selectedOption?.[key]?.length > 1
                        ? `มี ${selectedOption?.[key].length} ข้อ`
                        : selectedOption?.[key]?.map((item) => item.name).join(", ") || ""}
                    </span>
                  )}
                </Col>
              </Row>
            }>
            {format === 'button' && (
              <Row gutter={[8, 8]}>
                {value?.map(({ sub_key, sub_name }) => (
                  <React.Fragment key={sub_name}>
                    {value?.length > 1 && <Col span={24}>
                      <Typography.Text strong>{sub_name}</Typography.Text>
                    </Col>}
                    {sub_key.map(({ code, name }) => (
                      <Col key={code} span={12}>
                        <Button
                          block
                          type={selectedOption[key]?.some(item => item.code === code) ? "primary" : "default"}
                          onClick={() => handleChange(key, code, name, multiple)}
                          disabled={disabled}
                          style={{
                            width: "100%",
                            height: '50px',
                            backgroundColor: selectedOption[key]?.some(item => item.code === code) ? "#1890ff" : "",
                            color: selectedOption[key]?.some(item => item.code === code) ? "white" : "",
                            borderColor: selectedOption[key]?.some(item => item.code === code) ? "#1890ff" : "",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            textAlign: "center",
                            padding: "5px",
                          }}
                        >
                          {name}
                        </Button>
                      </Col>
                    ))}
                  </React.Fragment>
                ))}
              </Row>
            )}


            {
              (format === 'text') && <>
                {_.isArray(value) && value?.map(({ code, name }) => (
                  <Row key={code}>
                    {/*หน้ารายละเอียด การโอนเงิน*/}
                    <Col span={8}><Typography.Text>{code}</Typography.Text></Col>
                    <Col span={12}><Typography.Text>{name}</Typography.Text></Col>
                  </Row>
                ))}
                {!_.isArray(value) && (
                  <div>
                    <Typography.Text>{value}</Typography.Text>
                  </div>
                )}
              </>
            }
          </Panel >
        ))
      }
    </CollapseAntd >
  );
};

export default Collapse;
