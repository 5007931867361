import { useFormikContext } from 'formik';
import { getCouponData } from './api';
import { Input } from 'formik-antd';
import { useState } from 'react';
import _ from 'lodash';
import numeral from 'numeral';

const CouponCodeInput = (props) => {
  const [error, setError] = useState(null)
  const ctx = useFormikContext();
  const { contract } = ctx.values;

  const fetchData = async (value) => {

    ctx.setFieldValue('contract.contract.couponCode', value, false);
    let code = ''
    if (value) {
      const hasSpace = _.includes(value, ' ');

      code = value.toUpperCase()

      if (hasSpace) {
        code = value.trim()
      }
    }

    let payload = {
      coupon_code: code,
    }

    const res = await getCouponData(payload)

    if (res.status === 400) {
      setError(res.data.message)
      ctx.setFieldValue('contract.discount', 0);
    } else {
      if (res.success) {
        setError(null)
        ctx.setFieldValue('contract.discount', numeral(res?.coupon_discount || 0).format('0,0'), false);
      }
    }
  };

  const handleFetchCoupon = (e) => {
    fetchData(e.target.value)
  }

  return (
    <>
      <Input {...props} onChange={(e) => handleFetchCoupon(e)}></Input>
      <span style={{ color: 'red' }}><small>{error}</small></span>
    </>
  );
};

export default CouponCodeInput;
