export const registerAdminSteps = [
  {
    title: 'สินค้า',
  },
  {
    title: 'ส่วนตัว',
  },
  {
    title: 'อาชีพ',
  },
  {
    title: 'ผู้ติดต่อ',
  },
  {
    title: 'พิจารณา',
  },
]

export const registerUserSteps = [
  {
    title: 'สินค้า',
  },
  {
    title: 'ส่วนตัว',
  },
  {
    title: 'อาชีพ',
  },
  {
    title: 'ผู้ติดต่อ',
  },
]