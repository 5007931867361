import React, {useState} from 'react';
import {Form} from 'antd';
import {Tabs} from '../../../../components';
import FormTurn2Turn from './FormTurn2Turn';
import FormTurn2New from './FormTurn2New';

export const FormTurn2 = (props) => {
  const [activeTab, setActiveTab] = useState('PRODUCT_TURN');
  const [turnState, setTurnState] = useState({});
  const [newState, setNewState] = useState({});

  const [formTurn2Turn] = Form.useForm();
  const [formTurn2New] = Form.useForm();

  const tabs = [
    {
      key: 'PRODUCT_TURN',
      label: 'สินค้าเทิร์น',
      content: <FormTurn2Turn {...props} form={formTurn2Turn} setActiveTab={setActiveTab} turnState={turnState} setTurnState={setTurnState} />,
    },
    {
      key: 'PRODUCT_NEW',
      label: 'สินค้าใหม่',
      content: (
        <FormTurn2New
          {...props}
          form={formTurn2New}
          setActiveTab={setActiveTab}
          turnState={turnState}
          newState={newState}
          setNewState={setNewState}
        />
      ),
    },
  ];

  return (
    <>
      <Tabs
        centered
        tab={tabs}
        activeKey={activeTab}
        size={'large'}
        stopChangeTab={true}
        destroyInactiveTabPane={false}
        defaultActiveKey={activeTab}></Tabs>
    </>
  );
};
