import {ButtonTheme} from '../../../components/buttons';
import {generateUrlSignOnline} from '../../../services/api/adminservice/sign-online';
import {Modal, message, Button} from 'antd';
import {useState} from 'react';
import {CopyOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import { useEffect } from 'react';
import {convertSignOnlineStatus} from '../../monitor/monitor-customer/consider_vivid/form';

const GenSignedLink = ({ registerId, status, isAutoCopy }) => {
    const [urlSignOnline, setUrlSignOnline] = useState(null);
    const [qrSignOnline, setQrSignOnline] = useState(null);
    const [disabledGenLink , setDisabledGenLink] = useState(false)
    const getStatusText = (status) => {
        const statusConfig = {
            'WAITING_SIGNATURE': {
                text: 'รอเซ็นสัญญา',
                color: '#FF8C00' // สีส้ม
            },
            'SUCCESS': {
                text: 'เซ็นสัญญาเสร็จสิ้น',
                color: '#52c41a' // สีเขียว
            }
        };
        const config = statusConfig[status] || { text: status, color: '#000000' };
        return <span style={{ color: config.color }}>{config.text}</span>;
    };

    useEffect(() => {
        if(status === 'SUCCESS') {
            setDisabledGenLink(true)
        } else {
            setDisabledGenLink(false)
        }
    }, [status])
    
    const handleCopyLink = () => {
        navigator.clipboard.writeText(urlSignOnline)
        message.success('คัดลอก Link สำเร็จ')
    }

    const getGenerateLink = async () => {
      try {
        const res = await generateUrlSignOnline({id: registerId});
  
        const {success, result} = res;
        if (success) {
          setUrlSignOnline(result?.url_sign);
          setQrSignOnline(result?.qr_code);
          if(isAutoCopy) handleCopyLink()
        }
      } catch (error) {
        Modal.error({
          title: error?.message || error?.error || 'ไม่สามารถ Generate Link ได้',
        });
        return;
      }
    };
  
    const handleClickGenerateLink = async () => {
      Modal.confirm({
        title: 'ยืนยันจะสร้างลิงค์เซ็นสัญญา',
        icon: <ExclamationCircleOutlined />,
        content: (
          <>
            {`สถาณะการเซ็นสัญญา: ${convertSignOnlineStatus(status)}`}
            <br />
            {'ต้องการสร้างลิงค์ใหม่หรือไม่'}
          </>
        ),
        onOk() {
          getGenerateLink();
        },
        okText: 'ยืนยัน',
        cancelText: 'ยกเลิก',
      });
    };


    
    return (
        <div style={{marginTop: '15px'}}>
            <p style={{fontSize: '14px', fontWeight: 'bold'}}>ลิงค์เซ็นสัญญาซื้อขายออนไลน์</p>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <ButtonTheme 
                    useFor="CUSTOM" 
                    title={'Generate Signed Link'} 
                    onClick={handleClickGenerateLink}
                    style={{ margin: '0px' }}
                    disabled={disabledGenLink}
                />
                {urlSignOnline && (
                    <Button
                        onClick={handleCopyLink}
                    >
                        <CopyOutlined style={{fontSize: '18px' }}/>
                    </Button>
                )}
                
            </div>
            <p style={{marginTop: '10px'}}>สถานะการเซ็นสัญญาซื้อขายออนไลน์: {getStatusText(status)}</p>
            {urlSignOnline && (
                <>
                  <pre>{urlSignOnline}</pre>
                  {qrSignOnline && <img src={qrSignOnline} alt="QR Code" />}
                </>
              
            )}
        
        </div>
        
    )
};

export default GenSignedLink;
